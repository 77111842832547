import { ASSETS, ItemsAsset, tileSize } from '../assets';
import {EVENT_ITEM_COLLECTED, GlobalEvents} from '../helpers/global-events';
import BaseSound = Phaser.Sound.BaseSound;
import { BadgeType } from '../map/map-helper';
import {GameStoreObject} from './game-store-object';

export enum ItemType {
    Badge = 'badge',
    Life = 'life',
    Power = 'power'
}

export class ItemSprite extends Phaser.Physics.Arcade.Sprite implements GameStoreObject {
    private asset: ItemsAsset = ASSETS.items;
    private badgeFoundSound: BaseSound;
    private globalEvents = GlobalEvents.resolve();
    public body: Phaser.Physics.Arcade.Body;
    public objectId: string;

    constructor(scene: Phaser.Scene, x: number, y: number, public itemType: ItemType, public badge?: BadgeType) {
        super(scene, x, y + 10, 'item');
        this.scene.add.existing(this);
        this.scene.physics.world.enable(this);
        this.setOrigin(this.asset[itemType].originX, this.asset[itemType].originY);
        this.body
            .setSize(this.asset[itemType].bodyW, this.asset[itemType].bodyH)
            .setOffset(this.asset[itemType].offsetX, this.asset[itemType].offsetY)
            .setAllowGravity(false)
            .setImmovable(true);

        this.badgeFoundSound = this.scene.sound.add('music/badge-found', { loop: false, volume: 0.6 });

        if (!this.scene.anims.exists(`item/${itemType}`)) {
            this.scene.anims.create({
                key: `item/${itemType}`,
                repeat: -1,
                frames: this.asset[itemType].name,
                frameRate: this.asset[itemType].fps
            });
        }

        this.play(`item/${itemType}`, true);

        if (itemType === ItemType.Life || itemType === ItemType.Power) {
            this.scene.tweens.add({
                targets: this,
                duration: 1000,
                scale: 0.9,
                yoyo: true,
                ease: 'Sine.easeOut',
                repeat: -1
            });
        }

    }

    public itemFound(): void {
        this.globalEvents.emit(EVENT_ITEM_COLLECTED, this.objectId);
        this.badgeFoundSound.play();
        this.destroy();
    }

}
