import {ASSETS} from '../assets';
import {BadgeService} from '../gui/bagde-service';
import {ScreensService} from '../gui/screens-service';
import {GameInputs} from '../helpers/game-inputs';
import {
    EVENT_PLAYER_LOCATION_TARGET_UPDATE,
    EVENT_SHOW_MISSING_BADGES_INFO,
    GlobalEvents
} from '../helpers/global-events';
import {GlobalObjects, PlayerData} from '../helpers/global-objects';
import {AnyLevelScene} from '../scenes/any-level-scene';
import {boss1Scene} from '../scenes/definitions/boss-1.scene';
import {GameStoreObject} from './game-store-object';

export class PortalSprite extends Phaser.Physics.Arcade.Sprite implements GameStoreObject {
    private isActivated = false;
    private interactiveArea: Phaser.GameObjects.Rectangle;
    private isOpened = false;
    private openTween: Phaser.Tweens.Tween;
    private closeTween: Phaser.Tweens.Tween;
    private screens: ScreensService = ScreensService.resolve();
    private badgeService: BadgeService = BadgeService.resolve();
    public inputs: GameInputs = GameInputs.resolve();
    public globalEvents = GlobalEvents.resolve();

    public body: Phaser.Physics.Arcade.Body;
    public objectId: string;

    constructor(scene: Phaser.Scene, x: number, y: number, width: number, height: number, public targetScene: string, public locationTarget?: string) {
        super(scene, x + width/2, y + height + 2, 'portal');

        this.scene.add.existing(this);
        this.scene.physics.world.enable(this);
        this.setAlpha(0);

        this.scene.anims.create({
            key: 'portal/anim',
            repeat: -1,
            frames: ASSETS.portal.default.name,
            frameRate: ASSETS.portal.default.fps
        });
        this.setOrigin(ASSETS.portal.default.originX, ASSETS.portal.default.originY);
        this.body
            .setSize(ASSETS.portal.default.frameW, ASSETS.portal.default.frameH)
            .setOffset(ASSETS.portal.default.offsetX, ASSETS.portal.default.offsetY)
            .setAllowGravity(false)
            .setImmovable(true);

        this.openTween = this.scene.tweens.create({
           targets: this,
           duration: 500,
            props: {
               alpha: 1
            }
        });
        this.closeTween = this.scene.tweens.create({
            targets: this,
            duration: 500,
            props: {
                alpha: 0
            }
        });

        this.play('portal/anim');
        this.interactiveArea = this.scene.add.rectangle(this.x, this.y - height/2, width, height);
    }

    public preUpdate(time: number, delta: number): void {
        this.isActivated = Phaser.Geom.Rectangle.Overlaps(GlobalObjects.player.getBounds(), this.interactiveArea.getBounds());
        if (this.isActivated) {
            if (!this.isOpened) {
                this.isOpened = true;
                this.openTween.play(true);
            }

            if (this.inputs.down) {
                if (this.targetScene === boss1Scene.sceneName && !this.badgeService.hasAll()) {
                    this.globalEvents.emit(EVENT_SHOW_MISSING_BADGES_INFO);
                } else {
                    GlobalObjects.setCurrentSceneName(this.targetScene);
                    this.globalEvents.emit(EVENT_PLAYER_LOCATION_TARGET_UPDATE, this.targetScene, this.locationTarget);
                    this.screens.startNewMainScene(AnyLevelScene.SCENE_KEY);
                }
            }
        } else {
            if (this.isOpened) {
                this.isOpened = false;
                this.closeTween.play(true);
            }
        }
        super.preUpdate(time, delta);
    }
}
