import TiledObject = Phaser.Types.Tilemaps.TiledObject;

export class MapHelper {
    public static getProperties(tile: TiledObject): TileProperties {
        let result = <TileProperties>{};
        if (tile.properties && tile.properties.length > 0) {
            (tile.properties as any[]).forEach(item => {
                (<any>result)[item.name] = item.value;
            });
        }

        if (result.type === ObjectType.movingPlatform) {
            result.platformSize = result.platformSize || PlatformSize.size2x;
            result.platformStartPosition = result.platformStartPosition || 0;
        }
        if (result.type === ObjectType.spikesObstacle) {
            result.spikesObstacleInterval = result.spikesObstacleInterval || Phaser.Math.Between(1000, 5000);
            result.spikesObstacleStartDelay = result.spikesObstacleStartDelay || Phaser.Math.Between(0, 3000);
            result.spikesObstacleSize = result.spikesObstacleSize || SpikesObstacleSize.normal;
        }
        
        // Decode difficulty levels.
        // By default, show everything in every level from easy to hard.
        const levelsString = result.difficultyLevels ? result.difficultyLevels.toString() : `${GameDifficultyLevel.easy}|${GameDifficultyLevel.normal}|${GameDifficultyLevel.hard}`;
        const levels = levelsString.split('|');
        result.difficultyLevels = [];
        levels.forEach(level => {
           if (MapHelper.isCorrectLevel(level)) {
               result.difficultyLevels.push(level as GameDifficultyLevel);
           } else {
               throw new Error(`Invalid difficulty level: ${level} for the object with properties ${JSON.stringify(result)}`);
           }
        });
        return result;
    }
    
    private static isCorrectLevel(level: string): boolean {
        switch (level) {
            case GameDifficultyLevel.easy: return true;
            case GameDifficultyLevel.normal: return true;
            case GameDifficultyLevel.hard: return true;
            default: return false;
        }
    }
}

export interface TileProperties {
    type: ObjectType;
    platformSize?: PlatformSize;
    /**
     * Index of path point where platforms starts.
     */
    platformStartPosition?: number;
    /**
     * How often the spikes are shown/hidden in ms.
     */
    spikesObstacleInterval?: number;
    /**
     * Spikes delay before starting show/hide loop.
     */
    spikesObstacleStartDelay?: number;
    /**
     * Defines a maximum size which will have an expanded spike obstacle.
     * The biggest size (big) is the full height of the obstacle.
     */
    spikesObstacleSize?: SpikesObstacleSize;
    /**
     * Unlocked badge name
     */
    badge?: BadgeType;
    /**
     * Name of a target scene where the portal transports.
     */
    portalTarget?: string;
    /**
     * Name of the target location in a new scene where the player is transferred via portal.
     * If this is empty, we use standard player start position on a scene.
     */
    locationName?: string;
    /**
     * If provided in a game object, denotes from which difficulty level the object is visible on a map.
     */
    difficultyLevels: GameDifficultyLevel[];
}

export enum PlatformSize {
    size2x = '2x',
    size3x = '3x',
    size5x = '5x',
    size7x = '7x'
}

export enum ObjectType {
    staticPlatform = 'static-platform',
    movingPlatform = 'moving-platform',
    playerStart = 'player-start',
    locationTarget = 'player-location-target',
    staticGround = 'static-ground',
    staticWall = 'static-wall',
    deathArea = 'death-area',
    spikesObstacle = 'spikes-obstacle',
    chimneyEnemy = 'chimney-enemy',
    badge = 'badge',
    life = 'life',
    power = 'power',
    boss = 'boss',
    portal = 'portal',
    checkpoint = 'checkpoint'
}

export enum BadgeType {
    friendsAndFamily = 'friends-and-family',
    heatingCurveAssistant = 'heating-curve-assistant',
    surpriseGift = 'surprise-gift',
    freeHeatingService = 'free-heating-service',
    smartThermostat = 'smart-thermostat',
    geofencing = 'geofencing'
}

export enum SpikesObstacleSize {
    big = 'big',
    normal = 'normal',
    small = 'small',
}

export enum GameDifficultyLevel {
    easy = 'easy',
    normal = 'normal',
    hard = 'hard'
}