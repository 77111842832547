import GameObjectWithDynamicBody = Phaser.Types.Physics.Arcade.GameObjectWithDynamicBody;
import Rectangle = Phaser.GameObjects.Rectangle;
import StaticBody = Phaser.Physics.Arcade.StaticBody;
import GameObjectWithBody = Phaser.Types.Physics.Arcade.GameObjectWithBody;
import Vector2 = Phaser.Math.Vector2;
import {globalConfig} from '../config';

export class PathFollower extends Phaser.GameObjects.GameObject {
    private color: number = 0x00FF00;
    private movingForward = true;
    private velocity = 150;
    private currentPointIndex: number = -1;
    private paused = true;
    private waiting = false;

    constructor(
        scene: Phaser.Scene,
        public readonly targetObject: GameObjectWithDynamicBody,
        public readonly x: number,
        public readonly y: number,
        start: number,
        private readonly points: Vector2[],
        private readonly waitOnPoint: number = 1000,
        private readonly title?: string
    ) {
        super(scene, 'moving-path-point');
        this.title = this.title || 'moving-path';
        points.forEach((p, index) => {
            if (globalConfig.debug) {
                this.scene.add.ellipse(this.x + p.x, this.y + p.y, 7, 7, this.color).setDepth(1000);
                this.scene.add.text(this.x + p.x + 4, this.y + p.y - 20, index.toString(), {color: '#00FF00', fontSize: '11px'}).setDepth(1000);
            }
            const pointCollider = new Rectangle(this.scene, this.x + p.x, this.y + p.y, 7, 7, 0x000000, 0);
            pointCollider.setData('pathIndex', index);
            const body = this.scene.physics.add.existing(pointCollider, true).body as StaticBody;
            body.debugShowBody = false;

            if (index > 0 && globalConfig.debug) {
                const pp = points[index - 1];
                this.addLine(pp.x, pp.y, p.x, p.y, index === 1);
            }

            this.scene.physics.add.collider(
                this.targetObject, pointCollider,
                (object: GameObjectWithDynamicBody, pathPointCollider: GameObjectWithBody) => {
                    this.onPointCollide(object, pathPointCollider);
                });
        });

        // This is or horizontal/vertical movement only
        this.setTargetStartPos(start);
    }

    public startPause(start: boolean): void {
        this.paused = !start;
        if (this.paused) {
            this.targetObject.body.setVelocity(0);
        }
    }

    private onPointCollide(object: GameObjectWithDynamicBody, pointObject: GameObjectWithBody): void {
        if (this.paused) {
            return;
        }

        if (!this.waiting) {
            this.waiting = true;
            object.body.stop();

            this.scene.time.delayedCall(this.waitOnPoint, () => {
                const pointIndex = pointObject.getData('pathIndex') as number;
                if (this.currentPointIndex != pointIndex) {
                    this.currentPointIndex = pointIndex;
                    const currentPoint = this.points[pointIndex];
                    const nextPoint = this.getNextPoint(pointIndex);

                    const dx = nextPoint.x - currentPoint.x;
                    const dy = nextPoint.y - currentPoint.y;
                    const totalLength = Math.abs(dx) + Math.abs(dy); // full velocity for this
                    const vx = Math.sign(dx) * Math.abs(dx) / totalLength * this.velocity;
                    const vy = Math.sign(dy) * Math.abs(dy) / totalLength * this.velocity;
                    object.body.setVelocity(vx, vy);
                }
                this.scene.time.delayedCall(100, () => this.waiting = false);
            });
        }
    }

    private addLine(x1: number, y1: number, x2: number, y2: number, text: boolean): void {
        this.scene.add.line(this.x, this.y, x1, y1, x2, y2, this.color)
            .setDepth(1000).setOrigin(0, 0);

        if (text) {
            let textX = this.x + x1 + (x2 - x1) / 2 - 10;
            let textY = this.y + y1 + (y2 - y1) / 2 - 10;
            let rotate = Phaser.Math.Angle.Between(x1, y1, x2, y2) - Math.PI;
            if (y1 === y2) {
                rotate = 0;
            }
            this.scene.add.text(textX, textY, this.title, {
                fontSize: '12px',
                color: '#00FF00'
            }).setOrigin(0.5).setDepth(1000).setRotation(rotate);
        }
    }

    private setTargetStartPos(startIndex: number): void {
        const startPoint = this.points[startIndex];
        const nextPoint = this.getNextPoint(startIndex);

        if (nextPoint.y === startPoint.y) {
            const correction = nextPoint.x > startPoint.x ? 1 : -1;
            this.targetObject.body.reset(this.x + startPoint.x + this.targetObject.body.width / 2 * correction, this.y + startPoint.y);
        } else if (nextPoint.x === startPoint.x) {
            const correction = nextPoint.y > startPoint.y ? 0 : -1;
            this.targetObject.body.reset(this.x + startPoint.x, this.y + startPoint.y + this.targetObject.body.height * correction);
        }
    }

    private getNextPoint(index: number): Vector2 {
        const startPoint = this.points[index];
        let nextPoint: Vector2;
        if (this.movingForward) {
            if (index + 1 < this.points.length) {
                nextPoint = this.points[index + 1];
            }
            else {
                nextPoint = this.points[index - 1];
                this.movingForward = !this.movingForward; // reverse
            }
        }
        else {
            if (index - 1 >= 0) {
                nextPoint = this.points[index - 1];
            }
            else {
                nextPoint = this.points[index + 1];
                this.movingForward = !this.movingForward; // reverse
            }
        }
        return nextPoint;
    }
}
