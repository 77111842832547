import {Asset, ASSETS} from '../../assets';
import {BossPowerHud} from '../../gui/huds/boss-power-hud';
import {LivesHud} from '../../gui/huds/lives-hud';
import {PowerHud} from '../../gui/huds/power-hud';
import {MapTileSets} from '../../map/map-loader';
import {SceneData, ScreenButtonType} from './scenes-models';

export const boss1Scene = <SceneData>{
    sceneName: 'scenes/boss-1',
    dontStopScene: false,
    backgroundMusicKey: 'music/background-happy',
    sceneTileMap: <Asset>{key: 'map/boss', filePath: './assets/tilemaps/boss-1.json'},
    images: [
        // map tile sets
        <Asset>{key: 'map/tileset', filePath: './assets/images/tileset_x128.png'},
        <Asset>{key: 'map/boss-tileset', filePath: './assets/images/grass-tiles.png'},

        <Asset>{key: 'map/images/boss-base', filePath: './assets/images/objects/boss-base.png'},
        <Asset>{key: 'map/images/fireplace-grate', filePath: './assets/images/objects/fireplace-grate.png'},
        <Asset>{key: 'map/images/damaged-car', filePath: './assets/images/objects/damaged-car.png'},
        <Asset>{key: 'map/images/damaged-car-doors', filePath: './assets/images/objects/damaged-car-doors.png'},
        <Asset>{
            key: 'map/images/damaged-grass-parts-1',
            filePath: './assets/images/objects/damaged-grass-parts-1.png'
        },
        <Asset>{
            key: 'map/images/damaged-grass-parts-2',
            filePath: './assets/images/objects/damaged-grass-parts-2.png'
        },
        <Asset>{
            key: 'map/images/damaged-grass-parts-3',
            filePath: './assets/images/objects/damaged-grass-parts-3.png'
        },
        <Asset>{key: 'map/images/dry-grass-parts-1', filePath: './assets/images/objects/dry-grass-parts-1.png'},
        <Asset>{key: 'map/images/dry-grass-parts-2', filePath: './assets/images/objects/dry-grass-parts-2.png'},
        <Asset>{key: 'map/images/dry-grass-parts-3', filePath: './assets/images/objects/dry-grass-parts-3.png'},
        <Asset>{key: 'map/images/green-grass-parts-1', filePath: './assets/images/objects/green-grass-parts-1.png'},
        <Asset>{key: 'map/images/green-grass-parts-2', filePath: './assets/images/objects/green-grass-parts-2.png'},
        <Asset>{key: 'map/images/green-grass-parts-3', filePath: './assets/images/objects/green-grass-parts-3.png'},
        <Asset>{key: 'map/images/liane', filePath: './assets/images/objects/liane.png'},
        <Asset>{key: 'map/images/old-tire-1', filePath: './assets/images/objects/old-tire-1.png'},
        <Asset>{key: 'map/images/old-tire-2', filePath: './assets/images/objects/old-tire-2.png'},
        <Asset>{key: 'map/images/old-tire-3', filePath: './assets/images/objects/old-tire-3.png'},
        <Asset>{key: 'map/images/old-tire-4', filePath: './assets/images/objects/old-tire-4.png'},
        <Asset>{key: 'map/images/trashes-1', filePath: './assets/images/objects/trashes-1.png'},
        <Asset>{key: 'map/images/trashes-2', filePath: './assets/images/objects/trashes-2.png'},
        <Asset>{key: 'map/images/trashes-3', filePath: './assets/images/objects/trashes-3.png'},
        <Asset>{key: 'map/images/trashes-4', filePath: './assets/images/objects/trashes-4.png'},
        <Asset>{key: 'map/images/trashes-5', filePath: './assets/images/objects/trashes-5.png'},
        <Asset>{key: 'map/images/trashes-6', filePath: './assets/images/objects/trashes-6.png'},
        <Asset>{key: 'map/images/trashes-7', filePath: './assets/images/objects/trashes-7.png'},
        <Asset>{key: 'map/images/trashes-8', filePath: './assets/images/objects/trashes-8.png'},
        <Asset>{key: 'map/images/trashes-9', filePath: './assets/images/objects/trashes-9.png'},
        <Asset>{key: 'map/images/trashes-10', filePath: './assets/images/objects/trashes-10.png'},
        <Asset>{key: 'map/images/trashes-11', filePath: './assets/images/objects/trashes-11.png'},
        <Asset>{key: 'map/images/trashes-12', filePath: './assets/images/objects/trashes-12.png'},
        <Asset>{key: 'map/images/trashes-13', filePath: './assets/images/objects/trashes-13.png'},
        <Asset>{key: 'map/images/trashes-14', filePath: './assets/images/objects/trashes-14.png'},
        <Asset>{key: 'map/images/trashes-15', filePath: './assets/images/objects/trashes-15.png'},
        <Asset>{key: 'map/images/trashes-16', filePath: './assets/images/objects/trashes-16.png'},
        <Asset>{key: 'map/images/trashes-17', filePath: './assets/images/objects/trashes-17.png'},
        <Asset>{key: 'map/images/trashes-18', filePath: './assets/images/objects/trashes-18.png'},

        <Asset>{key: 'map/images/bush-green-big', filePath: './assets/images/objects/bush-green-big.png'},
        <Asset>{key: 'map/images/bush-green-small', filePath: './assets/images/objects/bush-green-small.png'},
        <Asset>{key: 'map/images/bush-pink-big', filePath: './assets/images/objects/bush-pink-big.png'},
        <Asset>{key: 'map/images/bush-pink-small', filePath: './assets/images/objects/bush-pink-small.png'},
        <Asset>{key: 'map/images/mushroom-blue-1', filePath: './assets/images/objects/mushroom-blue-1.png'},
        <Asset>{key: 'map/images/mushroom-blue-2', filePath: './assets/images/objects/mushroom-blue-2.png'},
        <Asset>{key: 'map/images/mushroom-blue-3', filePath: './assets/images/objects/mushroom-blue-3.png'},
        <Asset>{key: 'map/images/mushroom-pink-1', filePath: './assets/images/objects/mushroom-pink-1.png'},
        <Asset>{key: 'map/images/mushroom-pink-2', filePath: './assets/images/objects/mushroom-pink-2.png'},
        <Asset>{key: 'map/images/mushroom-pink-3', filePath: './assets/images/objects/mushroom-pink-3.png'},
        <Asset>{key: 'map/images/mushroom-red-1', filePath: './assets/images/objects/mushroom-red-1.png'},
        <Asset>{key: 'map/images/mushroom-red-2', filePath: './assets/images/objects/mushroom-red-2.png'},
        <Asset>{key: 'map/images/mushroom-red-3', filePath: './assets/images/objects/mushroom-red-3.png'},
        <Asset>{key: 'map/images/liane', filePath: './assets/images/objects/liane.png'},
        <Asset>{key: 'map/images/plant-1', filePath: './assets/images/objects/plant-1.png'},
        <Asset>{key: 'map/images/plant-2', filePath: './assets/images/objects/plant-2.png'},
        <Asset>{key: 'map/images/plant-3', filePath: './assets/images/objects/plant-3.png'},
        <Asset>{key: 'map/images/plant-4', filePath: './assets/images/objects/plant-4.png'},
        <Asset>{key: 'map/images/plant-5', filePath: './assets/images/objects/plant-5.png'},
        <Asset>{key: 'map/images/plant-6', filePath: './assets/images/objects/plant-6.png'},
        <Asset>{key: 'map/images/plant-7', filePath: './assets/images/objects/plant-7.png'},
        <Asset>{key: 'map/images/plant-8', filePath: './assets/images/objects/plant-8.png'},
        <Asset>{key: 'map/images/plant-9', filePath: './assets/images/objects/plant-9.png'},
        <Asset>{key: 'map/images/stones-1', filePath: './assets/images/objects/stones-1.png'},
        <Asset>{key: 'map/images/stones-2', filePath: './assets/images/objects/stones-2.png'},
        <Asset>{key: 'map/images/stones-3', filePath: './assets/images/objects/stones-3.png'},
        <Asset>{key: 'map/images/tree-1', filePath: './assets/images/objects/tree-1.png'},
        <Asset>{key: 'map/images/tree-2', filePath: './assets/images/objects/tree-2.png'},
        <Asset>{key: 'map/images/tree-3', filePath: './assets/images/objects/tree-3.png'},
        <Asset>{key: 'map/images/tree-4', filePath: './assets/images/objects/tree-4.png'},
        <Asset>{key: 'map/images/tree-5', filePath: './assets/images/objects/tree-5.png'},
        <Asset>{key: 'map/images/tree-6', filePath: './assets/images/objects/tree-6.png'},
        <Asset>{key: 'map/images/tree-7', filePath: './assets/images/objects/tree-7.png'},
        <Asset>{key: 'map/images/tree-8', filePath: './assets/images/objects/tree-8.png'},
        <Asset>{key: 'map/images/tree-green-big', filePath: './assets/images/objects/tree-green-big.png'},
        <Asset>{key: 'map/images/tree-green-small', filePath: './assets/images/objects/tree-green-small.png'},
        <Asset>{key: 'map/images/tree-pink-big', filePath: './assets/images/objects/tree-pink-big.png'},
        <Asset>{key: 'map/images/tree-pink-small', filePath: './assets/images/objects/tree-pink-small.png'},

        // platforms
        <Asset>{key: 'platform/2x', filePath: './assets/images/platform-2.png'},
        <Asset>{key: 'platform/3x', filePath: './assets/images/platform-3.png'},
        <Asset>{key: 'platform/5x', filePath: './assets/images/platform-5.png'},
        <Asset>{key: 'platform/7x', filePath: './assets/images/platform-7.png'},

        // parallax
        <Asset>{key: 'background/light/1', filePath: './assets/images/backgrounds/parallax-1.png'},
        <Asset>{key: 'background/light/2', filePath: './assets/images/backgrounds/parallax-2.png'},
        <Asset>{key: 'background/light/3', filePath: './assets/images/backgrounds/parallax-3.png'},
        <Asset>{key: 'background/light/4', filePath: './assets/images/backgrounds/parallax-4.png'},
        <Asset>{key: 'background/light/5', filePath: './assets/images/backgrounds/parallax-5.png'},
        <Asset>{key: 'background/light/6', filePath: './assets/images/backgrounds/parallax-6.png'},
        <Asset>{key: 'background/light/7', filePath: './assets/images/backgrounds/parallax-7.png'},

    ],
    sounds: [
        <Asset>{key: 'music/fail', filePath: './assets/sounds/fail.mp3'},
        <Asset>{key: 'music/walk', filePath: './assets/sounds/walk.mp3'},
        <Asset>{key: 'music/run', filePath: './assets/sounds/run.mp3'},
        <Asset>{key: 'music/land', filePath: './assets/sounds/landing.mp3'},
        <Asset>{key: 'music/spikes', filePath: './assets/sounds/spikes.mp3'},
        <Asset>{key: 'music/danger', filePath: './assets/sounds/danger.mp3'},
        <Asset>{key: 'music/player-ouch', filePath: './assets/sounds/player-ouch.mp3'},
        <Asset>{key: 'music/button', filePath: './assets/sounds/button.mp3'},
        <Asset>{key: 'music/click', filePath: './assets/sounds/click.mp3'},
        <Asset>{key: 'music/jump', filePath: './assets/sounds/jump.mp3'},
        <Asset>{key: 'music/destroy', filePath: './assets/sounds/destroy.mp3'},
        <Asset>{key: 'music/ammo-hit', filePath: './assets/sounds/ammo-hit.mp3'},
        <Asset>{key: 'music/badge-found', filePath: './assets/sounds/badge-found.mp3'},
        <Asset>{key: 'music/background-happy', filePath: './assets/sounds/background.mp3'}
    ],
    sprites: [
        ASSETS.player1,
        ASSETS.player2,
        ASSETS.player3,
        ASSETS.player4,
        ASSETS.player5,
        ASSETS.chimneyEnemy,
        ASSETS.boss,
        ASSETS.effects,
        ASSETS.items,
        ASSETS.ammo,
        ASSETS.obstacles
    ],
    tileSets: <MapTileSets>{
        grounds: {
            tilesetName: 'ground-tiles',
            key: 'map/tileset',
            firstGid: 1
        },
        foregrounds: {
            tilesetName: 'grass-tiles',
            key: 'map/boss-tileset',
            firstGid: 438
        }
    },
    huds: [
        (scene) => {
            return new LivesHud(scene, 0, 0);
        },
        (scene) => {
            return new PowerHud(scene, 0, 0);
        },
        (scene) => {
            return new BossPowerHud(scene, 0, 0, 200);
        }
    ],
    isBossLevel: true
};
