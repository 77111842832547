export class GlobalEvents extends Phaser.Events.EventEmitter {
    private static instance: GlobalEvents;

    /**
     * Resolve global events service instance.
     */
    public static resolve(): GlobalEvents {
        if (!GlobalEvents.instance) {
            GlobalEvents.instance = new GlobalEvents();
        }
        return GlobalEvents.instance;
    }

    private constructor() {
        super();
    }
}

export const EVENT_APPLICATION_STARTED = 'events/app-started';
export const EVENT_PLAYER_DEATH = 'events/player-death';
export const EVENT_PLAYER_HURT = 'events/player-hurt';
export const EVENT_PLAYER_POWER_UPDATE = 'events/player-power-update';
export const EVENT_PLAYER_LIFE_UPDATE = 'events/player-life-update';
export const EVENT_PLAYER_LOCATION_TARGET_UPDATE = 'events/player-location-target-update'; // the location where player after death will be placed
export const EVENT_BADGE_FOUND = 'events/badge-found';
export const EVENT_SHOW_UNLOCKED_BADGES = 'events/show-unlocked-badges';
export const EVENT_SHOW_BADGE_INFO = 'events/show-badge-info';
export const EVENT_SHOW_GAME_COMPLETED = 'events/show-game-completed';
export const EVENT_SHOW_EXIT_GAME_QUESTION = 'events/show-exit-game-question';
export const EVENT_SHOW_MISSING_BADGES_INFO = 'events/show-missing-badges-info';
export const EVENT_CHECKPOINT_ACTIVATE = 'events/checkpoint-activate';

export const EVENT_BOSS_KILLED = 'events/boss-killed';
export const EVENT_BOSS_POWER_UPDATE = 'events/boss-power-update';

export const EVENT_ENABLE_SOUND = 'events/enable-sound';
export const EVENT_DISABLE_SOUND = 'events/disable-sound';

export const EVENT_WINDOW_RESIZE = 'events/window-resize';

// GAME STORE
export const EVENT_ITEM_COLLECTED = 'events/item-collected';
export const EVENT_ENEMY_KILLED = 'events/enemy-killed';
export const EVENT_PLAYER_CHANGED = 'events/player-changed';
export const EVENT_DIFFICULTY_LEVEL_CHANGED = 'events/difficulty-level-changed';
export const EVENT_GAME_OVER = 'events/game-over';
export const EVENT_GAME_COMPLETED = 'events/game-completed';

// ERRORS
export const EVENT_GLOBAL_ERROR = 'events/global-exception';
