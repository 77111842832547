import {AssetAnimSpriteInfo} from '../assets';

export class GameSpriteHelper {
    public static setBodyParams(sprite: Phaser.Physics.Arcade.Sprite, info: AssetAnimSpriteInfo): void {
        sprite.body.setSize(info.bodyW, info.bodyH);

        if (sprite.flipX) {
            sprite.setOrigin(1 - info.originX, info.originY);
            sprite.body.setOffset(
                info.frameW - (info.bodyW + info.offsetX), info.offsetY
            );
        }
        else {
            sprite.setOrigin(info.originX, info.originY);
            sprite.body.setOffset(info.offsetX, info.offsetY);
        }
        sprite.body.updateFromGameObject();
    }
}
