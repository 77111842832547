import {ButtonSize, ButtonType, GuiButton} from '../gui-button';
import {ElementType, GuiElement} from '../gui-element';
import {GuiText, TextColor, TextType} from '../gui-text';
import {tweenHide, tweenShow} from '../gui-tweens';
import {GuiWindow, WindowType} from '../gui-window';
import {Screen, ScreenParams} from './screen';
import Container = Phaser.GameObjects.Container;
import BaseSound = Phaser.Sound.BaseSound;

export class GameCompletedScreen extends Screen {
    private container: Container;
    private windowHeight: number;
    private completedSound: BaseSound;
    public static readonly key = 'gui/screen/game-completed';

    constructor() {
        super(GameCompletedScreen.key);
    }

    protected onShow(data: ScreenParams): Promise<void> {
        this.completedSound = this.sound.add('music/badge-found', {loop: false, volume: 0.6});
        this.initHandlers();
        this.createElements();
        return tweenShow(this, this.container, {
            y: this.windowHeight / 2
        }).then(() => {
            this.completedSound.play();
        });
    }

    protected onHide(): Promise<void> {
        return tweenHide(this, this.container, {
            y: -this.windowHeight / 2
        }).then(() => {
            this.completedSound.stop();
        });
    }

    private initHandlers(): void {
        this.input.on('pointerdown', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
        this.input.keyboard.on('keydown-ENTER', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
        this.input.keyboard.on('keydown-ESC', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
    }

    private createElements(): void {
        const wnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.small
        });
        this.windowHeight = wnd.height;

        const title = new GuiText({
            scene: this,
            x: -50,
            y: -140,
            text: 'YEAH!',
            textType: TextType.ravie,
            fontSize: 24,
            color: TextColor.yellow
        });
        const msgTitle = new GuiText({
            scene: this,
            x: -300,
            y: -50,
            width: 600,
            text: 'Congratulations!',
            textType: TextType.ravie,
            fontSize: 28,
            color: TextColor.brown
        });

        const congrats = new GuiElement({
            elementType: ElementType.congratulations,
            scene: this,
            x: 0,
            y: 100
        });

        const okBtn = new GuiButton({
            scene: this,
            x: 0,
            y: 290,
            tag: 'ok',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.ok,
            onClick: () => {
                this.onButtonClick('ok');
            }
        });

        this.container = this.add.container(this.game.canvas.width / 2, -this.windowHeight / 2);
        this.container.add(wnd);
        this.container.add(title);
        this.container.add(msgTitle);
        this.container.add(congrats);
        this.container.add(okBtn);
    }

    private onButtonClick(tag: string): void {
        this.closeScreen({
            button: tag
        });
    }
}
