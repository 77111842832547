import {ASSETS, AssetSpriteInfo, GuiAssets} from '../assets';
import {globalConfig} from '../config';
import {GUI} from '../gui/gui';
import {ButtonSize, ButtonType, GuiButton} from '../gui/gui-button';
import {GuiText, TextColor, TextType} from '../gui/gui-text';
import {ScreensService} from '../gui/screens-service';
import {GameInputs} from '../helpers/game-inputs';
import {GameStore} from '../helpers/game-store';
import {
    EVENT_CHECKPOINT_ACTIVATE,
    EVENT_DISABLE_SOUND,
    EVENT_ENABLE_SOUND,
    EVENT_SHOW_UNLOCKED_BADGES,
    GlobalEvents
} from '../helpers/global-events';
import {GlobalObjects, PlayerData} from '../helpers/global-objects';
import {GoogleTagManager} from '../helpers/google-tag-manager';
import {MobileDetector} from '../helpers/mobile-detector';
import {SceneLoader} from '../helpers/scene-loader';
import {TimeHelper} from '../helpers/time-helper';
import {VirtualGamePad} from '../helpers/virtual-game-pad';
import {MapLoader} from '../map/map-loader';
import {Parallax} from '../map/parallax';
import {AmmoSprite} from '../sprites/ammo-sprite';
import {InteractiveObjectInterface} from '../sprites/interactive-object-interface';
import {PlayerSprite} from '../sprites/player-sprite';
import {SceneData, ScreenButtonType} from './definitions/scenes-models';
import {MainGameScene} from './main-game-scene-interface';
import {SCENES} from './scenes';
import Group = Phaser.GameObjects.Group;
import BaseSound = Phaser.Sound.BaseSound;
import Container = Phaser.GameObjects.Container;
import Vector2 = Phaser.Math.Vector2;

export class AnyLevelScene extends Phaser.Scene implements MainGameScene {
    private gtm = GoogleTagManager.resolve();
    private map: MapLoader;
    private player: PlayerSprite;
    private enemies: Group;
    private parallax: Parallax;
    private backgroundSound: BaseSound;
    private gui: GUI = GUI.resolve();
    private screens: ScreensService = ScreensService.resolve();
    private globalEvents: GlobalEvents = GlobalEvents.resolve();
    private inputs: GameInputs = GameInputs.resolve();
    private pad: VirtualGamePad;
    private loader: SceneLoader;
    private sceneData: SceneData;
    private debugContainer: Container;
    private debugText: GuiText;

    public static SCENE_KEY: string = 'scenes/any-level-scene';

    constructor() {
        super({
            key: AnyLevelScene.SCENE_KEY,
            active: false,
            visible: false
        });
    }

    public preload(): void {
        this.sceneData = SCENES[GlobalObjects.currentSceneName];
        this.loader = new SceneLoader(this);
        this.load.tilemapTiledJSON(this.sceneData.sceneTileMap.key, this.sceneData.sceneTileMap.filePath);

        // load GUI elements
        let guiName: keyof GuiAssets;
        for (guiName in ASSETS.gui) {
            const info = <AssetSpriteInfo>ASSETS.gui[guiName];
            if (info.name && info.frameW && info.frameH) {
                this.load.spritesheet(info.name, info.path, {
                    frameWidth: info.frameW,
                    frameHeight: info.frameH,
                    startFrame: 0, endFrame: info.framesCount - 1
                });
            }
        }

        // images
        if (this.sceneData.images) {
            this.sceneData.images.forEach(asset => {
                this.load.image(asset.key, asset.filePath);
            });
        }

        // sprites
        if (this.sceneData.sprites) {
            this.sceneData.sprites.forEach(sprite => {
                for (const spriteInfoKey in sprite) {
                    const info = sprite[spriteInfoKey];
                    if (info.name) {
                        this.load.spritesheet(info.name, info.path, {
                            frameWidth: info.frameW,
                            frameHeight: info.frameH,
                            startFrame: 0, endFrame: info.framesCount - 1
                        });
                    }
                }
            });
        }

        // sounds
        if (this.sceneData.sounds) {
            this.sceneData.sounds.forEach(asset => {
                this.load.audio(asset.key, asset.filePath);
            });
        }


    }

    public unload(nextSceneData?: SceneData): void {
        this.cache.tilemap.remove(this.sceneData.sceneTileMap.key);

        const shouldUnloadImage = (imageKey: string): boolean => {
            return nextSceneData && nextSceneData.images && !(nextSceneData.images.find((i => i.key === imageKey)));
        };

        const shouldUnloadSprite = (spriteAsset: string, spriteKey: string): boolean => {
            if (nextSceneData && nextSceneData.sprites) {
                const spriteInfoAssets = nextSceneData.sprites.filter(x => !!x[spriteAsset]);
                const hasSprite = spriteInfoAssets.find(x => x[spriteAsset].name === spriteKey);
                return !hasSprite;
            }
            return true;
        };

        // images
        if (this.sceneData.images) {
            this.sceneData.images.forEach(asset => {
                if (shouldUnloadImage(asset.key)) {
                    this.textures.remove(asset.key);
                }
            });
        }

        // sprites
        if (this.sceneData.sprites) {
            this.sceneData.sprites.forEach(sprite => {
                for (const spriteInfoKey in sprite) {
                    const info = sprite[spriteInfoKey];
                    if (info.name && shouldUnloadSprite(spriteInfoKey, info.name)) {
                        this.textures.remove(info.name);
                    }
                }
            });
        }

        // animations created for sprites etc.
        const animations = this.anims.toJSON();
        if (animations.anims) {
            for (let i = 0; i < animations.anims.length; i++) {
                this.anims.remove(animations.anims[i].key);
            }
        }

        // sounds
        if (this.sceneData.sounds) {
            this.sceneData.sounds.forEach(asset => {
                if (nextSceneData && nextSceneData.sounds && !(nextSceneData.sounds.find((i => i.key === asset.key)))) {
                    this.cache.audio.remove(asset.key);
                }
            });
        }
    }

    public resetPlayerLocation(): void {
        const checkpoint = this.map.getCheckpoint(GameStore.activeCheckpoint);
        if (checkpoint) {
            const position = new Vector2(checkpoint.x, checkpoint.y);
            this.player.setPosition(position.x, position.y);
        }
        else {
            this.player.setPosition(this.map.playerStartPos.x, this.map.playerStartPos.y);
        }
        this.player.resetPlayer();
    }

    public create() {
        this.loader.destroy();
        GameStore.initLevelState(GlobalObjects.currentSceneName);
        const data: PlayerData = GameStore.playerData;
        this.inputs.bootstrap(this);
        this.enemies = this.add.group();
        GlobalObjects.setEnemies(this.enemies); // must be set before map Loader initialization

        this.backgroundSound = this.sound.add(this.sceneData.backgroundMusicKey, {
            loop: true,
            volume: 0.2
        });
        this.screens.setGameScene(this);
        this.player = new PlayerSprite(this, 0, 0, GlobalObjects.selectedPlayer);
        this.player.power = data.power;
        this.player.lives = data.lives;

        GlobalObjects.setPlayer(this.player);

        this.map = new MapLoader(this, this.sceneData.sceneTileMap.key, this.sceneData.tileSets, data.locationTarget);
        this.player.setDepth(1);

        this.physics.world.setBounds(0, 0, this.map.width, this.map.height);
        this.physics.world.TILE_BIAS = 128; // to not tunnel through tiles :p

        this.map.addPhysics();

        // special case for bosses
        if (GlobalObjects.boss) {
            this.physics.add.collider(this.player.getAmmo(), GlobalObjects.boss, (boss: any, flame: any) => {
                (<AmmoSprite>flame).killAmo();
                (<InteractiveObjectInterface>boss).hurt((<InteractiveObjectInterface>flame).hurtScore);
            });
        }

        this.parallax = new Parallax(this, this.map.bounds);

        this.cameras.main.setBounds(0, 0, this.map.width, this.map.height);
        this.cameras.main.startFollow(this.player, true);

        this.backgroundSound.play();
        this.initHuds();

        if (MobileDetector.isMobile()) {
            this.pad = new VirtualGamePad(this);
            this.player.setVirtualPad(this.pad);
        }

        // activate checkpoint
        if (GameStore.activeCheckpoint) {
            this.globalEvents.emit(EVENT_CHECKPOINT_ACTIVATE, GameStore.activeCheckpoint);
        }

        // setTimeout(() => {
        //     this.globalEvents.emit(EVENT_SHOW_MISSING_BADGES_INFO);
        // }, 5000);

        // game debug info
        if (globalConfig.debug) {
            const containerWidth = 500;
            const containerHeight = 100;
            this.debugContainer = this.add.container(this.game.canvas.width / 2, this.game.canvas.height - containerHeight / 2);
            this.debugContainer.setScrollFactor(0, 0);
            const bkgRect = new Phaser.GameObjects.Rectangle(this, 0, 0, containerWidth, containerHeight, 0x000000, 0.5);
            this.debugText = new GuiText({
                scene: this,
                x: 0,
                y: 0,
                width: containerWidth,
                text: `Game time: ${TimeHelper.getGameDurationTimeString()}`,
                textType: TextType.interSemiBold,
                textAlign: 'center',
                fontSize: 16,
                color: TextColor.white
            }).setOrigin(0.5);
            this.debugContainer.add(bkgRect);
            this.debugContainer.add(this.debugText);
        }
    }

    public update(time: number, delta: number): void {
        this.parallax.update();
        if (globalConfig.debug) {
            this.debugText.setText(`Game time: ${TimeHelper.getGameDurationTimeString()}`);
        }
        super.update(time, delta);
    }

    private initHuds(): void {
        // Add huds
        const hudsSpacing = 5;
        const top = 3;
        let left = 3;
        if (this.sceneData.huds) {
            this.sceneData.huds.forEach(hud => {
                const instance = hud(this);
                instance.setPosition(left, top);
                left += (instance.width * instance.scaleX) + hudsSpacing;
                this.add.existing(instance);
            });
        }

        // add gui buttons
        const buttonsSpacing = -10;
        let right = this.game.canvas.width - 3;
        let screenButtons = this.sceneData.screenButtons;
        if (!screenButtons) {
            screenButtons = [
                () => ScreenButtonType.FullScreen,
                () => ScreenButtonType.Badges,
                () => ScreenButtonType.Sound,
                () => ScreenButtonType.Exit
            ];
        }

        if (screenButtons) {
            for (let i = screenButtons.length - 1; i >= 0; i--) {
                const result = screenButtons[i](this, this.globalEvents);
                let guiButton: GuiButton;
                if (result instanceof GuiButton) {
                    guiButton = result as GuiButton;
                }
                else {
                    switch (result as ScreenButtonType) {
                        case ScreenButtonType.Exit:
                            guiButton = this.addExitButton();
                            break;

                        case ScreenButtonType.Badges:
                            guiButton = this.addBadgesButton();
                            break;

                        case ScreenButtonType.Sound:
                            guiButton = this.addSoundButton();
                            break;

                        case ScreenButtonType.FullScreen:
                            guiButton = this.addFullScreenButton();
                            break;
                    }
                }

                if (guiButton) {

                    if (MobileDetector.isMobile()) {
                        guiButton.changeSize(ButtonSize.normal); // force size on mobile
                    }

                    guiButton.setOrigin(1, 0)
                             .setPosition(right, top)
                             .setScrollFactor(0, 0);
                    right -= (guiButton.width * guiButton.scaleX) + buttonsSpacing;
                    this.add.existing(guiButton);
                }
            }
        }


    }

    private addExitButton(): GuiButton {
        return new GuiButton({
            scene: this,
            x: 0,
            y: 0,
            buttonType: ButtonType.cancel,
            zoomOnHover: false,
            buttonSize: ButtonSize.small,
            onClick: () => {
                this.gtm.buttonClicked(`${GlobalObjects.currentSceneName}:EXIT`);
                this.gui.exitGameQuestion()
                    .then(() => {
                    });
            }
        });
    }

    private addBadgesButton(): GuiButton {
        return new GuiButton({
            scene: this,
            x: 0,
            y: 0,
            buttonType: ButtonType.badge,
            zoomOnHover: false,
            buttonSize: ButtonSize.small,
            onClick: () => {
                this.gtm.buttonClicked(`${GlobalObjects.currentSceneName}:SHOW-BADGES`);
                this.globalEvents.emit(EVENT_SHOW_UNLOCKED_BADGES);
            }
        });
    }

    private addSoundButton(): GuiButton {
        const soundBtn = new GuiButton({
            scene: this,
            x: 0,
            y: 0,
            buttonType: this.sound.mute ? ButtonType.soundOff : ButtonType.soundOn,
            zoomOnHover: false,
            buttonSize: ButtonSize.small,
            onClick: () => {
                if (soundBtn.getBtnType() === ButtonType.soundOn) {
                    soundBtn.changeType(ButtonType.soundOff);
                    this.globalEvents.emit(EVENT_DISABLE_SOUND);
                    this.gtm.buttonClicked(`${GlobalObjects.currentSceneName}:SOUND-OFF`);
                }
                else {
                    soundBtn.changeType(ButtonType.soundOn);
                    this.globalEvents.emit(EVENT_ENABLE_SOUND);
                    this.gtm.buttonClicked(`${GlobalObjects.currentSceneName}:SOUND-ON`);
                }
            }
        });
        return soundBtn;
    }

    private addFullScreenButton(): GuiButton {
        const soundBtn = new GuiButton({
            scene: this,
            x: 0,
            y: 0,
            buttonType: this.scale.isFullscreen ? ButtonType.fullScreenOff : ButtonType.fullScreenOn,
            zoomOnHover: false,
            buttonSize: ButtonSize.small,
            onClick: () => {
                if (soundBtn.getBtnType() === ButtonType.fullScreenOn) {
                    soundBtn.changeType(ButtonType.fullScreenOff);
                    this.scale.startFullscreen();
                    this.gtm.buttonClicked(`${GlobalObjects.currentSceneName}:FULLSCREEN-ON`);
                }
                else {
                    soundBtn.changeType(ButtonType.fullScreenOn);
                    this.scale.stopFullscreen();
                    this.gtm.buttonClicked(`${GlobalObjects.currentSceneName}:FULLSCREEN-OFF`);
                }
            }
        });
        return soundBtn;
    }
}
