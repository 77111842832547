import {GroundCollider} from '../map/ground-collider';
import { BadgeType, GameDifficultyLevel } from '../map/map-helper';
import {StaticPlatformCollider} from '../map/static-platform-collider';
import {BossSprite} from '../sprites/boss-sprite';
import {MovingPlatformSprite} from '../sprites/moving-platform-sprite';
import {PlayerSprite} from '../sprites/player-sprite';
import {PlayerType} from '../sprites/player-type.enum';
import Group = Phaser.GameObjects.Group;

export class GlobalObjects {

    private static _gameStartedTime: Date;
    public static get gameStartedTime(): Date {
        return GlobalObjects._gameStartedTime;
    }

    private static _game: Phaser.Game;
    public static get game(): Phaser.Game {
        return GlobalObjects._game;
    }

    private static _currentSceneName: string;
    public static get currentSceneName(): string {
        return GlobalObjects._currentSceneName;
    }

    private static _selectedPlayer: PlayerType;
    public static get selectedPlayer(): PlayerType {
        return GlobalObjects._selectedPlayer;
    }

    private static _player: PlayerSprite;
    public static get player(): PlayerSprite {
        return GlobalObjects._player;
    }

    private static _enemies: Group;
    public static get enemies(): Group {
        return GlobalObjects._enemies;
    }

    private static _boss: BossSprite;
    public static get boss(): BossSprite {
        return GlobalObjects._boss;
    }

    private static _movingPlatforms: MovingPlatformSprite[];
    public static get movingPlatforms(): MovingPlatformSprite[] {
        return GlobalObjects._movingPlatforms;
    }

    private static _staticPlatforms: StaticPlatformCollider[];
    public static get staticPlatforms(): StaticPlatformCollider[] {
        return GlobalObjects._staticPlatforms;
    }

    private static _grounds: GroundCollider[];
    public static get grounds(): GroundCollider[] {
        return GlobalObjects._grounds;
    }

    private static _usePadController: boolean = true;
    public static get usePadController(): boolean {
        return GlobalObjects._usePadController;
    }
    
    private static _difficultyLevel: GameDifficultyLevel;
    public static get difficultyLevel(): GameDifficultyLevel {
        return GlobalObjects._difficultyLevel;
    }

    public static setGame(instance: Phaser.Game): void {
        GlobalObjects._game = instance;
    }

    public static setCurrentSceneName(name: string): void {
        GlobalObjects._currentSceneName = name;
    }

    public static setBoss(boss: BossSprite): void {
        GlobalObjects._boss = boss;
    }

    public static setPlayer(player: PlayerSprite): void {
        GlobalObjects._player = player;
    }

    public static setEnemies(enemies: Group): void {
        GlobalObjects._enemies = enemies;
    }

    public static setMovingPlatforms(platforms: MovingPlatformSprite[]): void {
        GlobalObjects._movingPlatforms = platforms;
    }

    public static setStaticPlatforms(platforms: StaticPlatformCollider[]): void {
        GlobalObjects._staticPlatforms = platforms;
    }

    public static setGrounds(grounds: GroundCollider[]): void {
        GlobalObjects._grounds = grounds;
    }

    public static setSelectedPlayer(type: PlayerType): void {
        GlobalObjects._selectedPlayer = type;
    }

    public static setUsePadController(usePad: boolean): void {
        GlobalObjects._usePadController = usePad;
    }

    public static setGameStartedTime(date: Date): void {
        GlobalObjects._gameStartedTime = date;
    }
    
    public static setDifficultyLevel(level: GameDifficultyLevel): void {
        GlobalObjects._difficultyLevel = level;
    }
}

/**
 * Reusable player data across levels / scenes.
 */
export interface PlayerData {
    /**
     * Player power energy to transfer to a new scene.
     */
    power: number;
    /**
     * Player lives count to transfer to a new scene.
     */
    lives: number;
    /**
     * A set of already collected badges.
     */
    badges: BadgeType[];
    /**
     * Name of the new location target in the scene. If empty, then using player start location.
     */
    locationTarget?: string;
}
