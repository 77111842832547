import {ASSETS, AssetSpriteInfo, ChimneyEnemyAsset, GuiAssets, PlayerAssets} from '../assets';
import {GUI} from '../gui/gui';
import {GuiSkyBackground} from '../gui/gui-sky-background';
import {ScreensService} from '../gui/screens-service';
import {GlobalObjects} from '../helpers/global-objects';
import {GoogleTagManager} from '../helpers/google-tag-manager';
import {SceneLoader} from '../helpers/scene-loader';
import {PlayerType} from '../sprites/player-type.enum';
import {MainGameScene} from './main-game-scene-interface';
import BaseSound = Phaser.Sound.BaseSound;
import Sprite = Phaser.GameObjects.Sprite;
import Image = Phaser.GameObjects.Image;

export class WelcomeScene extends Phaser.Scene implements MainGameScene {
    private gtm = GoogleTagManager.resolve();
    private screens: ScreensService = ScreensService.resolve();
    private sky: GuiSkyBackground;
    private gui: GUI = GUI.resolve();
    private backgroundSound: BaseSound;
    private currentType: PlayerType;
    private animation: Phaser.Animations.Animation;
    private standingPlayer: Sprite;
    private standingChimney: Sprite;
    private welcomeSceneTop: Image;
    private welcomeSceneBottom: Image;
    private loader: SceneLoader;

    public static SCENE_KEY: string = 'scenes/welcome';


    constructor() {
        super({
            key: WelcomeScene.SCENE_KEY,
            active: false,
            visible: false
        });
    }

    public preload(): void {
        this.loader = new SceneLoader(this, 'Uhh...a lot to load....\nNext time will be faster, really :)');
        this.load.image('welcome-scene/top', './assets/images/gui/welcome-screen-top.png');
        this.load.image('welcome-scene/bottom', './assets/images/gui/welcome-screen-bottom.png');
        this.load.image('welcome-scene/ornaments', './assets/images/gui/welcome-screen-ornaments.png');
        this.load.html('form', './assets/dom/form.html');

        // players loading
        const players = [ASSETS.player1, ASSETS.player2, ASSETS.player3, ASSETS.player4, ASSETS.player5];
        players.forEach((playerAsset) => {
            let playerName: keyof PlayerAssets;
            for (playerName in playerAsset) {
                if (playerName === 'idle' || playerName === 'idleBlink') {
                    const info = playerAsset[playerName];
                    this.load.spritesheet(info.name, info.path, {
                        frameWidth: info.frameW,
                        frameHeight: info.frameH,
                        startFrame: 0, endFrame: info.framesCount - 1
                    });
                }
            }
        });

        // load chimney assets
        let chimneyName: keyof ChimneyEnemyAsset;
        for (chimneyName in ASSETS.chimneyEnemy) {
            const info = ASSETS.chimneyEnemy[chimneyName];
            this.load.spritesheet(info.name, info.path, {
                frameWidth: info.frameW,
                frameHeight: info.frameH,
                startFrame: 0, endFrame: info.framesCount - 1
            });
        }

        // parallax backgrounds
        this.load.image('background/light/1', './assets/images/backgrounds/parallax-1.png');
        this.load.image('background/light/2', './assets/images/backgrounds/parallax-2.png');
        this.load.image('background/light/3', './assets/images/backgrounds/parallax-3.png');
        this.load.image('background/light/4', './assets/images/backgrounds/parallax-4.png');
        this.load.image('background/light/5', './assets/images/backgrounds/parallax-5.png');
        this.load.image('background/light/6', './assets/images/backgrounds/parallax-6.png');
        this.load.image('background/light/7', './assets/images/backgrounds/parallax-7.png');

        // load GUI elements
        let guiName: keyof GuiAssets;
        for (guiName in ASSETS.gui) {
            const info = <AssetSpriteInfo>ASSETS.gui[guiName];
            if (info.name && info.frameW && info.frameH) {
                this.load.spritesheet(info.name, info.path, {
                    frameWidth: info.frameW,
                    frameHeight: info.frameH,
                    startFrame: 0, endFrame: info.framesCount - 1
                });
            }
        }

        this.load.audio('music/button', './assets/sounds/button.mp3');
        this.load.audio('music/click', './assets/sounds/click.mp3');
        this.load.audio('music/background-happy', './assets/sounds/background.mp3');
    }

    public unload(): void {
        this.textures.remove('welcome-scene/top');
        this.textures.remove('welcome-scene/bottom');
        this.textures.remove('welcome-scene/ornaments');
        this.cache.html.remove('form');
        //
        // const players = [ASSETS.player1, ASSETS.player2, ASSETS.player3, ASSETS.player4, ASSETS.player5];
        // players.forEach((playerAsset) => {
        //     let playerName: keyof PlayerAssets;
        //     for (playerName in playerAsset) {
        //         const info = playerAsset[playerName];
        //         this.textures.remove(info.name)
        //     }
        // });
        //
        // let chimneyName: keyof ChimneyEnemyAsset;
        // for (chimneyName in ASSETS.chimneyEnemy) {
        //     const info = ASSETS.chimneyEnemy[chimneyName];
        //     this.textures.remove(info.name)
        // }
        //
        // this.textures.remove('background/light/1');
        // this.textures.remove('background/light/2');
        // this.textures.remove('background/light/3');
        // this.textures.remove('background/light/4');
        // this.textures.remove('background/light/5');
        // this.textures.remove('background/light/6');
        // this.textures.remove('background/light/7');
        //
        // let guiName: keyof GuiAssets;
        // for (guiName in ASSETS.gui) {
        //     const info = <AssetSpriteInfo>ASSETS.gui[guiName];
        //     if (info.name && info.frameW && info.frameH) {
        //         this.textures.remove(info.name);
        //     }
        // }
        //
        // this.cache.audio.remove('music/button');
        // this.cache.audio.remove('music/click');
        // this.cache.audio.remove('music/background-happy');
    }

    public resetPlayerLocation(): void {
        // N/A
    }

    public create() {
        this.loader.destroy();
        this.screens.setGameScene(this);
        this.backgroundSound = this.sound.add('music/background-happy', {
            loop: true,
            volume: 0.2
        });
        this.sky = new GuiSkyBackground(this);

        this.welcomeSceneTop = this.add.image(this.cameras.main.width / 2, 0, 'welcome-scene/top').setOrigin(0.5, 0).setScale(0.6);
        this.welcomeSceneBottom = this.add.image(this.cameras.main.width / 2, this.cameras.main.height, 'welcome-scene/bottom').setOrigin(0.5, 1).setScale(0.6);

        this.currentType = GlobalObjects.selectedPlayer;
        this.standingPlayer = this.createStandingPlayer();
        this.standingChimney = this.createStandingChimney();

        this.backgroundSound.play();
        this.gui.startWelcomeScreen();
        this.scale.stopFullscreen();

        this.gtm.sceneEnter('WelcomeScreen');
    }

    public update(time: number, delta: number): void {
        this.sky.update();
        if (this.currentType != GlobalObjects.selectedPlayer) {
            this.currentType = GlobalObjects.selectedPlayer;
            this.standingPlayer.destroy();
            this.animation.destroy();
            this.standingPlayer = this.createStandingPlayer();
        }
    }

    private createStandingPlayer(): Sprite {
        const asset = <PlayerAssets>ASSETS[this.currentType];
        this.animation = this.anims.create({
            key: 'anim/standing-player',
            repeat: -1,
            frames: [
                ...this.anims.generateFrameNames(asset.idle.name),
                ...this.anims.generateFrameNames(asset.idle.name),
                ...this.anims.generateFrameNames(asset.idleBlink.name)
            ],
            frameRate: asset.idle.fps
        }) as Phaser.Animations.Animation;
        const playerX = this.cameras.main.width / 2 - 530;
        const playerY = this.cameras.main.height - 490;

        return this.add.sprite(playerX, playerY, 'anim/standing-player')
                   .setOrigin(0.5, 1)
                   .play('anim/standing-player');
    }

    private createStandingChimney(): Sprite {
        this.anims.create({
            key: 'anim/standing-chimney',
            repeat: -1,
            frames: ASSETS.chimneyEnemy.idle.name,
            frameRate: ASSETS.chimneyEnemy.idle.fps
        });
        const x = this.cameras.main.width / 2 + 530;
        const y = this.cameras.main.height - 540;
        return this.add.sprite(x, y, 'standing-chimney').play('anim/standing-chimney');
    }
}
