import {ASSETS, ChimneyEnemyAsset} from '../assets';
import { POWER_VALUE } from '../constants';
import {EVENT_ENEMY_KILLED, GlobalEvents} from '../helpers/global-events';
import {GlobalObjects} from '../helpers/global-objects';
import {globalConfig} from '../config';
import { GameDifficultyLevel } from '../map/map-helper';
import {AmmoSprite, AmmoType} from './ammo-sprite';
import {GameSpriteHelper} from './game-sprite-helper';
import {GameStoreObject} from './game-store-object';
import {InteractiveObjectInterface} from './interactive-object-interface';
import Animation = Phaser.Animations.Animation;
import {Obstacle} from './obstacle-base';
import BaseSound = Phaser.Sound.BaseSound;

enum States {
    IDLE = 0,
    ATTACK = 1,
    DIE
}

export class ChimneySprite extends Obstacle implements InteractiveObjectInterface, GameStoreObject {
    private asset: ChimneyEnemyAsset = ASSETS.chimneyEnemy;
    private debugText: Phaser.GameObjects.Text;
    private ammo: Phaser.GameObjects.Group;
    private bounds: Phaser.Geom.Rectangle;
    private isDead: boolean = false;
    private readonly player: Phaser.Physics.Arcade.Sprite;
    private isGoingToStopAttacking: boolean = true;
    private isAttacking: boolean;
    private isInCamRange: boolean;
    private dangerSound: BaseSound;
    private destroySound: BaseSound;
    private globalEvents = GlobalEvents.resolve();
    private readonly activationDistance: number;
    private readonly shootSpeed: number;
    private readonly destroyShootAfter: number;
    private readonly shootHurtScore: number;

    public body: Phaser.Physics.Arcade.Body;
    public readonly hurtScore: number; // by touching the chimney, we lose some power
    public power: number;
    
    public objectId: string;

    get isCloseToPlayer(): boolean {
        const distance = Math.sqrt(Math.pow(this.player.x - this.x, 2) + Math.pow(this.player.y - this.y, 2));
        return this.player ? this.isInCamRange && (distance < this.activationDistance) : false;
    }

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y, 'enemy-chimney');
        this.scene.add.existing(this);
        this.scene.physics.world.enable(this);
        
        switch (GlobalObjects.difficultyLevel) {
            case GameDifficultyLevel.easy:
                this.hurtScore = POWER_VALUE * 0.1;
                this.power = POWER_VALUE * 0.1;
                this.activationDistance = 500;
                this.shootSpeed = 400;
                this.destroyShootAfter = 1180;
                this.shootHurtScore = 5;
                break;
            
            case GameDifficultyLevel.normal:
                this.hurtScore = POWER_VALUE * 0.2;
                this.power = POWER_VALUE * 0.2;
                this.activationDistance = 650;
                this.shootSpeed = 500;
                this.destroyShootAfter = 1250;
                this.shootHurtScore = 10;
                break;
            
            case GameDifficultyLevel.hard:
                this.hurtScore = POWER_VALUE * 0.3;
                this.power = POWER_VALUE * 0.3;
                this.activationDistance = 750;
                this.shootSpeed = 700;
                this.destroyShootAfter = 1000;
                this.shootHurtScore = 20;
                break;
        }

        this.body
            .setAllowGravity(false)
            .setCollideWorldBounds(true)
            .setImmovable(true);

        this.dangerSound = this.scene.sound.add('music/danger', {loop: true, volume: 0.2});
        this.destroySound = this.scene.sound.add('music/destroy', {loop: false, volume: 0.3});

        this.ammo = this.scene.physics.add.group({
            allowGravity: false,
            mass: 0.00000001
        });
        this.scene.physics.add.collider(this.ammo, GlobalObjects.player, (player: any, flame: any) => {
            (<AmmoSprite>flame).killAmo();
            (<InteractiveObjectInterface>player).hurt((<InteractiveObjectInterface>flame).hurtScore);
        });

        this.player = GlobalObjects.player;

        if (globalConfig.debug) {
            this.debugText = this.scene.add.text(x - 50, y - 260, `Pow: ${this.power}`);
            this.debugText.setDepth(999);
        }

        if (!this.scene.anims.exists('enemy-chimney/idle')) { // reuse animations
            this.scene.anims.create({
                key: 'enemy-chimney/idle',
                repeat: -1,
                frames: this.asset.idle.name,
                frameRate: this.asset.idle.fps
            });
            this.scene.anims.create({
                key: 'enemy-chimney/attack',
                repeat: -1,
                frames: this.asset.attack.name,
                frameRate: this.asset.attack.fps
            });
            this.scene.anims.create({
                key: 'enemy-chimney/kill',
                repeat: 0,
                frames: ASSETS.effects.effect6.name,
                frameRate: ASSETS.effects.effect6.fps
            });
        }

        this.setState(States.IDLE);

        /**
         * Setting state ATTACK -> IDLE triggers animation stop
         * in this case we have to set the flag to false
         */
        this.on(Phaser.Animations.Events.ANIMATION_STOP, (anim: Animation) => {
            if (anim.key === 'enemy-chimney/attack') {
                this.isAttacking = false;
            }
        });

        this.on(Phaser.Animations.Events.ANIMATION_REPEAT, (anim: Animation) => {
            if (anim.key === 'enemy-chimney/attack') {
                if (this.isGoingToStopAttacking) {
                    this.isAttacking = false;
                } else {
                    // need to set the state again to throw ammo
                    this.setState(States.ATTACK);
                }
            }
        });

        this.bounds = this.getBounds();
    }

    public preUpdate(time: number, delta: number): void {
        this.isInCamRange = Phaser.Geom.Rectangle.Overlaps(this.scene.cameras.main.worldView, this.bounds);
        switch (this.state) {
            case States.IDLE:
                if (this.isCloseToPlayer) {
                    this.setState(States.ATTACK);
                    this.dangerSound.play();
                }
                break;

            case States.ATTACK:
                // flip body relative to player position
                this.setFlipX(this.player.x > this.x);
                if (!this.isCloseToPlayer) {
                    this.isGoingToStopAttacking = true;
                }
                else {
                    this.isGoingToStopAttacking = false;
                    GameSpriteHelper.setBodyParams(this, this.asset.attack); // update body
                }

                if (this.isGoingToStopAttacking && !this.isAttacking) {
                    this.setState(States.IDLE);
                    this.dangerSound.stop();
                }
                break;
            case States.DIE:

                this.on(Phaser.Animations.Events.ANIMATION_COMPLETE, (animation: Animation) => {
                    if (animation.key === 'enemy-chimney/kill') {
                        this.destroy();
                    }
                }, this);
                this.play('enemy-chimney/kill', true);
                break;
        }

        super.preUpdate(time, delta);
    }

    public setState(value: States): this {
        switch (value) {
            case States.IDLE:
                GameSpriteHelper.setBodyParams(this, this.asset.idle);
                this.play('enemy-chimney/idle', true);
                break;

            case States.ATTACK:
                GameSpriteHelper.setBodyParams(this, this.asset.attack);
                this.play('enemy-chimney/attack', true);
                this.attackThrow();
                break;
        }

        return super.setState(value);
    }

    public hurt(amount: number): void {
        this.power -= amount;
        if (globalConfig.debug) {
            this.debugText.setText(`Pow: ${this.power}
            isGoingToStopAttacking: ${this.isGoingToStopAttacking}`);
        }
        if (this.power <= 0) {
            this.destroySound.play();
            this.dangerSound.stop();
            this.isDead = true;
            this.globalEvents.emit(EVENT_ENEMY_KILLED, this.objectId);
            this.setState(States.DIE);
        }
        else {
            this.alpha = 0.3;
            this.scene.tweens.add({
                targets: this,
                duration: 500,
                props: {
                    alpha: 1
                }
            });
        }
    }

    public destroy(fromScene?: boolean): void {
        if (globalConfig.debug) {
            this.debugText.destroy();
        }
        super.destroy();
    }

    private attackThrow(): void {
        this.isAttacking = true;
        this.scene.time.delayedCall(2000, () => this.throwSoot());
    }

    private throwSoot(): void {
        if (this.isAttacking && !this.isDead) {
            // flip body relative to player position
            this.setFlipX(this.player.x > this.x);
            const soot = new AmmoSprite(this.scene, this.x, this.y - 90, this.flipX, AmmoType.Soot, this.destroyShootAfter, this.shootHurtScore);
            this.ammo.add(soot);
            this.scene.physics.moveTo(soot, this.player.x, this.player.y - 60, this.shootSpeed);
        }
    }
}
