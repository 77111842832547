import {PathFollower} from '../helpers/path-follower';
import {PlatformSize} from '../map/map-helper';
import Vector2 = Phaser.Math.Vector2;

export class MovingPlatformSprite extends Phaser.Physics.Arcade.Sprite {
    private platformHeight = 15;
    private leftRightMargins = 10;
    private pathFollower: PathFollower;
    public body: Phaser.Physics.Arcade.Body;

    constructor(
        scene: Phaser.Scene,
        x: number, y: number, w: number, h: number,
        platformSize: PlatformSize, startPos: number,
        path: Vector2[]) {
        super(scene, x, y, 'moving-platform');

        this.setTexture(`platform/${platformSize}`);
        this.scene.add.existing(this);
        this.scene.physics.add.existing(this);
        this.setOrigin(0.5, 0);
        this.body
            .setImmovable(true)
            .setAllowGravity(false)
            .setSize(this.width - 2 * this.leftRightMargins, this.platformHeight)
            .setOffset(this.leftRightMargins, 0);
        this.debugBodyColor = 0x0000FF;

        this.pathFollower = new PathFollower(this.scene, this, this.x, this.y - this.displayOriginY, startPos, path, 1000, 'moving-platform');
        this.pathFollower.startPause(true);
    }
}


