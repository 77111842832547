import {Scene} from 'phaser';
import TileSprite = Phaser.GameObjects.TileSprite;

export class Parallax {
    private mapW: number;
    private mapH: number;
    private bg: TileSprite[] = [];
    private config = {
        count: 7,
        depths: [
            -1000, -990, -980, -970, -960, -950, -940
        ],
        offsets: [
            {x: 0, y: 0},
            {x: 0, y: 0},
            {x: 0, y: 0},
            {x: 0, y: +100},
            {x: 0, y: 0},
            {x: 0, y: -100},
            {x: 0, y: 2} // hide small alpha gap for this texture
        ],
        origins: [
            {x: 0, y: 0},
            {x: 0, y: 0},
            {x: 0, y: 1},
            {x: 0, y: 1},
            {x: 0, y: 1},
            {x: 0, y: 1},
            {x: 0, y: 1}
        ],
        scrollFactors: [
            {x: 0.01, y: 0.01},
            {x: 0.05, y: 0.025},
            {x: 0.06, y: 0.05},
            {x: 0.1, y: 0.08},
            {x: 0.25, y: 0.1},
            {x: 0.4, y: 0.2},
            {x: 0.6, y: 0.4}
        ],
        textures: [
            {name: 'background/light/1', width: 0, height: 0}, // background
            {name: 'background/light/2', width: 0, height: 0}, // flying clouds
            {name: 'background/light/3', width: 0, height: 0}, // fog 1
            {name: 'background/light/4', width: 0, height: 0}, // fog 2
            {name: 'background/light/5', width: 0, height: 0}, // mountains
            {name: 'background/light/6', width: 0, height: 0}, // trees and hills
            {name: 'background/light/7', width: 0, height: 0}  // front bushes
        ]
    };

    constructor(private scene: Scene, private mapBounds: Phaser.Geom.Rectangle) {
        this.mapW = mapBounds.width;
        this.mapH = mapBounds.height;
        this.prepareConfig();
        this.createBackgroundSprites();
    }

    public update(): void {

        for (let i = 0; i < this.config.count; i++) {
            this.bg[i].tilePositionX = this.scene.cameras.main.scrollX * this.config.scrollFactors[i].x;

            if (this.config.origins[i].y == 1) {
                this.bg[i]
                    .setPosition(
                        this.bg[i].x + this.config.offsets[i].x,
                        (this.mapH - this.scene.game.canvas.height) * this.bg[i].scrollFactorY + this.scene.game.canvas.height + this.config.offsets[i].y
                    );
            }

            // update width on game resize
            this.bg[i].setSize(this.scene.game.canvas.width, this.bg[i].height);
        }
    }

    private prepareConfig(): void {
        for (let i = 0; i < this.config.count; i++) {
            const img = this.scene.textures.get(this.config.textures[i].name).getSourceImage();
            this.config.textures[i].width = img.width;
            this.config.textures[i].height = img.height;
        }
    }

    private createBackgroundSprites(): void {

        for (let i = 0; i < this.config.count; i++) {

            this.bg[i] = this.scene.add
                             .tileSprite(0, 0, this.scene.game.canvas.width, this.config.textures[i].height, this.config.textures[i].name)
                             .setOrigin(this.config.origins[i].x, this.config.origins[i].y)
                             .setScrollFactor(0, this.config.scrollFactors[i].y)
                             .setDepth(this.config.depths[i]);

        }
    }
}
