import Container = Phaser.GameObjects.Container;
import {tweenHide, tweenShow} from '../gui-tweens';
import { Screen } from './screen';
import { GuiWindow, WindowType } from '../gui-window';
import { GuiText, TextColor, TextType } from '../gui-text';
import { ElementType, GuiElement } from '../gui-element';
import { BadgeType } from '../../map/map-helper';
import { BadgeService } from '../bagde-service';
import { ButtonSize, ButtonType, GuiButton } from '../gui-button';

const titles = {
    [BadgeType.freeHeatingService]: 'Free Heating Service',
    [BadgeType.friendsAndFamily]: 'Friends & Family',
    [BadgeType.geofencing]: 'Geofencing',
    [BadgeType.smartThermostat]: 'Viessmann Smart Thermostat',
    [BadgeType.surpriseGift]: 'Viessmann Surprise Gift',
    [BadgeType.heatingCurveAssistant]: 'Heating Curve Assistant'
};

export class BadgesScreen extends Screen {
    private container: Container;
    private windowHeight: number;
    public static readonly key = 'gui/screen/badges';
    private badgeService: BadgeService = BadgeService.resolve();

    constructor() {
        super(BadgesScreen.key);
    }

    protected onShow(): Promise<void> {
        this.initHandlers();

        this.createElements();
        return tweenShow(this, this.container, {
            y: this.windowHeight / 2
        });
    }

    protected onHide(): Promise<void> {
        return tweenHide(this, this.container, {
            y: -this.windowHeight / 2
        });
    }

    private initHandlers(): void {
        this.input.keyboard.on('keydown-ENTER', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
        this.input.keyboard.on('keydown-ESC', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
    }

    private createElements(): void {
        const wnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.big
        });
        this.windowHeight = wnd.height;

        const content = new GuiWindow({
            scene: this,
            x: 0,
            y: 70,
            windowType: WindowType.contentGroup
        });

        const title = new GuiText({
            scene: this,
            x: -99,
            y: -205,
            text: 'Your badges',
            textType: TextType.ravie,
            fontSize: 24,
            color: TextColor.yellow
        });

        const subtitle = new GuiText({
            scene: this,
            x: -180,
            y: -130,
            text: 'Badges open a final BOSS level. Collect them all!',
            textType: TextType.interSemiBold,
            fontSize: 15,
            color: TextColor.black
        });

        const okBtn = new GuiButton({
            scene: this,
            x: 165,
            y: 350,
            tag: 'ok',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.ok,
            onClick: this.onButtonClick.bind(this)
        });

        this.container = this.add.container(this.game.canvas.width / 2, -this.windowHeight / 2);
        this.container.add(wnd);
        this.container.add(content);
        this.container.add(title);
        this.container.add(subtitle);
        this.container.add(okBtn);
        this.createBadges();
    }

    private createBadges(): void {
        Object.values(BadgeType).forEach((badge: BadgeType, index) => {
            const x = (index % 3) * 140 - 140;
            const y = Math.floor(index / 3) * 160 - 20;
            const badgeUnlocked = this.badgeService.isBadgeUnlocked(badge);

            if (badgeUnlocked) {
                // add button
                this.container.add(new GuiButton({
                    scene: this,
                    y,
                    x,
                    buttonType: ButtonType.itemSlot,
                    zoomOnHover: true,
                    buttonSize: ButtonSize.big,
                    onClick: () => {
                        this.closeScreen({
                            button: 'show-badge-info',
                            data: { badge }
                        });
                    }
                }));

                // add text under button
                this.container.add(new GuiText({
                    scene: this,
                    x: x - 53,
                    y: y + 63,
                    text: titles[badge],
                    textType: TextType.interSemiBold,
                    fontSize: 12,
                    color: TextColor.brown,
                    width: 110,
                    wordWrap: true
                }));

                // add badge image
                this.container.add(new GuiElement({
                    elementType: this.resolveElementType(badge),
                    scene: this,
                    x,
                    y: y - 2,
                    scale: 0.57
                }));
            } else {
                this.container.add(new GuiElement({
                    elementType: badgeUnlocked ? ElementType.itemSlot : ElementType.itemSlotLocked,
                    scene: this,
                    x,
                    y
                }));
            }
        });
    }

    private resolveElementType(badge: BadgeType): ElementType {
        switch (badge) {
            case BadgeType.freeHeatingService:
                return ElementType.badge1;

            case BadgeType.friendsAndFamily:
                return ElementType.badge2;

            case BadgeType.geofencing:
                return ElementType.badge3;

            case BadgeType.heatingCurveAssistant:
                return ElementType.badge4;

            case BadgeType.smartThermostat:
                return ElementType.badge5;

            case BadgeType.surpriseGift:
                return ElementType.badge6;
        }
    }

    private onButtonClick(tag: string): void {
        this.closeScreen({
            button: tag
        })
    }
}
