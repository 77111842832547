import {Scene} from 'phaser';
import {Asset, MultiAsset} from '../../assets';
import {GuiButton} from '../../gui/gui-button';
import {GlobalEvents} from '../../helpers/global-events';
import {MapTileSets} from '../../map/map-loader';

export type SceneHud = (scene: Scene) => Phaser.GameObjects.Container;

export type ScreenButton = (scene: Scene, events: GlobalEvents) => GuiButton | ScreenButtonType;

export enum ScreenButtonType {
    Exit = 1,
    Sound,
    Badges,
    FullScreen
}

export interface SceneData {
    /**
     * The scene name used to navigate between scenes.
     */
    sceneName: string;
    /**
     * Very important information, that the scene shouldn't be stopped but paused instead, so we
     * can go back to it preserving the state.
     */
    dontStopScene: boolean;
    /**
     * A tile map to load as main scene.
     */
    sceneTileMap: Asset;
    /**
     * Which music should be used for scene start.
     */
    backgroundMusicKey: string;
    /**
     * A multiasset instance from {@see ASEETS} set.
     */
    sprites: MultiAsset[];
    /**
     * A set of images to load.
     */
    images: Asset[];
    /**
     * A set pf sounds to load.
     */
    sounds: Asset[];
    /**
     * Defines a tile sets required for the map loaded in {@see sceneTileMap}.
     */
    tileSets: MapTileSets;
    /**
     * A set of HUDS displayed in scene from the left upper corner.
     * HUDS are rendered from left to right, first one on left side is the first
     * one on this list.
     */
    huds: SceneHud[];
    /**
     * A set of buttons displayed in scene in the right upper corner.
     * Buttons are rendered from right to left, the one on right side is the last
     * one on this list.
     */
    screenButtons?: ScreenButton[];
    /**
     * Determines if the scene represents boss level.
     */
    isBossLevel: boolean;
}
