import {ButtonSize, ButtonType, GuiButton} from '../gui-button';
import {ElementType, GuiElement} from '../gui-element';
import {GuiText, TextColor, TextType} from '../gui-text';
import {tweenHide, tweenShow} from '../gui-tweens';
import {GuiWindow, WindowType} from '../gui-window';
import {Screen, ScreenParams} from './screen';
import Container = Phaser.GameObjects.Container;
import BaseSound = Phaser.Sound.BaseSound;

export class PlayerFinalDeathScreen extends Screen {
    private container: Container;
    private windowHeight: number;
    private failSound: BaseSound;
    public static readonly key = 'gui/screen/player-final-death';

    constructor() {
        super(PlayerFinalDeathScreen.key);
    }

    protected onShow(data: ScreenParams): Promise<void> {
        this.createElements();
        this.failSound = this.sound.add('music/fail', {loop: false});
        return tweenShow(this, this.container, {
            y: this.windowHeight / 2
        }).then(() => {
            this.failSound.play();
        });
    }
    protected onHide(): Promise<void> {
        return tweenHide(this, this.container, {
            y: -this.windowHeight / 2
        }).then(() => {
            this.failSound.stop();
        });
    }

    private createElements(): void {
        const wnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.big
        });
        this.windowHeight = wnd.height;

        const title = new GuiText({
            scene: this,
            x: -150,
            y: -210,
            width: 300,
            text: 'Ouch...',
            textType: TextType.ravie,
            fontSize: 30,
            color: TextColor.yellow
        });
        const msgTitle = new GuiText({
            scene: this,
            x: -300,
            y: -90,
            width: 600,
            text: 'Holy moly, you died...',
            textType: TextType.ravie,
            fontSize: 28,
            color: TextColor.brown
        });

        const msg = new GuiText({
            scene: this,
            x: -300,
            y: 180,
            width: 600,
            text: `You need to begin from start.\nDon’t give up, defend all the SMOTHERS and\nfind all BADGES !`,
            textType: TextType.interSemiBold,
            fontSize: 18,
            color: TextColor.brown
        });

        const deathPlayer = new GuiElement({
            scene: this,
            x: 0,
            y: 70,
            elementType: ElementType.playerDeath
        })

        const okBtn = new GuiButton({
            scene: this,
            x: 0,
            y: 320,
            tag: 'ok',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.ok,
            onClick: this.onButtonClick.bind(this)
        });

        this.container = this.add.container(this.game.canvas.width / 2, -this.windowHeight / 2);
        this.container.add(wnd);
        this.container.add(title);
        this.container.add(msgTitle);
        this.container.add(msg);
        this.container.add(deathPlayer)
        this.container.add(okBtn)
    }

    private onButtonClick(tag: string): void {
        this.closeScreen({
            button: tag
        })
    }
}
