import {ASSETS, AssetAnimSpriteInfo} from '../assets';
import {globalConfig} from '../config';
import { SpikesObstacleSize } from '../map/map-helper';
import {Obstacle} from './obstacle-base';
import TimerEvent = Phaser.Time.TimerEvent;
import TimerEventConfig = Phaser.Types.Time.TimerEventConfig;
import BaseSound = Phaser.Sound.BaseSound;
import GenerateFrameNames = Phaser.Types.Animations.GenerateFrameNames;

export class SpikesObstacleSprite extends Obstacle {
    private readonly obstacleSize: SpikesObstacleSize
    private asset: AssetAnimSpriteInfo;
    private isHidden: boolean = false;
    private readonly bounds: Phaser.Geom.Rectangle;
    private readonly timer: TimerEvent;
    private tweenInProgress: boolean = false;
    private timerConfig: TimerEventConfig = {
        loop: true,
        callback: this.changeState.bind(this)
    };
    private spikesSound: BaseSound;
    private debugText: Phaser.GameObjects.Text;
    private get showAnimName(): string {
        return `spikes-obstacle-${this.obstacleSize}/show`;
    }
    private get hideAnimName(): string {
        return `spikes-obstacle-${this.obstacleSize}/hide`;
    }
    public readonly hurtScore: number = 20;
    public body: Phaser.Physics.Arcade.StaticBody;

    constructor(scene: Phaser.Scene, x: number, y: number, public id: string, private upsideDown: boolean = false, interval?: number, startDelay?: number, size?: SpikesObstacleSize) {
        super(scene, x, y, 'spikes-obstacle-' + `${size || SpikesObstacleSize.normal}`);
        
        this.obstacleSize = size || SpikesObstacleSize.normal;
        switch (this.obstacleSize) {
            case SpikesObstacleSize.big:
                this.asset = Object.assign({}, ASSETS.obstacles.spikesBig);
                this.hurtScore = 20;
                break;
            
            case SpikesObstacleSize.normal:
                this.asset = Object.assign({}, ASSETS.obstacles.spikesNormal);
                this.hurtScore = 10;
                break;
            
            case SpikesObstacleSize.small:
                this.asset = Object.assign({}, ASSETS.obstacles.spikesSmall);
                this.hurtScore = 5;
                break;
        }
            
        
        if (upsideDown) {
            this.asset.offsetY = this.asset.frameH - (this.asset.bodyH + this.asset.offsetY);
            this.asset.originY = 1 - this.asset.originY;
        }
        
        this.timerConfig.startAt = startDelay ?? 500;
        this.timerConfig.delay = interval ?? 2000;
        this.setOrigin(this.asset.originX, this.asset.originY);
        this.setTexture(this.asset.name, this.asset.framesCount - 1);
        this.scene.add.existing(this);
        this.scene.physics.add.existing(this, true);
        this.setSize(this.asset.frameW, this.asset.frameH);
        this.setFlipY(upsideDown);


        this.body.updateFromGameObject();
        this.body
            .setSize(this.asset.bodyW, this.asset.bodyH)
            .setOffset(this.asset.offsetX, this.asset.offsetY);
        this.setImmovable();

        this.spikesSound = this.scene.sound.add('music/spikes', {loop: false, volume: 0.1});

        if (!this.scene.anims.exists(this.showAnimName)) {
            this.scene.anims.create({
                key: this.showAnimName,
                repeat: 0,
                frames: this.anims.generateFrameNames(this.asset.name, <GenerateFrameNames>{start: 0, end: this.asset.framesCount - 1}),
                frameRate: this.asset.fps,
                showOnStart: true,
                hideOnComplete: false
            });

            this.scene.anims.create({
                key: this.hideAnimName,
                repeat: 0,
                frames: this.anims.generateFrameNames(this.asset.name, <GenerateFrameNames>{start: 0, end: this.asset.framesCount - 1}).reverse(),
                frameRate: this.asset.fps,
                showOnStart: true,
                hideOnComplete: false
            });
        }
        this.bounds = this.getBounds();
        this.initDebug();
        this.timer = this.scene.time.addEvent(this.timerConfig);
    }

    public destroy(fromScene?: boolean): void {
        this.timer.destroy();
        super.destroy(fromScene);
    }

    protected preUpdate(time: number, delta: number): void {
        // FIX: we pause global timer during the tween animations
        //      of spikes, to prevent running two tweens at the same
        //      time what is very buggy and breaks physics body positioning
        if (this.tweenInProgress) {
            this.timer.paused = true;
        }
        else {
            const visibleOnScreen = Phaser.Geom.Rectangle.Overlaps(this.scene.cameras.main.worldView, this.bounds);
            this.timer.paused = !visibleOnScreen;
        }

        this.setDebugText(`id: ${this.id}\ntimer: ${this.timer.paused ? 'paused' : 'running'}\ntween: ${this.tweenInProgress ? 'in progress' : 'stopped'}`);
        super.preUpdate(time, delta);
    }

    private changeState(): void {
        const floorMargin = 35;
        if (this.isHidden) {
            const targetY = !this.upsideDown ?
                this.body.y - this.asset.bodyH + floorMargin :
                this.body.y;

            this.play(this.showAnimName);
            this.spikesSound.play();

            this.tweenInProgress = true;
            this.scene.tweens.add({
                loop: false,
                targets: this.body,
                duration: 500,
                props: {
                    y: targetY,
                    height: this.asset.bodyH
                },
                onComplete: () => {
                    this.tweenInProgress = false;
                }
            });
        }
        else {
            const targetY = !this.upsideDown ?
                this.body.y + this.asset.bodyH - floorMargin :
                this.body.y;

            this.play(this.hideAnimName);
            this.spikesSound.play();
            this.tweenInProgress = true;
            this.scene.tweens.add({
                targets: this.body,
                loop: false,
                duration: 500,
                props: {
                    y: targetY,
                    height: floorMargin
                },
                onComplete: () => {
                    this.tweenInProgress = false;
                }
            });
        }
        this.isHidden = !this.isHidden;
    }

    private initDebug(): void {
        if (globalConfig.debug) {
            this.debugText = this.scene.add
                                 .text(this.x, this.y, 'SPIKES', {
                                     align: 'center',
                                     fontStyle: 'bold',
                                     color: '#FFFFFF'
                                 })
                                 .setDepth(1)
                                 .setOrigin(0.5, 0);
        }
    }

    private setDebugText(debugText: string): void {
        if (globalConfig.debug) {
            this.debugText.setText(debugText);
            this.debugText.setPosition(this.x, this.y + 10);
        }
    }
}
