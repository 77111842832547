import Vector2 = Phaser.Math.Vector2;
import Tilemap = Phaser.Tilemaps.Tilemap;
import Tileset = Phaser.Tilemaps.Tileset;
import TiledObject = Phaser.Types.Tilemaps.TiledObject;
import {
    LAYER_BKG_1, LAYER_BKG_2, LAYER_CHECKPOINTS, LAYER_DEATH_AREAS, LAYER_FKG_1, LAYER_FKG_2, LAYER_GROUND_1,
    LAYER_GROUND_2, LAYER_GROUNDS, LAYER_LOCATIONS, LAYER_PLATFORMS
} from '../constants';
import { GameStore } from '../helpers/game-store';
import { EVENT_CHECKPOINT_ACTIVATE, GlobalEvents } from '../helpers/global-events';
import { GlobalObjects } from '../helpers/global-objects';
import { BossSprite } from '../sprites/boss-sprite';
import { CheckpointSprite } from '../sprites/checkpoint-sprite';
import { ChimneySprite } from '../sprites/chimney-sprite';
import { ItemSprite, ItemType } from '../sprites/item-sprite';
import { MovingPlatformSprite } from '../sprites/moving-platform-sprite';
import { Obstacle } from '../sprites/obstacle-base';
import { PlayerSprite } from '../sprites/player-sprite';
import { PortalSprite } from '../sprites/portal-sprite';
import { SpikesObstacleSprite } from '../sprites/spikes-obstacle-sprite';
import { DeathCollider } from './death-collider';
import { GroundCollider } from './ground-collider';
import { GameDifficultyLevel, MapHelper, ObjectType } from './map-helper';
import { StaticPlatformCollider } from './static-platform-collider';

export class MapLoader {
    private map: Tilemap;
    private tileSets: Tileset[] = [];
    private player: PlayerSprite;
    private globalEvents: GlobalEvents = GlobalEvents.resolve();
    private checkpoints: { [id: string]: CheckpointSprite } = {};
    public platform: MovingPlatformSprite;
    public startPos: Vector2;
    
    public get width(): number {
        return this.map.widthInPixels;
    }
    
    public get playerStartPos(): Vector2 {
        return this.startPos;
    }
    
    public get height(): number {
        return this.map.heightInPixels;
    }
    
    public get bounds(): Phaser.Geom.Rectangle {
        return new Phaser.Geom.Rectangle(0, 0, this.width, this.height);
    }
    
    constructor(private scene: Phaser.Scene, mapKey: string = 'map/tilemap', private mapTileSets: MapTileSets, locationTarget?: string) {
        this.player = GlobalObjects.player;
        this.loadMap(mapKey, locationTarget);
    }
    
    public addPhysics(): void {
        this.initGroundColliders();
        this.initDeathColliders();
        this.initStaticPlatformColliders();
        this.initMovingPlatforms();
        this.initObstacles();
        this.initItems();
        this.initCheckpoints();
    }
    
    public getCheckpoint(checkpointId: string): CheckpointSprite | undefined {
        if (checkpointId) {
            return this.checkpoints[checkpointId];
        }
        return undefined;
    }
    
    private loadMap(mapKey: string, locationTarget?: string): void {
        this.map = this.scene.make.tilemap({
            key: mapKey
        });
        
        
        if (this.mapTileSets.foregrounds) {
            this.tileSets.push(this.map.addTilesetImage(
                this.mapTileSets.foregrounds.tilesetName,
                this.mapTileSets.foregrounds.key
            ));
        }
        
        if (!this.mapTileSets.grounds) {
            throw new Error('Map tile sets grounds are required for map loader.');
        }
        
        this.tileSets.push(this.map.addTilesetImage(
            this.mapTileSets.grounds.tilesetName,
            this.mapTileSets.grounds.key
        ));
        
        
        // graphics layers
        this.generateBackgroundImages();
        this.map.createLayer(LAYER_GROUND_1, this.tileSets);
        this.map.createLayer(LAYER_GROUND_2, this.tileSets);
        this.generateForegroundImages();
        
        // player location
        this.setPlayerStartLocation(locationTarget);
        this.initBoss(); // if exists
        this.initPortals();
    }
    
    private setPlayerStartLocation(locationTarget?: string): void {
        const layer = this.map.getObjectLayer(LAYER_LOCATIONS);
        let playerStart: TiledObject;
        if (layer && layer.objects) {
            if (locationTarget) {
                playerStart = layer.objects
                                   .find(o => {
                                       const props = MapHelper.getProperties(o);
                                       return props.type === ObjectType.locationTarget && props.locationName === locationTarget;
                                   });
            }
            
            if (!playerStart) {
                playerStart = layer.objects
                                   .find(o => MapHelper.getProperties(o).type === ObjectType.playerStart);
            }
            
            if (!playerStart) {
                throw new Error('The player-start or player-location-target object is missing on the map.');
            }
            this.startPos = new Vector2(playerStart.x, playerStart.y);
            this.player.setPosition(playerStart.x, playerStart.y);
        } else {
            throw new Error(`The map layer "${LAYER_LOCATIONS}" is missing.`);
        }
    }
    
    private initDeathColliders(): void {
        const layer = this.map.getObjectLayer(LAYER_DEATH_AREAS);
        if (layer && layer.objects) {
            const deathObjects = layer.objects
                                      .filter((c) => {
                                          const properties = MapHelper.getProperties(c);
                                          return this.matchDifficultyLevel(properties.difficultyLevels);
                                      })
                                      .map((c) => {
                                          const posX = c.x + c.width / 2;
                                          const posY = c.y + c.height / 2;
                                          const result = new DeathCollider(this.scene, posX, posY, c.width, c.height);
                                          return result;
                                      });
            this.scene.physics.add.collider(
                this.player,
                deathObjects,
                (player: PlayerSprite) => {
                    if (!player.playerIsDead) {
                        player.die();
                    }
                }
            );
        }
    }
    
    private initGroundColliders(): void {
        const layer = this.map.getObjectLayer(LAYER_GROUNDS);
        if (layer && layer.objects) {
            const grounds = layer.objects
                                 .filter((c) => {
                                     const properties = MapHelper.getProperties(c);
                                     return this.matchDifficultyLevel(properties.difficultyLevels);
                                 })
                                 .map((c) => {
                                     const posX = c.x + c.width / 2;
                                     const posY = c.y + c.height / 2;
                                     const result = new GroundCollider(this.scene, posX, posY, c.width, c.height);
                                     return result;
                                 });
            GlobalObjects.setGrounds(grounds);
            this.scene.physics.add.collider(this.player, grounds);
        }
    }
    
    private initStaticPlatformColliders(): void {
        const layer = this.map.getObjectLayer(LAYER_PLATFORMS);
        if (layer && layer.objects) {
            const staticPlatforms = layer.objects
                                         .filter((c) => {
                                             const properties = MapHelper.getProperties(c);
                                             return this.matchDifficultyLevel(properties.difficultyLevels) && properties.type === ObjectType.staticPlatform;
                                         })
                                         .map((c) => {
                                             return new StaticPlatformCollider(this.scene, c.x, c.y, c.width, c.height);
                                         });
            GlobalObjects.setStaticPlatforms(staticPlatforms);
            const collisionStaticPlatform = (player: PlayerSprite, platform: StaticPlatformCollider) => {
                if (platform.body.touching.up && player.body.touching.down) {
                    player.setOnStaticPlatform(platform);
                }
            };
            this.scene.physics.add.collider(this.player, staticPlatforms, collisionStaticPlatform, this.verifyPlayerPlatformCollision);
        }
    }
    
    private initMovingPlatforms(): void {
        const layer = this.map.getObjectLayer(LAYER_PLATFORMS);
        if (layer && layer.objects) {
            const platformCollisions = layer.objects
                                            .filter((tile) => {
                                                const properties = MapHelper.getProperties(tile);
                                                return this.matchDifficultyLevel(properties.difficultyLevels) && properties.type === ObjectType.movingPlatform && (tile.polygon || tile.polyline);
                                            })
                                            .map((tile) => {
                                                const posX = tile.x + tile.width / 2;
                                                const posY = tile.y + tile.height / 2;
                                                const props = MapHelper.getProperties(tile);
                                                return new MovingPlatformSprite(
                                                    this.scene, posX, posY, tile.width, tile.height,
                                                    props.platformSize, props.platformStartPosition,
                                                    (tile.polyline || tile.polygon || [{x: 0, y: 0}]) as Vector2[]
                                                );
                                            });
            
            GlobalObjects.setMovingPlatforms(platformCollisions);
            
            const collisionMovingPlatform = (player: PlayerSprite, platform: MovingPlatformSprite) => {
                if (platform.body.touching.up && player.body.touching.down) {
                    player.setOnMovingPlatform(platform);
                }
            };
            
            this.scene.physics.add.collider(this.player, platformCollisions, collisionMovingPlatform, this.verifyPlayerPlatformCollision);
        }
    }
    
    private initObstacles(): void {
        const layer = this.map.getObjectLayer(LAYER_LOCATIONS);
        if (layer && layer.objects) {
            const obstacles: Obstacle[] = layer.objects
                                               .filter((tile) => {
                                                   const properties = MapHelper.getProperties(tile);
                                                   return this.matchDifficultyLevel(properties.difficultyLevels) && [ObjectType.spikesObstacle, ObjectType.chimneyEnemy].includes(properties.type);
                                               })
                                               .map((tile) => {
                                                   const id = tile.id.toString();
                                                   if (!GameStore.hasObject(id)) {
                                                       const props = MapHelper.getProperties(tile);
                                                       if (props.type === ObjectType.spikesObstacle) {
                                                           return new SpikesObstacleSprite(
                                                               this.scene, tile.x, tile.y, id, tile.rotation === 180 || tile.rotation === -180,
                                                               props.spikesObstacleInterval, props.spikesObstacleStartDelay, props.spikesObstacleSize
                                                           );
                                                       }
                                                       if (props.type === ObjectType.chimneyEnemy) {
                                                           const chimney = new ChimneySprite(this.scene, tile.x, tile.y);
                                                           chimney.objectId = tile.id.toString();
                                                           GlobalObjects.enemies.add(chimney);
                                                           return chimney;
                                                       }
                                                   }
                                               });
            
            const overlapWithPlayer = (player: PlayerSprite, obstacle: Obstacle) => {
                player.hurt(obstacle.hurtScore);
            };
            this.scene.physics.add.overlap(this.player, obstacles, overlapWithPlayer);
        }
    }
    
    private initCheckpoints(): void {
        const layer = this.map.getObjectLayer(LAYER_CHECKPOINTS);
        if (layer && layer.objects) {
            const checkpoints: CheckpointSprite[] = layer.objects
                                                         .filter((tile) => {
                                                             const properties = MapHelper.getProperties(tile);
                                                             return this.matchDifficultyLevel(properties.difficultyLevels) && properties.type === ObjectType.checkpoint;
                                                         })
                                                         .map((tile) => {
                                                             const checkpoint = new CheckpointSprite(this.scene, tile.x, tile.y, tile.id.toString());
                                                             this.checkpoints[checkpoint.id] = checkpoint;
                                                             return checkpoint;
                                                         });
            
            const overlapWithPlayer = (player: PlayerSprite, checkpoint: CheckpointSprite) => {
                this.globalEvents.emit(EVENT_CHECKPOINT_ACTIVATE, checkpoint.id);
            };
            this.scene.physics.add.overlap(this.player, checkpoints, overlapWithPlayer);
        }
    }
    
    private initItems(): void {
        const layer = this.map.getObjectLayer(LAYER_LOCATIONS);
        if (layer && layer.objects) {
            const items = layer.objects
                               .filter(tile => {
                                   const properties = MapHelper.getProperties(tile);
                                   return this.matchDifficultyLevel(properties.difficultyLevels) && [ObjectType.badge, ObjectType.life, ObjectType.power].includes(properties.type);
                               })
                               .map(tile => {
                                   if (!GameStore.hasObject(tile.id.toString())) {
                                       const props = MapHelper.getProperties(tile);
                                       if (props.type === ObjectType.badge) {
                                           const item = new ItemSprite(this.scene, tile.x, tile.y, ItemType.Badge, props.badge);
                                           item.objectId = tile.id.toString();
                                           return item;
                                       }
                                       if (props.type === ObjectType.life) {
                                           const item = new ItemSprite(this.scene, tile.x, tile.y, ItemType.Life);
                                           item.objectId = tile.id.toString();
                                           return item;
                                       }
                                       if (props.type === ObjectType.power) {
                                           const item = new ItemSprite(this.scene, tile.x, tile.y, ItemType.Power);
                                           item.objectId = tile.id.toString();
                                           return item;
                                       }
                                   }
                               });
            
            const overlapWithPlayer = (player: PlayerSprite, item: ItemSprite) => {
                player.collectItem(item);
                item.itemFound();
            };
            this.scene.physics.add.overlap(this.player, items, overlapWithPlayer);
        }
    }
    
    
    private generateBackgroundImages(): void {
        const layerBkg1 = this.map.getObjectLayer(LAYER_BKG_1);
        const layerBkg2 = this.map.getObjectLayer(LAYER_BKG_2);
        
        if (!layerBkg1) {
            throw new Error(`The map layer "${LAYER_BKG_1}" is missing.`);
        }
        
        if (!layerBkg2) {
            throw new Error(`The map layer "${LAYER_BKG_2}" is missing.`);
        }
        
        const bkgObjects1 = layerBkg1.objects;
        const bkgObjects2 = layerBkg2.objects;
        const imageNames: { [gid: number]: string } = {};
        this.map.tilesets.forEach(t => {
            imageNames[t.firstgid] = 'map/images/' + this.getFileNameFromPath(t.name);
        });
        
        // layer 1
        bkgObjects1.forEach((tile) => {
            const properties = MapHelper.getProperties(tile);
            if (this.matchDifficultyLevel(properties.difficultyLevels)) {
                this.addStaticImage(tile, imageNames[tile.gid]);
            }
        });
        
        // layer 2
        bkgObjects2.forEach((tile) => {
            const properties = MapHelper.getProperties(tile);
            if (this.matchDifficultyLevel(properties.difficultyLevels)) {
                this.addStaticImage(tile, imageNames[tile.gid]);
            }
        });
    }
    
    private generateForegroundImages(): void {
        const layerFg1 = this.map.getObjectLayer(LAYER_FKG_1);
        const layerFg2 = this.map.getObjectLayer(LAYER_FKG_2);
        
        if (!layerFg1) {
            throw new Error(`The map layer "${LAYER_FKG_1}" is missing.`);
        }
        
        if (!layerFg2) {
            throw new Error(`The map layer "${LAYER_FKG_2}" is missing.`);
        }
        
        const bkgObjects1 = layerFg1.objects;
        const bkgObjects2 = layerFg2.objects;
        const imageNames: { [gid: number]: string } = {};
        this.map.tilesets.forEach(t => {
            imageNames[t.firstgid] = 'map/images/' + this.getFileNameFromPath(t.name);
        });
        
        // layer 1
        bkgObjects1.forEach((tile) => {
            const properties = MapHelper.getProperties(tile);
            if (this.matchDifficultyLevel(properties.difficultyLevels)) {
                this.addStaticImage(tile, imageNames[tile.gid]);
            }
        });
        
        // layer 2
        bkgObjects2.forEach((tile) => {
            const properties = MapHelper.getProperties(tile);
            if (this.matchDifficultyLevel(properties.difficultyLevels)) {
                this.addStaticImage(tile, imageNames[tile.gid]);
            }
        });
    }
    
    private initBoss(): void {
        const layer = this.map.getObjectLayer(LAYER_LOCATIONS);
        if (layer && layer.objects) {
            layer.objects
                 .filter(tile => {
                     const properties = MapHelper.getProperties(tile);
                     return this.matchDifficultyLevel(properties.difficultyLevels) && properties.type === ObjectType.boss;
                 })
                 .map(tile => {
                     const boss = new BossSprite(this.scene, tile.x, tile.y);
                     GlobalObjects.setBoss(boss);
                     return boss;
                 });
        }
    }
    
    private initPortals(): void {
        const layer = this.map.getObjectLayer(LAYER_LOCATIONS);
        if (layer && layer.objects) {
            layer.objects
                 .filter(tile => {
                     const properties = MapHelper.getProperties(tile);
                     return this.matchDifficultyLevel(properties.difficultyLevels) && properties.type === ObjectType.portal;
                 })
                 .map(tile => {
                     const properties = MapHelper.getProperties(tile);
                     const portal = new PortalSprite(this.scene, tile.x, tile.y, tile.width, tile.height, properties.portalTarget, properties.locationName);
                     portal.objectId = tile.id.toString();
                     return portal;
                 });
        }
    }
    
    private addStaticImage(tile: TiledObject, name: string): void {
        const tex = this.scene.textures.get(name);
        const img = tex.getSourceImage();
        let posX = tile.x + img.width / 2;
        let posY = tile.y - img.height / 2;
        if (tile.rotation === -90) {
            posX -= img.width;
        }
        if (tile.rotation === 90) {
            posY += img.height;
        }
        if (tile.rotation === 180) {
            posX -= img.width;
            posY += img.height;
        }
        this.scene.add.image(posX, posY, tex)
            .setFlipX(tile.flippedHorizontal)
            .setFlipY(tile.flippedVertical)
            .setRotation(tile.rotation * Math.PI / 180.0);
    }
    
    private getFileNameFromPath(path: string): string {
        return path.split(/[\\/]/).pop().replace(/\.[^/.]+$/, '');
    }
    
    private verifyPlayerPlatformCollision(player: PlayerSprite, platform: MovingPlatformSprite): boolean {
        const playerAbovePlatform = platform.body.y > player.body.y;
        return playerAbovePlatform && !player.isJumping;
    }
    
    private matchDifficultyLevel(levels: GameDifficultyLevel[]): boolean {
        return levels.includes(GlobalObjects.difficultyLevel);
    }
}

export interface MapTileSets {
    grounds: {
        key: string;
        tilesetName: string;
        firstGid?: number;
    };
    foregrounds?: {
        key: string;
        tilesetName: string;
        firstGid?: number;
    };
}
