import TileSprite = Phaser.GameObjects.TileSprite;

export class GuiSkyBackground {
    private bg: TileSprite[] = [];
    private textures = [
        {name: 'background/light/1', width: 0, height: 0, scrollFactor: 0.01, depth: -1000, offsetY: 0, originY: 0}, // background
        {name: 'background/light/2', width: 0, height: 0, scrollFactor: 0.05, depth: -900, offsetY: 0, originY: 0}, // flying clouds
        {name: 'background/light/3', width: 0, height: 0, scrollFactor: 0.06, depth: -800, offsetY: 2, originY: 1}, // fog 1
        {name: 'background/light/4', width: 0, height: 0, scrollFactor: 0.10, depth: -700, offsetY: 2, originY: 1}, // fog 2
        {name: 'background/light/5', width: 0, height: 0, scrollFactor: 0.25, depth: -600, offsetY: 2, originY: 1}, // mountains
        {name: 'background/light/6', width: 0, height: 0, scrollFactor: 0.40, depth: -500, offsetY: 2, originY: 1}, // trees and hills
        {name: 'background/light/7', width: 0, height: 0, scrollFactor: 0.60, depth: -400, offsetY: 2, originY: 1}  // front bushes
    ];
    private speed: number = 1;
    private scrollX: number = 0;

    constructor(private scene: Phaser.Scene) {
        this.createBackgroundSprites();
    }

    public update(): void {
        this.scrollX += this.speed;

        for (let i = 0; i < this.textures.length; i++) {
            this.bg[i].tilePositionX = this.scrollX * this.textures[i].scrollFactor;

            // update width on game resize
            this.bg[i].setSize(this.scene.game.canvas.width, this.bg[i].height);
        }
    }

    private createBackgroundSprites(): void {
        for (let i = 0; i < this.textures.length; i++) {
            const yPos = this.textures[i].originY * this.scene.game.canvas.height + this.textures[i].offsetY;
            this.bg[i] = this.scene.add
                             .tileSprite(0, yPos, this.scene.game.canvas.width, this.textures[i].height, this.textures[i].name)
                             .setOrigin(0, this.textures[i].originY)
                             .setScrollFactor(0, 0)
                             .setDepth(this.textures[i].depth);
        }
    }
}
