import {ASSETS} from '../assets';
import Image = Phaser.GameObjects.Image;

export class GuiWindow extends Image {
    constructor(private config: WindowConfig) {

        super(config.scene, config.x, config.y, GuiWindow.getAsset(config.windowType))
        this.setOrigin(0.5, 0.5);
    }

    private static getAsset(type: WindowType): string {
        switch (type) {
            case WindowType.big: return ASSETS.gui.windowBig.name;
            case WindowType.wide: return ASSETS.gui.windowWide.name;
            case WindowType.ultraWide: return ASSETS.gui.windowUltraWide.name;
            case WindowType.small: return ASSETS.gui.windowSmall.name;
            case WindowType.rect: return ASSETS.gui.windowRect.name;
            case WindowType.itemCollected: return ASSETS.gui.windowItemCollected.name;
            case WindowType.menu: return ASSETS.gui.windowMenu.name;
            case WindowType.contentGroup: return ASSETS.gui.windowContentGroup.name;
        }
        return ASSETS.gui.windowBig.name;
    }
}

export enum WindowType {
    big = 1,
    rect,
    itemCollected,
    small,
    menu,
    contentGroup,
    wide,
    ultraWide
}

export interface WindowConfig {
    scene: Phaser.Scene;
    x: number;
    y: number;
    windowType: WindowType;
}
