import {VirtualGamePad} from './virtual-game-pad';

export class GameInputs {
    private capsLockIsDown: boolean;
    private virtualPad: VirtualGamePad;
    private isAlwaysSpeed: boolean = true;
    private static instance: GameInputs;
    public scene: Phaser.Scene;
    public keys: any;
    /**
     * Resolve global game inputs service instance.
     */
    public static resolve(): GameInputs {
        if (!GameInputs.instance) {
            GameInputs.instance = new GameInputs();
        }
        return GameInputs.instance;
    }

    private constructor() {
    }

    public bootstrap(scene: Phaser.Scene): void {
        this.scene = scene;
        this.keys = scene.input.keyboard.addKeys(
            'W,A,S,D,Z,X,K,C,up,left,down,right,space,shift'
        );
    }

    public assignVirtualPad(vPad: VirtualGamePad): void {
        this.virtualPad = vPad;
    }

    public get up(): boolean {
        return this.keys.up.isDown || this.keys.W.isDown || this.padAxisV === -1 || this.vPadUp;
    }

    public get down(): boolean {
        return this.keys.down.isDown || this.keys.S.isDown || this.padAxisV === 1 || this.vPadDown;
    }

    public get left(): boolean {
        return this.keys.left.isDown || this.keys.A.isDown || this.padAxisH === -1 || this.vPadLeft;
    }

    public get right(): boolean {
        return this.keys.right.isDown || this.keys.D.isDown || this.padAxisH === 1 || this.vPadRight;
    }

    public get holdShift(): boolean {
        return this.keys.shift.isDown || this.capsLockIsDown || this.vPadTurboOn || this.isAlwaysSpeed;
    }

    public get attack1(): boolean {
        return false; // this.keys.Z.isDown;
    }

    public get attack2(): boolean {
        return this.keys.X.isDown || this.keys.K.isDown || this.vPadFire;
    }

    public get jump(): boolean {
        return (
            this.up ||
            this.vPadJump ||
            this.keys.space.isDown ||
            this.padA ||
            this.padB
        );
    }

    public get jumpDown(): boolean {
        return (
            this.down && this.jump
        );
    }

    private get padA(): boolean {
        return this.padButtons.some(
            (button: Phaser.Input.Gamepad.Button) =>
                button.index % 2 === 0 && button.value === 1
        );
    }

    private get padB(): boolean {
        return this.padButtons.some(
            (button: Phaser.Input.Gamepad.Button) =>
                button.index % 2 === 1 && button.value === 1
        );
    }

    private get padAxisH(): number {
        if (this.pad) {
            return this.pad.axes[0].getValue();
        }

        return 0;
    }

    private get padAxisV(): number {
        if (this.pad) {
            return this.pad.axes[1].getValue();
        }

        return 0;
    }

    private get padButtons(): Phaser.Input.Gamepad.Button[] {
        if (this.pad) {
            return this.pad.buttons;
        }

        return [];
    }

    private get pad(): Phaser.Input.Gamepad.Gamepad {
        const pad = this.scene.input.gamepad;

        if (pad && pad.gamepads && pad.gamepads.length) {
            return pad.gamepads[0];
        }

        return null;
    }

    private get vPadUp(): boolean {
        return this.virtualPad ? this.virtualPad.up : false;
    }

    private get vPadDown(): boolean {
        return this.virtualPad ? this.virtualPad.down : false;
    }

    private get vPadRight(): boolean {
        return this.virtualPad ? this.virtualPad.right : false;
    }

    private get vPadLeft(): boolean {
        return this.virtualPad ? this.virtualPad.left : false;
    }

    private get vPadTurboOn(): boolean {
        return this.virtualPad ? this.virtualPad.turboOn : false;
    }

    private get vPadJump(): boolean {
        return this.virtualPad ? this.virtualPad.jump : false;
    }

    private get vPadFire(): boolean {
        return this.virtualPad ? this.virtualPad.fire : false;
    }
}
