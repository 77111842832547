import {Screen, ScreenParams} from './screen';

/**
 * A base class for all GUI screens.
 */
export class OverlayScreen extends Screen {
    public static readonly key = 'gui/screen/overlay';
    private overlay: Phaser.GameObjects.Rectangle;

    constructor() {
        super(OverlayScreen.key);
    }

    protected onShow(data: ScreenParams): void {
        super.onShow(data);

        this.overlay = this.add
                           .rectangle(
                               0, 0,
                               this.game.canvas.width,
                               this.game.canvas.height,
                               0x000000
                           )
                           .setAlpha(0)
                           .setScrollFactor(0)
                           .setOrigin(0, 0);

        this.tweens.add({
            targets: this.overlay,
            duration: 200,
            alpha: 0.7
        });
    }

    protected onHide(): Promise<void> {
        return new Promise((resolve) => {
            this.tweens.add({
                targets: this.overlay,
                duration: 200,
                alpha: 0,
                onComplete: () => resolve()
            })
        });
    }
}
