import {GlobalObjects} from '../helpers/global-objects';
import {MainGameScene} from '../scenes/main-game-scene-interface';
import {SCENES} from '../scenes/scenes';
import {OverlayScreen} from './screens/overlay-screen';
import {Screen, ScreenParams, ScreenResult} from './screens/screen';

export class ScreensService {
    private static instance: ScreensService;
    private currentScene: Phaser.Scene & MainGameScene;
    private overlay: Screen;
    private currentScreen: Screen;
    private isGuiActive: boolean = false;
    private isMainScenePaused: boolean = false;

    /**
     * Resolve global events service instance.
     */
    public static resolve(): ScreensService {
        if (!ScreensService.instance) {
            ScreensService.instance = new ScreensService();
        }
        return ScreensService.instance;
    }

    private constructor() {
    }

    public destroy(): Promise<void> {
        try {
            return this.hide()
                .then(() => {
                    ScreensService.instance = undefined;
                });

        } catch {

        } finally {
            ScreensService.instance = undefined;
        }
        return Promise.resolve();
    }

    public setGameScene(scene: Phaser.Scene & MainGameScene): void {
        this.currentScene = scene;
    }

    public restartMainScene(): void {
        this.currentScene.resetPlayerLocation();
    }

    public startNewMainScene(key: string): void {
        this.currentScene.sound.stopAll();
        this.currentScene.scene.stop();

        setTimeout(() => {
            this.currentScene.unload(SCENES[GlobalObjects.currentSceneName]);
            this.currentScene.scene.start(key);
        }, 1000);

    }

    public soundMute(mute: boolean): void {
        (<Phaser.Sound.HTML5AudioSoundManager>this.currentScene.sound).setMute(mute);
    }

    public fullRestartMainScene(): void {
        this.currentScene.sound.stopAll();
        this.currentScene.scene.stop();
        this.currentScene.scene.start();
    }

    /**
     * Hides all gui related screens and overlay.
     */
    public hide(): Promise<void> {
        if (this.currentScreen) {
            this.currentScene.scene.stop(this.currentScreen.name);
        }
        return this.deactivateGui();
    }
    /**
     * Pauses main gameplay scene and shows GUI screen.
     * @param params Screen showing parameters.
     * @param screenName Name of the screen / scene.
     * @param pauseMainScene Pause or not main scene, default true
     * @private
     */
    public async show(params: ScreenParams, screenName: string, pauseMainScene: boolean = true): Promise<ScreenResult> {
        if (this.currentScreen) {
            return Promise.reject(new Error('Close all screens before showing new one.'));
        }
        const newParams = Object.assign({}, params);
        return new Promise((resolve) => {
            newParams.onShow = (screen: Screen) => {
                if (params.onShow) {
                    params.onShow(screen);
                }
                this.currentScreen = screen;
            };
            newParams.onClose = (result: ScreenResult) => {
                if (params.onClose) {
                    params.onClose(result);
                }
                this.currentScreen = undefined;
                resolve(result);
            };
            this.activateGui(newParams, pauseMainScene);
            this.currentScene.scene.launch(screenName, newParams);
        });
    }

    private showOverlay(): Promise<void> {
        return new Promise((resolve) => {
            this.currentScene.scene.launch(OverlayScreen.key, <ScreenParams>{
                onShow: (screen) => {
                    this.overlay = screen;
                    resolve();
                },
                onClose: () => {
                    this.overlay = undefined;
                }
            });
        });
    }

    private hideOverlay(): Promise<void> {
        if (this.overlay) {
            return this.overlay.closeScreen({});
        }
        return Promise.resolve();
    }

    private activateGui(params: ScreenParams, pauseMainScene: boolean): Promise<void> {
        if (!this.isGuiActive) {
            this.isGuiActive = true;
            if (pauseMainScene) {
                this.currentScene.scene.pause();
                this.isMainScenePaused = true;
                return this.showOverlay();
            } else {
                return Promise.resolve();
            }
        }
    }

    private async deactivateGui(): Promise<void> {
        if (this.isGuiActive) {
            this.isGuiActive = false;
            await this.hideOverlay();
            if (this.isMainScenePaused) {
                this.currentScene.scene.resume();
            }
        }
    }
}
