import { BadgeType } from '../map/map-helper';

export class BadgeService {
    private static instance: BadgeService;
    private unlockedBadges: BadgeType[] = [];

    /**
     * Resolve global events service instance.
     */
    public static resolve(): BadgeService {
        if (!BadgeService.instance) {
            BadgeService.instance = new BadgeService();
        }
        return BadgeService.instance;
    }

    public addBadgeToUnlocked(badge: BadgeType): BadgeType[] {
        this.unlockedBadges.push(badge);
        return this.unlockedBadges;
    }

    public isBadgeUnlocked(badge: BadgeType): boolean {
        return this.unlockedBadges.includes(badge);
    }

    public hasAll(): boolean {
        return this.unlockedBadges.length === Object.values(BadgeType).length;
    }

    public removeAll(): void {
        this.unlockedBadges = [];
    }
}
