import {globalConfig} from '../config';
import {GoogleTagManager} from './google-tag-manager';

export class ErrorHandler {
    private gtm = GoogleTagManager.resolve();
    private static instance: ErrorHandler;

    /**
     * Resolve global game inputs service instance.
     */
    public static resolve(): ErrorHandler {
        if (!ErrorHandler.instance) {
            ErrorHandler.instance = new ErrorHandler();
        }
        return ErrorHandler.instance;
    }

    private constructor() {
        if (!globalConfig.debug) {
            window.addEventListener('error', (event: ErrorEvent) => {
                event.stopPropagation();
                event.preventDefault();

                this.gtm.error(`file: ${event.filename}
pos: ${event.colno}:${event.lineno}
msg: ${event.message}
type: ${event.type}
error: ${event.error}
time: ${event.timeStamp}
date: ${new Date().toISOString()}
stack: ${event.error?.stack}
`);

                alert(`:(\nAn unexpected problem occurred.\nI'm really sorry for that.\nI'm still working on the game.\n\nThe game will be restarted now.`);
                location.reload();
            });
        }
    }
}
