export class SceneLoader {
    private progressBox: Phaser.GameObjects.Graphics;
    private progressBar: Phaser.GameObjects.Graphics;
    private loadingText: Phaser.GameObjects.Text;
    private isDestroyed = false;

    constructor(private scene: Phaser.Scene, message: string = 'Loading...') {
        // simple progress bar
        const width = this.scene.cameras.main.width;
        const height = this.scene.cameras.main.height;
        const progressW = 450;
        const progressH = 40;
        const paddingW = 10;
        const paddingH = 10;
        const x = (width - progressW) / 2;
        const y = (height - progressH) / 2;
        this.progressBox = this.scene.add.graphics();
        this.progressBar = this.scene.add.graphics();
        this.progressBox.fillStyle(0x222222, 0.8);
        this.progressBox.fillRect(x, y, progressW, progressH);
        this.loadingText = this.scene.make.text({
            x: width / 2,
            y: y - 10,
            text: message,
            style: {
                font: '20px monospace',
                align: 'center',
                color: '#ffffff'
            }
        });
        this.loadingText.setOrigin(0.5, 1);

        // listeners
        this.scene.load.on('progress', (value: any) => {
            this.progressBar.clear();
            this.progressBar.fillStyle(0x00DD00, 1);
            this.progressBar.fillRect(x + paddingW, y + paddingH, (progressW - 2 * paddingW) * value, progressH - 2 * paddingH);
        });
        this.scene.load.on('complete', () => {
            this.destroy();
        });
    }

    public destroy(): void {
        if (!this.isDestroyed) {
            this.progressBar.destroy();
            this.progressBox.destroy();
            this.loadingText.destroy();
            this.scene.load.removeAllListeners('progress');
            this.scene.load.removeAllListeners('complete');
            this.isDestroyed = true;
        }
    }
}
