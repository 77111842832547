import {GlobalObjects} from './global-objects';
import {TimeHelper} from './time-helper';

declare const dataLayer: any;

export class GoogleTagManager {

    private static instance: GoogleTagManager;

    /**
     * Resolve global events service instance.
     */
    public static resolve(): GoogleTagManager {
        if (!GoogleTagManager.instance) {
            GoogleTagManager.instance = new GoogleTagManager();
        }
        return GoogleTagManager.instance;
    }

    private constructor() {
    }

    public sceneEnter(sceneName: string): void {
        try {
            dataLayer.push({'event': 'scene-enter', sceneName, buttonName: null, gameDuration: TimeHelper.getGameDurationTime(), gameDurationString: TimeHelper.getGameDurationTimeString()});
        }
        catch {
        }
    }

    public gameOver(): void {
        try {
            dataLayer.push({'event': 'game-over', buttonName: null, gameDuration: TimeHelper.getGameDurationTime(), gameDurationString: TimeHelper.getGameDurationTimeString()});
        }
        catch {
        }
    }

    public gameCompleted(): void {
        try {
            dataLayer.push({'event': 'game-completed', buttonName: null, gameDuration: TimeHelper.getGameDurationTime(), gameDurationString: TimeHelper.getGameDurationTimeString()});
        }
        catch {
        }
    }

    public livesChanged(leftLives: number): void {
        try {
            dataLayer.push({'event': 'lives-changed', leftLives, buttonName: null, gameDuration: TimeHelper.getGameDurationTime(), gameDurationString: TimeHelper.getGameDurationTimeString()});
        }
        catch {
        }
    }

    public buttonClicked(buttonName: string): void {
        try {
            dataLayer.push({'event': 'button-clicked', buttonName, gameDuration: TimeHelper.getGameDurationTime(), gameDurationString: TimeHelper.getGameDurationTimeString()});
        }
        catch {
        }
    }

    public error(message: string): void {
        try {
            dataLayer.push({'event': 'error', lastError: message, gameDuration: TimeHelper.getGameDurationTime(), gameDurationString: TimeHelper.getGameDurationTimeString()});
        }
        catch {
        }
    }


}
