import Container = Phaser.GameObjects.Container;
import {ASSETS} from '../../assets';
import {
    EVENT_BOSS_KILLED,
    EVENT_BOSS_POWER_UPDATE,
    GlobalEvents
} from '../../helpers/global-events';
import {GlobalObjects} from '../../helpers/global-objects';
import Image = Phaser.GameObjects.Image;

export class BossPowerHud extends Container {
    private powerBar: Image;
    private globalEvents: GlobalEvents = GlobalEvents.resolve();

    constructor(scene: Phaser.Scene, x: number, y: number, private maxPower: number = 100) {
        super(scene, x, y);

        const hud = new Phaser.GameObjects.Image(this.scene, x, y, ASSETS.gui.hudBossPower.name)
            .setOrigin(0, 0);
        this.add(hud);

        this.setSize(ASSETS.gui.hudBossPower.frameW, ASSETS.gui.hudBossPower.frameH);

        this.powerBar = new Phaser.GameObjects.Image(this.scene, x + 110 + ASSETS.gui.hudPowerItem.frameW / 2, y + 60, ASSETS.gui.hudPowerItem.name, 0)
            .setOrigin(0.5, 0.5);
        this.add(this.powerBar);
        this.setScrollFactor(0, 0);

        this.globalEvents.on(EVENT_BOSS_POWER_UPDATE, this.onPowerUpdate, this);
        this.globalEvents.on(EVENT_BOSS_KILLED, this.onBossKilled, this);
    }

    public destroy(fromScene?: boolean): void {
        this.globalEvents.off(EVENT_BOSS_POWER_UPDATE, this.onPowerUpdate, this);
        this.globalEvents.off(EVENT_BOSS_KILLED, this.onBossKilled, this);
        super.destroy(fromScene);
    }

    public setPower(value: number): void {
        const percent = Math.max(0, Math.min(100, value / this.maxPower)) * 100;
        const step = 100 / ASSETS.gui.hudPowerItem.framesCount;
        const frame = ASSETS.gui.hudPowerItem.framesCount - Math.round(percent / step) - 1;
        this.powerBar.setFrame(frame);
    }

    private onBossKilled(): void {
        this.destroy();
    }

    private onPowerUpdate(): void {
        this.setPower(GlobalObjects.boss.power);
    }
}
