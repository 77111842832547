import { ASSETS, PlayerAssets } from '../assets';
import {
    EVENT_BADGE_FOUND, EVENT_GAME_OVER,
    EVENT_PLAYER_DEATH,
    EVENT_PLAYER_HURT, EVENT_PLAYER_LIFE_UPDATE,
    EVENT_PLAYER_POWER_UPDATE,
    GlobalEvents
} from '../helpers/global-events';
import { GlobalObjects } from '../helpers/global-objects';
import {VirtualGamePad} from '../helpers/virtual-game-pad';
import { globalConfig } from '../config';
import { StaticPlatformCollider } from '../map/static-platform-collider';
import { GameSpriteHelper } from './game-sprite-helper';
import { InteractiveObjectInterface } from './interactive-object-interface';
import { MovingPlatformSprite } from './moving-platform-sprite';
import { GameInputs } from '../helpers/game-inputs';
import {AmmoSprite} from './ammo-sprite';
import {LIVES_VALUE, POWER_VALUE, THROW_FLAME_DELAY, TURBO_SPEED, WALK_SPEED} from '../constants';
import { ItemSprite, ItemType } from './item-sprite';
import {PlayerType} from './player-type.enum';
import Animation = Phaser.Animations.Animation;
import BaseSound = Phaser.Sound.BaseSound;

export enum PlayerStates {
    STANDING = 0,
    FALLING = 1,
    JUMPING = 3,
    WALKING = 4,
    RUNNING = 5,
    DYING = 6,
    HURTING = 7,
    ATTACK_HAND = 8,
    ATTACK_THROW = 9
}

export class PlayerSprite extends Phaser.Physics.Arcade.Sprite implements InteractiveObjectInterface {
    private currentTime: number = 0;
    private asset: PlayerAssets;
    private isHurting = false;
    private isDead: boolean = false;
    private isAttacking = false;
    private isTurbo = false;
    private jumpVelocity = -900;
    private turboJumpVelocity = -1100;
    private lastAttackThrowTime = 0;
    private attackThrowMinDelay = 300; // how often user can fire an ammo
    private walkVelocity = WALK_SPEED;
    private runVelocity = TURBO_SPEED;
    private beforeHurtState: PlayerStates;
    private isOnMovingPlatform: boolean;
    private isOnStaticPlatform: boolean;
    private currentMovingPlatform: MovingPlatformSprite;
    private text: Phaser.GameObjects.Text;
    private originRect: Phaser.GameObjects.Rectangle;
    private ammo: Phaser.GameObjects.Group;
    private globalEvents: GlobalEvents = GlobalEvents.resolve();
    private walkSound: BaseSound;
    private runSound: BaseSound;
    private landSound: BaseSound;
    private ouchSound: BaseSound;
    private jumpSound: BaseSound;
    private currentSound: BaseSound;
    public body: Phaser.Physics.Arcade.Body;
    public inputs: GameInputs = GameInputs.resolve();
    public readonly hurtScore = 0; // touching player is not hurting anyone
    public power: number = POWER_VALUE;
    public lives: number = LIVES_VALUE;

    public get playerIsDead(): boolean {
        return this.isDead;
    }

    public get isJumping(): boolean {
        return this.state === PlayerStates.JUMPING;
    }

    constructor(scene: Phaser.Scene, x: number, y: number, private playerType: PlayerType = PlayerType.player1, private hasFixedState: boolean = false) {
        super(scene, x, y, 'player');

        this.asset = <PlayerAssets>ASSETS[playerType];

        this.scene.add.existing(this);
        this.scene.physics.world.enable(this);
        this.setOrigin(0.5, 0.87);
        if (globalConfig.debug) {
            this.text = this.scene.add.text(x, y, 'PLAYER');
            this.text.setDepth(1);
            this.originRect = this.scene.add.rectangle(this.x + this.displayOriginX, this.y + this.displayOriginY, 2, 2, 0x000000);
            this.originRect.setDepth(999);
        }

        this.body
            .setFrictionX(1)
            .setBounce(0.5, 0)
            .setCollideWorldBounds(true);

        // sounds
        this.walkSound = this.scene.sound.add('music/walk', {loop: true});
        this.runSound = this.scene.sound.add('music/run', {loop: true});
        this.landSound = this.scene.sound.add('music/land', {loop: false});
        this.ouchSound = this.scene.sound.add('music/player-ouch', {loop: false, volume: 0.7});
        this.jumpSound = this.scene.sound.add('music/jump', {loop: false, volume: 0.4});

        this.ammo = this.scene.physics.add.group({
            allowGravity: false,
            mass: 0.00000001
        });
        this.scene.physics.add.collider(this.ammo, GlobalObjects.enemies, (enemy: any, flame: any) => {
            const ammoKiller =(<AmmoSprite>flame);
            if (!ammoKiller.isAboutToKill) {
                ammoKiller.isAboutToKill = true;
                (<InteractiveObjectInterface>enemy).hurt(ammoKiller.hurtScore);
            }
            ammoKiller.killAmo();
        });

        this.scene.anims.create({
            key: this.playerType + '/stand',
            repeat: -1,
            frames: [
                ...this.anims.generateFrameNames(this.asset.idle.name),
                ...this.anims.generateFrameNames(this.asset.idle.name),
                ...this.anims.generateFrameNames(this.asset.idleBlink.name)
            ],
            frameRate: this.asset.idle.fps
        });
        this.scene.anims.create({
            key: this.playerType + '/walk',
            repeat: -1,
            frames: this.asset.walk.name,
            frameRate: this.asset.walk.fps
        });
        this.scene.anims.create({
            key: this.playerType + '/run',
            repeat: -1,
            frames: this.asset.run.name,
            frameRate: this.asset.run.fps
        });
        this.scene.anims.create({
            key: this.playerType + '/jump',
            repeat: -1,
            frames: this.asset.jump.name,
            frameRate: this.asset.jump.fps
        });
        this.scene.anims.create({
            key: this.playerType + '/fall',
            repeat: -1,
            frames: this.asset.fall.name,
            frameRate: this.asset.fall.fps
        });
        this.scene.anims.create({
            key: this.playerType + '/crouch',
            repeat: -1,
            frames: this.asset.idleBlink.name,
            frameRate: this.asset.idleBlink.fps
        });
        this.scene.anims.create({
            key: this.playerType + '/die',
            repeat: this.hasFixedState ? -1 : 0,
            frames: //this.anims.generateFrameNames(this.asset.death.name, {frames: [0]}),
            this.asset.death.name,
            frameRate: this.asset.death.fps
        });

        this.scene.anims.create({
            key: this.playerType + '/hurt',
            repeat: this.hasFixedState ? -1 : 0,
            frames: this.asset.hurt.name,
            frameRate: this.asset.hurt.fps
        });

        this.scene.anims.create({
            key: this.playerType + '/attack',
            repeat: -1,
            frames: this.asset.attack.name,
            frameRate: this.asset.attack.fps
        });
        this.onAnimationCompleteCallback(this.playerType + '/hurt', () => {
            this.globalEvents.emit(EVENT_PLAYER_HURT, this);
            this.setState(this.beforeHurtState);
        });
        this.onAnimationCompleteCallback(this.playerType + '/die', () => {
            if (this.lives === 0) {
                this.globalEvents.emit(EVENT_GAME_OVER, this);
            } else {
                this.globalEvents.emit(EVENT_PLAYER_DEATH, this);
            }
        });
        this.on(Phaser.Animations.Events.ANIMATION_STOP, (anim: Animation) => {
            if (anim.key === this.playerType + '/attack') {
                this.onStopThrowAttack();
            }
        });
        this.on(Phaser.Animations.Events.ANIMATION_REPEAT, (anim: Animation) => {
            if (anim.key === this.playerType + '/attack') {
                this.onRepeatThrowAttack();
            }
        });
        this.setState(PlayerStates.STANDING);
    }

    public setVirtualPad(vPad: VirtualGamePad): void {
        this.inputs.assignVirtualPad(vPad);
    }

    public setState(value: PlayerStates): this {
        if (value != PlayerStates.HURTING) {
            this.beforeHurtState = value;
        }
        switch (value) {
            case PlayerStates.JUMPING:
                this.setOnMovingPlatform(null);
                this.setOnStaticPlatform(null);
                this.setDebugText('JUMPING');
                GameSpriteHelper.setBodyParams(this, this.asset.jump);

                // prevent jumping in air
                if (this.body.onFloor()) {
                    this.body.setVelocityY(this.isTurbo ? this.turboJumpVelocity : this.jumpVelocity);
                    this.play(this.playerType + '/jump', true);
                    if (this.currentSound) {
                        this.currentSound.stop();
                    }
                    this.currentSound = this.jumpSound;
                    this.currentSound.play();
                }
                break;
            case PlayerStates.WALKING:
                this.setDebugText('WALKING');
                GameSpriteHelper.setBodyParams(this, this.asset.walk);
                this.play(this.playerType + '/walk', true);
                if (this.currentSound) {
                    this.currentSound.stop();
                }
                this.currentSound = this.walkSound;
                this.currentSound.play();
                break;
            case PlayerStates.RUNNING:
                this.setDebugText('RUNNING');
                GameSpriteHelper.setBodyParams(this, this.asset.run);
                this.play(this.playerType + '/run', true);
                if (this.currentSound) {
                    this.currentSound.stop();
                }
                this.currentSound = this.runSound;
                this.currentSound.play();
                break;
            case PlayerStates.FALLING:
                this.setOnMovingPlatform(null);
                this.setOnStaticPlatform(null);
                this.setDebugText('FALLING');
                GameSpriteHelper.setBodyParams(this, this.asset.fall);
                this.play(this.playerType + '/fall', true);
                if (this.currentSound) {
                    this.currentSound.stop();
                }
                break;
            case PlayerStates.DYING:
                this.setDebugText('DYING');
                this.body.setVelocity(0, 0);
                GameSpriteHelper.setBodyParams(this, this.asset.death);
                this.play(this.playerType + '/die', true);
                if (this.currentSound) {
                    this.currentSound.stop();
                }
                break;
            case PlayerStates.HURTING:
                this.setDebugText('HURTING');
                GameSpriteHelper.setBodyParams(this, this.asset.hurt);
                this.play(this.playerType + '/hurt', false);
                if (this.currentSound) {
                    this.currentSound.stop();
                }
                break;
            case PlayerStates.ATTACK_THROW:
                if (this.canAttackThrow()) {
                    this.lastAttackThrowTime = this.currentTime;
                    this.setDebugText('ATTACK_THROW');
                    GameSpriteHelper.setBodyParams(this, this.asset.attack);
                    this.play(this.playerType + '/attack', false);
                    this.attackThrow();
                    if (this.currentSound) {
                        this.currentSound.stop();
                    }
                }
                break;
            default:
            case PlayerStates.STANDING:
                this.setDebugText('STANDING');
                this.body.setVelocityX(0);
                GameSpriteHelper.setBodyParams(this, this.asset.idle);
                this.play(this.playerType + '/stand', true);
                if (this.currentSound) {
                    this.currentSound.stop();
                }
                break;
        }
        return super.setState(value);
    }

    public getIdleAnimName(): string {
        return this.playerType + '/stand';
    }

    public preUpdate(time: number, delta: number): void {
        this.currentTime = time;
        // ignore user inputs on hurt animation or for fixed sandbox state
        if (!this.hasFixedState && this.state !== PlayerStates.HURTING) {
            const {left, right, jump, jumpDown, holdShift, attack1, attack2, velocityX} = this.preProcessInputs();



            switch (this.state) {
                case PlayerStates.STANDING:
                    if (!this.body.onFloor()) {
                        //this.setState(PlayerStates.FALLING);
                    }
                    else if (jumpDown && (this.isOnStaticPlatform || this.isOnMovingPlatform)) {
                        this.setPosition(this.x, this.y + 5);
                        this.setState(PlayerStates.FALLING);
                    }
                    else if (jump) {
                        this.landSound.play();
                        this.setState(PlayerStates.JUMPING);
                    }
                    else if ((left || right) && !holdShift) {
                        this.setState(PlayerStates.WALKING);
                    }
                    else if ((left || right) && holdShift) {
                        this.setState(PlayerStates.RUNNING);
                    }
                    else if (attack1) {
                        this.setState(PlayerStates.ATTACK_HAND);
                    }
                    else if (attack2) {
                        this.setState(PlayerStates.ATTACK_THROW);
                    }
                    break;

                case PlayerStates.WALKING:
                case PlayerStates.RUNNING:

                    this.body.setVelocity(velocityX, this.body.velocity.y);

                    if (attack1) {
                        this.setState(PlayerStates.ATTACK_HAND);
                    }
                    else if (attack2) {
                        this.setState(PlayerStates.ATTACK_THROW);
                    }
                    else if (this.state === PlayerStates.WALKING && holdShift) {
                        this.setState(PlayerStates.RUNNING);
                    }
                    else if (this.state === PlayerStates.RUNNING && !holdShift) {
                        this.setState(PlayerStates.WALKING);
                    }

                    if (!this.body.onFloor()) {
                        this.setState(PlayerStates.FALLING);
                    }
                    else if (jump) {
                        this.landSound.play();
                        this.setState(PlayerStates.JUMPING);
                    }
                    else if (!left && !right) {
                        this.setState(PlayerStates.STANDING);
                    }
                    break;

                case PlayerStates.JUMPING:
                    if (this.body.velocity.y > 10) {
                        this.setState(PlayerStates.FALLING);
                    }
                    else if (!jump) {
                        this.setVelocityY(this.body.velocity.y * 0.9);
                    }

                    if (attack1) {
                        this.setState(PlayerStates.ATTACK_HAND);
                    }
                    else if (attack2) {
                        this.setState(PlayerStates.ATTACK_THROW);
                    }
                case PlayerStates.FALLING:
                    // this.setFlipX(flipX);
                    this.body.setVelocityX(velocityX);

                    if (this.body.onFloor()) {
                        this.landSound.play();
                        this.setState(PlayerStates.STANDING);
                    }

                    if (attack1) {
                        this.setState(PlayerStates.ATTACK_HAND);
                    }
                    else if (attack2) {
                        this.setState(PlayerStates.ATTACK_THROW);
                    }
                    break;

                case PlayerStates.ATTACK_HAND:
                    if (attack2) {
                        this.setState(PlayerStates.ATTACK_THROW);
                    }
                    else if ((!this.isAttacking || left || right || jump || jumpDown) && !attack1 && !attack2) {
                        if (this.body.onFloor()) {
                            this.setState(PlayerStates.STANDING);
                        }
                        else if (this.body.velocity.y > 0) {
                            this.setState(PlayerStates.FALLING);
                        }
                        else {
                            this.setState(PlayerStates.JUMPING);
                        }
                    }
                    break;

                case PlayerStates.ATTACK_THROW:
                    if (attack1) {
                        this.setState(PlayerStates.ATTACK_HAND);
                    }
                    else if ((!this.isAttacking || left || right || jump || jumpDown) && !attack1 && !attack2) {
                        if (this.body.onFloor()) {
                            this.setState(PlayerStates.STANDING);
                        }
                        else if (this.body.velocity.y > 0) {
                            this.setState(PlayerStates.FALLING);
                        }
                        else if (jump) {
                            this.setState(PlayerStates.JUMPING);
                        }
                    }
                    break;
            }
        }

        if (globalConfig.debug) {
            this.text.setPosition(this.x - this.body.width / 2, this.y - this.body.height - 40);
            this.originRect.setPosition(this.x, this.y);
        }
        super.preUpdate(time, delta);
    }

    public hurt(lostPower: number = 0): void {
        if (this.isHurting || this.isDead) {
            return;
        }
        this.power -= lostPower;
        this.globalEvents.emit(EVENT_PLAYER_POWER_UPDATE, this);
        if (this.power <= 0) {
            this.die();
            return;
        }
        this.ouchSound.play();
        this.alpha = 0.6;
        this.isHurting = true;
        this.preProcessInputs(); // must be called when calling setState from outside
        this.setState(PlayerStates.HURTING);
        this.setVelocity(0, 0);
        this.scene.time.delayedCall(2000, () => {
            this.alpha = 1;
            this.isHurting = false;
        });
    }

    public die(): void {
        this.lives--;
        this.isDead = true;
        this.power = 0;
        this.globalEvents.emit(EVENT_PLAYER_LIFE_UPDATE, this);
        this.globalEvents.emit(EVENT_PLAYER_POWER_UPDATE, this);
        this.ouchSound.play();
        this.preProcessInputs(); // must be called when calling setState from outside
        this.setState(PlayerStates.DYING);
    }

    public attackThrow(): void {
        this.isAttacking = true;
        this.scene.time.delayedCall(THROW_FLAME_DELAY, () => {
            this.throwFlame();
        });
    }

    public throwFlame(): void {
        const flame = new AmmoSprite(this.scene, this.x, this.y - 60, this.flipX);
        this.ammo.add(flame);
        flame.setVelocityX(this.flipX ? -500 : 500);
    }

    public setOnMovingPlatform(platform: MovingPlatformSprite): void {
        this.isOnMovingPlatform = !!platform;
        this.currentMovingPlatform = platform;
    }

    public setOnStaticPlatform(platform: StaticPlatformCollider): void {
        this.isOnStaticPlatform = !!platform;
    }

    public getAmmo(): Phaser.GameObjects.Group {
        return this.ammo;
    }

    public collectItem(item: ItemSprite): void {
        switch (item.itemType) {
            case ItemType.Badge:
                this.scene.time.delayedCall(300, () => {
                    this.globalEvents.emit(EVENT_BADGE_FOUND, item.badge);
                });
                break;

            case ItemType.Life:
                this.lives = this.lives === 3 ? this.lives : this.lives + 1;
                this.globalEvents.emit(EVENT_PLAYER_LIFE_UPDATE, this);
                break;

            case ItemType.Power:
                this.power += 20;
                this.power = Math.min(POWER_VALUE, this.power);
                this.globalEvents.emit(EVENT_PLAYER_POWER_UPDATE, this);
                break;

        default:
            return;
        }
    }

    /**
     * Resets player energy, and death flags. Number of lives stays the same.
     */
    public resetPlayer(): void {
        this.isDead = false;
        this.isAttacking = false;
        this.power = POWER_VALUE;
        this.isTurbo = false;
        this.setFlipX(false);
        this.setOnMovingPlatform(null);
        this.setOnStaticPlatform(null);
        this.setState(PlayerStates.STANDING);
        this.globalEvents.emit(EVENT_PLAYER_POWER_UPDATE, this);
    }

    private preProcessInputs(): {
        left: boolean, right: boolean, jump: boolean, jumpDown: boolean, holdShift: boolean, attack1: boolean, attack2: boolean,
        velocityX: number
    } {
        const {left, right, jump, jumpDown, holdShift, attack1, attack2} = this.inputs;
        const flipX =
            left && right ? this.flipX : (left ? true : (right ? false : this.flipX));
        this.isTurbo = holdShift;
        const directionX = Number(left) * -1 + Number(right);
        const attackVelocityX = attack1 || attack2 ? 0 : 1;
        const velocityX = directionX * (this.isTurbo ? this.runVelocity : this.walkVelocity) * attackVelocityX;
        this.setFlipX(flipX);

        return {
            left, right, jump, jumpDown, holdShift, attack1, attack2,
            velocityX
        };
    }

    private onAnimationCompleteCallback(animationKey: string, callback: () => void): void {
        this.on(Phaser.Animations.Events.ANIMATION_COMPLETE + '-' + animationKey, (animation: Animation) => {
            if (animation.key === animationKey) {
                callback();
            }
        }, this);
    }

    private onStopThrowAttack(): void {
        this.isAttacking = false;
    }

    private onRepeatThrowAttack(): void {
        const stillAttacking = this.inputs.attack2;
        if (stillAttacking) {
            this.attackThrow();
        }
        else {
            this.isAttacking = false;
        }
    }

    private setDebugText(text: string): void {
        if (globalConfig.debug) {
            this.text.setText(text);
            if (this.isOnMovingPlatform) {
                this.text.setText(this.text.text + ' on moving platform');
            }
            else if (this.isOnStaticPlatform) {
                this.text.setText(this.text.text + ' on static platform');
            }
        }
    }

    private canAttackThrow(): boolean {
        return this.currentTime - this.lastAttackThrowTime > this.attackThrowMinDelay;
    }
}
