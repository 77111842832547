import Container = Phaser.GameObjects.Container;
import {tweenShow} from '../gui-tweens';
import {Screen, ScreenParams} from './screen';
import {GuiWindow, WindowType} from '../gui-window';
import {GuiText, TextColor, TextType} from '../gui-text';
import {ElementType, GuiElement} from '../gui-element';
import {BadgeType} from '../../map/map-helper';
import {ButtonSize, ButtonType, GuiButton} from '../gui-button';

const titles = {
    [BadgeType.freeHeatingService]: 'Free Heating Service',
    [BadgeType.friendsAndFamily]: 'Friends & Family',
    [BadgeType.geofencing]: 'Geofencing',
    [BadgeType.smartThermostat]: 'Viessmann Smart Thermostat',
    [BadgeType.surpriseGift]: 'Viessmann Surprise Gift',
    [BadgeType.heatingCurveAssistant]: 'Heating Curve Assistant'
};

const messages = {
    [BadgeType.freeHeatingService]: 'Thank to this badge, you earned one free heating device service. Congratulations!',
    [BadgeType.friendsAndFamily]: 'Did you ever think about sharing you installation settings and access across whole family living in you house? Now it is possible. All your family members can have now share the access to the system!',
    [BadgeType.geofencing]: 'A great feature giving ability to turn on/off your heating based on localization. Imagine that your installation knows that nobody’s home, so it can turn heating off. Then it knows when you are going back and turns heating on again, so you go back to warm house.\n' +
    'Isn’t it nice!?',
    [BadgeType.smartThermostat]: 'It’s your time! You get a brand new Smart Thermostat device for your smart heating system. Don’t have it yet? Go and get it!',
    [BadgeType.surpriseGift]: 'It’s your lucky day! You will get a surprise gift from Viessmann company.',
    [BadgeType.heatingCurveAssistant]: 'Configuration of heating curve in your heat generation device isn’t a trivial task. It was possible only with help of an qualified service... till now! This new feature allows you to go step by step and configure you heating curve on your own. Go and try it yourself!'
};

export class BadgeInfoScreen extends Screen {
    private container: Container;
    private windowHeight: number;
    public static readonly key = 'gui/screen/badge-info';
    private badge: BadgeType;

    constructor() {
        super(BadgeInfoScreen.key);
    }

    protected onShow(data: ScreenParams): Promise<void> {
        this.badge = data.data.badge;
        this.initHandlers();
        this.createElements();

        tweenShow(this, this.container, {
            y: this.windowHeight / 2
        }).then(() => {
        });

        return Promise.resolve();
    }

    protected onHide(): Promise<void> {
        return Promise.resolve();
    }

    private initHandlers(): void {
        this.input.keyboard.on('keydown-ENTER', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
        this.input.keyboard.on('keydown-ESC', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
    }

    private createElements(): void {
        const wnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.big
        });
        this.windowHeight = wnd.height;

        const content = new GuiWindow({
            scene: this,
            x: 0,
            y: 70,
            windowType: WindowType.contentGroup
        });

        const title = new GuiText({
            scene: this,
            x: -82,
            y: -205,
            text: 'Badge info',
            textType: TextType.ravie,
            fontSize: 24,
            color: TextColor.yellow
        });

        const badgeTitle = new GuiText({
            scene: this,
            x: -70,
            y: -38,
            text: titles[this.badge],
            textType: TextType.ravie,
            fontSize: 24,
            color: TextColor.brown,
            textAlign: 'left',
            width: 240,
            wordWrap: true
        });
        // position text relatively to its height
        badgeTitle.setY(-23 - badgeTitle.height / 2);

        const msg = new GuiText({
            scene: this,
            x: -198,
            y: 70,
            text: messages[this.badge],
            textType: TextType.interSemiBold,
            fontSize: 14,
            color: TextColor.brown,
            width: 380,
            wordWrap: true,
            textAlign: 'left'
        });

        const backBtn = new GuiButton({
            scene: this,
            x: -152,
            y: 318,
            tag: 'ok',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.back,
            onClick: async () => {
                await this.hideContainer();
                this.onButtonClick('ok');
            }
        });

        this.container = this.add.container(this.game.canvas.width / 2, -this.windowHeight / 2);
        this.container.add(wnd);
        this.container.add(content);
        this.container.add(title);
        this.container.add(badgeTitle);
        this.container.add(msg);
        this.container.add(this.createBadge());
        this.container.add(backBtn);

        if (this.badge === BadgeType.smartThermostat) {
            // add thermostat image
            const thermostat = new GuiElement({
                elementType: ElementType.thermostatImage,
                scene: this,
                x: 130,
                y: 185
            });
            thermostat.setAlpha(0.5);
            this.container.add(thermostat);
        }
    }

    private createBadge(): GuiElement {
        return new GuiElement({
            elementType: this.resolveElementType(),
            scene: this,
            x: -147,
            y: -20
        });
    }

    private resolveElementType(): ElementType {
        switch (this.badge) {
            case BadgeType.freeHeatingService:
                return ElementType.badge1;

            case BadgeType.friendsAndFamily:
                return ElementType.badge2;

            case BadgeType.geofencing:
                return ElementType.badge3;

            case BadgeType.heatingCurveAssistant:
                return ElementType.badge4;

            case BadgeType.smartThermostat:
                return ElementType.badge5;

            case BadgeType.surpriseGift:
                return ElementType.badge6;
        }
    }

    private onButtonClick(tag: string): void {
        this.closeScreen({
            button: tag
        });
    }

    private hideContainer(): Promise<void> {
        return new Promise((resolve => {
            this.tweens.add({
                targets: this.container,
                duration: 500,
                props: {
                    y: -this.windowHeight / 2
                },
                onComplete: () => {
                    resolve();
                }
            });
        }));
    }
}
