import {ASSETS} from '../assets';
import Image = Phaser.GameObjects.Image;
import BaseSound = Phaser.Sound.BaseSound;

export class GuiButton extends Image {
    private initialScale = 0.8;
    private isPressed = false;
    private isOver = false;
    private hoverSound: BaseSound;
    private clickSound: BaseSound;

    constructor(private config: ButtonConfig) {
        super(config.scene, config.x, config.y, GuiButton.getTexture(config.buttonType), GuiButton.getStateFrame(config.buttonType));
        this.config.buttonSize = this.config.buttonSize || ButtonSize.normal;
        this.config.zoomOnHover = typeof this.config.zoomOnHover === 'undefined' ? true : this.config.zoomOnHover;
        this.config.isDisabled = !!this.config.isDisabled;

        this.initialScale = this.getButtonScale();
        this.setScrollFactor(0, 0);
        this.setOrigin(0.5, 0.5);
        this.setScale(this.initialScale);

        if (!this.config.isDisabled) {
            this.setInteractive({cursor: 'pointer'});
            this.input.hitArea.setTo(40, 30, this.width - 70, this.height - 60);
            this.hoverSound = this.scene.sound.add('music/button', {loop: false, volume: 1});
            this.clickSound = this.scene.sound.add('music/click', {loop: false, volume: 1});

            this.on('pointerdown', this.onButtonDown.bind(this));
            this.on('pointerup', this.onButtonUp.bind(this));
            this.on('pointerout', this.onOut.bind(this));
            this.on('pointerover', this.onHover.bind(this));
            this.scene.input.on('pointerup', () => {
                if (this.isPressed) {
                    this.isPressed = false;
                    this.onButtonUp();
                }
            });
        } else {
            this.setAlpha(0.5);
        }
    }

    public changeType(newType: ButtonType): void {
        this.config.buttonType = newType;
        this.setTexture(GuiButton.getTexture(this.config.buttonType), GuiButton.getStateFrame(this.config.buttonType));
    }

    public changeSize(newSize: ButtonSize): void {
        this.config.buttonSize = newSize || ButtonSize.normal;
        this.setScale(this.getButtonScale());
    }

    public getBtnType(): ButtonType {
        return this.config.buttonType;
    }

    public getTag(): string {
        return this.config.tag;
    }

    private onButtonUp(): void {
        if (!this.isOver) {
            this.setNormalState();
        }
        if (this.isPressed) {
            this.isPressed = false;
            this.clickSound.play();
            this.scene.time.delayedCall(100, () => {
                if (this.config.onClick) {
                    this.config.onClick(this.config.tag);
                }
            }); // allow to play sound even on scene stopping
        }
    }

    private onButtonDown(): void {
        this.isPressed = true;
    }

    private onHover(): void {
        this.hoverSound.play();
        this.isOver = true;
        this.setHoverState();
        if (this.config.onHover) {
            this.config.onHover(this.config.tag);
        }
    }

    private onOut(): void {
        this.isOver = false;
        if (!this.isPressed) {
            this.setNormalState();
        }
        if (this.config.onHoverOut) {
            this.config.onHoverOut(this.config.tag);
        }
    }

    private setHoverState(): void {
        this.setTexture(GuiButton.getTexture(this.config.buttonType), GuiButton.getStateFrame(this.config.buttonType, false));
        if (this.config.zoomOnHover) {
            this.setScale(this.initialScale * 1.1);
        }
    }

    private setNormalState(): void {
        this.setTexture(GuiButton.getTexture(this.config.buttonType), GuiButton.getStateFrame(this.config.buttonType, true));
        if (this.config.zoomOnHover) {
            this.setScale(this.initialScale);
        }
    }

    private getButtonScale(): number {
        switch (this.config.buttonSize) {
            case ButtonSize.small:
                return 0.5;
            case ButtonSize.big:
                return 1.0;
            default:
            case ButtonSize.normal:
                return 0.7;
        }
    }

    private static getStateFrame(type: ButtonType, normalState: boolean = true): number {
        switch (type) {
            case ButtonType.back:
                return normalState ? ASSETS.gui.backBtn.normal : ASSETS.gui.backBtn.hover;
            case ButtonType.badge:
                return normalState ? ASSETS.gui.badgeBtn.normal : ASSETS.gui.badgeBtn.hover;
            case ButtonType.cancel:
                return normalState ? ASSETS.gui.cancelBtn.normal : ASSETS.gui.cancelBtn.hover;
            case ButtonType.settings:
                return normalState ? ASSETS.gui.settingsBtn.normal : ASSETS.gui.settingsBtn.hover;
            case ButtonType.ok:
                return normalState ? ASSETS.gui.okBtn.normal : ASSETS.gui.okBtn.hover;
            case ButtonType.retry:
                return normalState ? ASSETS.gui.retryBtn.normal : ASSETS.gui.retryBtn.hover;
            case ButtonType.soundOn:
                return normalState ? ASSETS.gui.soundOnBtn.normal : ASSETS.gui.soundOnBtn.hover;
            case ButtonType.soundOff:
                return normalState ? ASSETS.gui.soundOffBtn.normal : ASSETS.gui.soundOffBtn.hover;
            case ButtonType.controls:
                return normalState ? ASSETS.gui.controlsBtn.normal : ASSETS.gui.controlsBtn.hover;
            case ButtonType.itemSlot:
                return ASSETS.gui.itemSlot.normal;

            case ButtonType.textStart:
            case ButtonType.textAbout:
            case ButtonType.textControls:
            case ButtonType.textPlayers:
            case ButtonType.textContinue:
            case ButtonType.textSmallVitodens:
            case ButtonType.textSmallVitodensF:
            case ButtonType.textSmallVitocal:
            case ButtonType.textSmallVitovolt:
            case ButtonType.textSmallVitocell:
            case ButtonType.fullScreenOn:
            case ButtonType.fullScreenOff:
                return normalState ? 0 : 1;
        }
        return 0;
    }

    private static getTexture(type: ButtonType): string {
        switch (type) {
            case ButtonType.textStart:
                return ASSETS.gui.textStartBtn.name;
            case ButtonType.textAbout:
                return ASSETS.gui.textAboutBtn.name;
            case ButtonType.textControls:
                return ASSETS.gui.textControlsBtn.name;
            case ButtonType.textPlayers:
                return ASSETS.gui.textPlayersBtn.name;
            case ButtonType.textContinue:
                return ASSETS.gui.textContinueBtn.name;

            case ButtonType.textSmallVitodens:
                return ASSETS.gui.textVitodensSmallBtn.name;
            case ButtonType.textSmallVitodensF:
                return ASSETS.gui.textVitodensFSmallBtn.name;
            case ButtonType.textSmallVitocal:
                return ASSETS.gui.textVitocalSmallBtn.name;
            case ButtonType.textSmallVitovolt:
                return ASSETS.gui.textVitovoltSmallBtn.name;
            case ButtonType.textSmallVitocell:
                return ASSETS.gui.textVitocellSmallBtn.name;

            case ButtonType.fullScreenOn:
                return ASSETS.gui.fullScreenOnBtn.name;
            case ButtonType.fullScreenOff:
                return ASSETS.gui.fullScreenOffBtn.name;

            case ButtonType.back:
            case ButtonType.badge:
            case ButtonType.cancel:
            case ButtonType.settings:
            case ButtonType.ok:
            case ButtonType.retry:
            case ButtonType.soundOn:
            case ButtonType.soundOff:
            case ButtonType.controls:
            case ButtonType.itemSlot:
            default:
                return ASSETS.gui.elements.name;
        }
    }
}

export enum ButtonType {
    badge = 1,
    settings,
    ok,
    retry,
    back,
    cancel,
    soundOn,
    soundOff,
    controls,
    itemSlot,

    fullScreenOn,
    fullScreenOff,

    textStart = 100,
    textAbout,
    textControls,
    textPlayers,
    textContinue,

    textSmallVitodens = 200,
    textSmallVitodensF,
    textSmallVitocal,
    textSmallVitovolt,
    textSmallVitocell,
}

export enum ButtonSize {
    small = 1,
    normal,
    big
}

export interface ButtonConfig {
    scene: Phaser.Scene;
    x: number;
    y: number;
    isDisabled?: boolean;
    buttonType: ButtonType;
    buttonSize?: ButtonSize;
    zoomOnHover?: boolean;
    onClick?: (tag: string) => void;
    onHover?: (tag: string) => void;
    onHoverOut?: (tag: string) => void;
    tag?: string;
}
