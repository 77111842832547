import {ButtonSize, ButtonType, GuiButton} from '../gui-button';
import {ElementType, GuiElement} from '../gui-element';
import {GuiText, TextColor, TextType} from '../gui-text';
import {tweenHide, tweenShow} from '../gui-tweens';
import {GuiWindow, WindowType} from '../gui-window';
import {Screen, ScreenParams} from './screen';
import Container = Phaser.GameObjects.Container;

export class ExitQuestionScreen extends Screen {
    private container: Container;
    private windowHeight: number;
    private continueHint: GuiElement;
    private exitHint: GuiElement;
    public static readonly key = 'gui/screen/exit-question';

    constructor() {
        super(ExitQuestionScreen.key);
    }

    protected onShow(data: ScreenParams): Promise<void> {
        this.createElements();
        return tweenShow(this, this.container, {
            y: this.windowHeight / 2
        });
    }
    protected onHide(): Promise<void> {
        return tweenHide(this, this.container, {
            y: -this.windowHeight / 2
        });
    }

    private createElements(): void {
        const wnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.rect
        });
        this.windowHeight = wnd.height;

        const msg = new GuiText({
            scene: this,
            x: -50,
            y: 50,
            width: 600,
            text: `Do you really want to exit\nthe game?`,
            textType: TextType.interSemiBold,
            textAlign: 'left',
            fontSize: 22,
            color: TextColor.brown
        });

        this.exitHint = new GuiElement({
            scene: this,
            x: -90,
            y: -10,
            elementType: ElementType.playerExitHint
        });

        this.continueHint = new GuiElement({
            scene: this,
            x: -90,
            y: -10,
            elementType: ElementType.playerContinueHint
        });
        this.continueHint.setVisible(false);

        const okBtn = new GuiButton({
            scene: this,
            x: 150,
            y: 220,
            tag: 'ok',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.ok,
            onClick: this.onButtonClick.bind(this)
        });

        const cancelBtn = new GuiButton({
            scene: this,
            x: -150,
            y: 220,
            tag: 'cancel',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.cancel,
            onClick: this.onButtonClick.bind(this),
            onHover: this.onHoverCancel.bind(this),
            onHoverOut: this.onHoverOutCancel.bind(this)
        });

        this.container = this.add.container(this.game.canvas.width / 2, -this.windowHeight / 2);
        this.container.add(wnd);
        this.container.add(msg);
        this.container.add(this.exitHint);
        this.container.add(this.continueHint);
        this.container.add(okBtn);
        this.container.add(cancelBtn);
    }

    private onButtonClick(tag: string): void {
        this.closeScreen({
            button: tag
        });
    }

    private onHoverCancel(): void {
        this.exitHint.setVisible(false);
        this.continueHint.setVisible(true);
    }

    private onHoverOutCancel(): void {
        this.exitHint.setVisible(true);
        this.continueHint.setVisible(false);
    }
}
