import Container = Phaser.GameObjects.Container;
import {ASSETS} from '../../assets';
import {EVENT_PLAYER_LIFE_UPDATE, GlobalEvents} from '../../helpers/global-events';
import {GlobalObjects} from '../../helpers/global-objects';
import Image = Phaser.GameObjects.Image;

export class LivesHud extends Container {
    private lifeSlots: Image[] = [];
    private globalEvents: GlobalEvents = GlobalEvents.resolve();

    constructor(scene: Phaser.Scene, x: number, y: number, private maxLives: number = 3) {
        super(scene, x, y);

        const hud = new Phaser.GameObjects.Image(this.scene, x, y, ASSETS.gui.hudLife.name)
            .setOrigin(0, 0);
        this.add(hud);
        this.setSize(ASSETS.gui.hudLife.frameW, ASSETS.gui.hudLife.frameH);

        for (let i = 0; i < maxLives; i++) {
            this.lifeSlots[i] = new Phaser.GameObjects.Image(this.scene, x + 150 + i * (ASSETS.gui.hudLifeItem.frameW + 7), y + 60, ASSETS.gui.hudLifeItem.name, 0)
                .setOrigin(0.5, 0.5);
            this.add(this.lifeSlots[i]);
        }
        this.setScrollFactor(0, 0);
        this.setLives(GlobalObjects.player.lives);
        this.globalEvents.on(EVENT_PLAYER_LIFE_UPDATE, this.onLivesUpdate, this);
    }

    public destroy(fromScene?: boolean): void {
        this.globalEvents.off(EVENT_PLAYER_LIFE_UPDATE, this.onLivesUpdate, this);
        super.destroy(fromScene);
    }

    public setLives(count: number): void {
        for (let i = 0; i < this.maxLives; i++) {
            this.lifeSlots[i].setFrame(i < count ? 0 : 1);
        }
    }

    private onLivesUpdate(): void {
        this.setLives(GlobalObjects.player.lives);
    }
}
