// Object layers on map
export const LAYER_CHECKPOINTS = 'checkpoints';
export const LAYER_LOCATIONS = 'locations';
export const LAYER_PLATFORMS = 'platforms';
export const LAYER_DEATH_AREAS = 'death-areas';
export const LAYER_GROUNDS = 'grounds';
export const LAYER_BKG_1 = 'background-1';
export const LAYER_BKG_2 = 'background-2';
export const LAYER_FKG_1 = 'foreground-1';
export const LAYER_FKG_2 = 'foreground-2';

// Tile layers on map
export const LAYER_GROUND_1 = 'ground-1';
export const LAYER_GROUND_2 = 'ground-2';

// Animations
export const THROW_FLAME_DELAY = 100;

// Speeds
export const WALK_SPEED = 200;
export const TURBO_SPEED = 450;

// Huds
export const POWER_VALUE = 100;
export const LIVES_VALUE = 3;
