import {GameStore} from '../helpers/game-store';
import {
    GlobalEvents,
    EVENT_PLAYER_DEATH,
    EVENT_BADGE_FOUND,
    EVENT_DISABLE_SOUND,
    EVENT_ENABLE_SOUND,
    EVENT_SHOW_UNLOCKED_BADGES,
    EVENT_SHOW_BADGE_INFO,
    EVENT_SHOW_GAME_COMPLETED,
    EVENT_SHOW_EXIT_GAME_QUESTION,
    EVENT_GAME_OVER,
    EVENT_GLOBAL_ERROR,
    EVENT_SHOW_MISSING_BADGES_INFO
} from '../helpers/global-events';
import {GlobalObjects} from '../helpers/global-objects';
import {AnyLevelScene} from '../scenes/any-level-scene';
import {WelcomeScene} from '../scenes/welcome-scene';
import {PlayerSprite} from '../sprites/player-sprite';
import {ScreensService} from './screens-service';
import {ErrorScreen} from './screens/error-screen';
import {ExitQuestionScreen} from './screens/exit-question-screen';
import {GameCompletedScreen} from './screens/game-completed-screen';
import {MessageScreen} from './screens/message-screen';
import {PlayerDeathScreen} from './screens/player-death-screen';
import {PlayerFinalDeathScreen} from './screens/player-final-death-screen';
import {WelcomeScreen} from './screens/welcome-screen';
import {BadgeType} from '../map/map-helper';
import {BadgeService} from './bagde-service';
import {BadgesScreen} from './screens/badges-screen';
import {BadgeInfoScreen} from './screens/badge-info-screen';
import {ScreenParams, ScreenResult} from './screens/screen';

export class GUI {
    private static instance: GUI;
    private globalEvents: GlobalEvents = GlobalEvents.resolve();
    private screens: ScreensService = ScreensService.resolve();
    private badgeService: BadgeService = BadgeService.resolve();

    /**
     * Resolve global events service instance.
     */
    public static resolve(): GUI {
        if (!GUI.instance) {
            GUI.instance = new GUI();
        }
        return GUI.instance;
    }

    private constructor() {
        // handle player death screen
        this.globalEvents.on(EVENT_PLAYER_DEATH, (player: PlayerSprite) => {
            this.screens.show({data: player.lives}, PlayerDeathScreen.key)
                .then(result => this.screens.hide())
                .then(() => {
                    this.screens.restartMainScene();
                });
        });

        this.globalEvents.on(EVENT_GAME_OVER, (player: PlayerSprite) => {
            this.screens.show({}, PlayerFinalDeathScreen.key)
                .then(result => {
                    return result.button;
                })
                .then(async (btn: string) => {
                    await this.screens.hide();
                    this.screens.startNewMainScene(WelcomeScene.SCENE_KEY);
                });
        });

        this.globalEvents.on(EVENT_BADGE_FOUND, (badge: BadgeType) => {
            this.badgeService.addBadgeToUnlocked(badge);
        });

        this.globalEvents.on(EVENT_SHOW_UNLOCKED_BADGES, () => {
            this.screens.show({}, BadgesScreen.key)
                .then(async (result: ScreenResult) => {
                    if (result.button === 'ok') {
                        await this.screens.hide();
                    }
                    else if (result.button === 'show-badge-info') {
                        await this.screens.hide();
                        this.globalEvents.emit(EVENT_SHOW_BADGE_INFO, result.data.badge);
                    }
                });
        });

        this.globalEvents.on(EVENT_SHOW_BADGE_INFO, (badge: BadgeType) => {
            this.screens.show({data: {badge}}, BadgeInfoScreen.key)
                .then(result => {
                    return result.button;
                })
                .then(async (btn: string) => {
                    if (btn === 'ok') {
                        await this.screens.hide();
                        this.globalEvents.emit(EVENT_SHOW_UNLOCKED_BADGES);
                    }
                });
        });

        this.globalEvents.on(EVENT_SHOW_EXIT_GAME_QUESTION, () => {
            this.screens.show({data: {}}, ExitQuestionScreen.key)
                .then(result => {
                    return result.button;
                })
                .then(async (btn: string) => {
                    await this.screens.hide();
                    if (btn === 'ok') {
                        this.screens.startNewMainScene(WelcomeScene.SCENE_KEY);
                    }
                });
        });

        this.globalEvents.on(EVENT_SHOW_GAME_COMPLETED, () => {
            this.screens.show({data: {}}, GameCompletedScreen.key)
                .then(result => {
                    return result.button;
                })
                .then(async (btn: string) => {
                    if (btn === 'ok') {
                        if (GameStore.isBossLastLevel) {
                            GameStore.resetSingleLevel(GameStore.lastLevelName);
                        }
                        await this.screens.hide();
                        this.screens.startNewMainScene(WelcomeScene.SCENE_KEY);
                    }
                });
        });

        this.globalEvents.on(EVENT_GLOBAL_ERROR, () => {
            this.screens.show({data: {}}, ErrorScreen.key)
                .then(async () => {
                    location.reload();
                });
        });

        this.globalEvents.on(EVENT_SHOW_MISSING_BADGES_INFO, () => {
            const params: ScreenParams<{title: string, msg: string}> = {
                data: {
                    title: 'UPS!',
                    msg: 'Please collect all BADGES to go there!'
                }
            }
            this.screens.show(params, MessageScreen.key)
                .then(async () => {
                    await this.screens.hide();
                });
        });


        this.globalEvents.on(EVENT_DISABLE_SOUND, () => {
            this.screens.soundMute(true);
        });
        this.globalEvents.on(EVENT_ENABLE_SOUND, () => {
            this.screens.soundMute(false);
        });
    }

    public startWelcomeScreen(): Promise<void> {
        return this.screens.show({}, WelcomeScreen.key, false)
                   .then(result => {
                       return result.button;
                   })
                   .then(async (btn: string) => {
                       if (btn === 'start' || btn === 'continue') {
                           await this.screens.hide();
                           GlobalObjects.setCurrentSceneName(GameStore.lastLevelName);
                           this.screens.startNewMainScene(AnyLevelScene.SCENE_KEY);
                       }
                   });
    }

    public exitGameQuestion(): Promise<void> {
        this.globalEvents.emit(EVENT_SHOW_EXIT_GAME_QUESTION);
        return Promise.resolve();
    }
}
