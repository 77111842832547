import {ASSETS} from '../assets';
import Image = Phaser.GameObjects.Image;

export class GuiElement extends Image {
    constructor(private config: ElementConfig) {
        super(config.scene, config.x, config.y, GuiElement.getAsset(config.elementType).key, GuiElement.getAsset(config.elementType).frame)
        this.setOrigin(0.5, 0.5);
        if (config.scale) {
            this.setScale(config.scale);
        }
        if (config.onClick) {
            this.setInteractive({cursor: 'pointer'});
            this.on('pointerdown', () => {
                this.config.onClick(this.config.tag);
            });
        }
    }

    private static getAsset(type: ElementType): {key: string, frame: number} {
        switch (type) {
            case ElementType.thermostatImage: return {
                key: ASSETS.gui.thermostatImage.assetName,
                frame: ASSETS.gui.thermostatImage.frame
            };
            case ElementType.life: return {
                key: ASSETS.gui.life.assetName,
                frame: ASSETS.gui.life.frame
            };
            case ElementType.badgeBag: return {
                key: ASSETS.gui.badgeBag.name,
                frame: 0
            };
            case ElementType.playerDeath: return {
                key: ASSETS.gui.playerDeath.name,
                frame: 0
            };
            case ElementType.windowContentGroup: return {
                key: ASSETS.gui.windowContentGroup.name,
                frame: 0
            };
            case ElementType.windowContentGroupSmall: return {
                key: ASSETS.gui.windowContentGroupSmall.name,
                frame: 0
            };
            case ElementType.windowContentGroupRow: return {
                key: ASSETS.gui.windowContentGroupRow.name,
                frame: 0
            };
            case ElementType.windowLarge: return {
                key: ASSETS.gui.windowLarge.name,
                frame: 0
            };
            case ElementType.windowWide: return {
                key: ASSETS.gui.windowWide.name,
                frame: 0
            };
            case ElementType.windowUltraWide: return {
                key: ASSETS.gui.windowUltraWide.name,
                frame: 0
            };
            case ElementType.playerExitHint: return {
                key: ASSETS.gui.playerWithHint.assetName,
                frame: ASSETS.gui.playerWithHint.exit
            };
            case ElementType.playerContinueHint: return {
                key: ASSETS.gui.playerWithHint.assetName,
                frame: ASSETS.gui.playerWithHint.continue
            };
            case ElementType.itemSlot: return {
                key: ASSETS.gui.itemSlot.assetName,
                frame: ASSETS.gui.itemSlot.normal
            };
            case ElementType.itemSlotLocked: return {
                key: ASSETS.gui.itemSlot.assetName,
                frame: ASSETS.gui.itemSlot.locked
            };
            case ElementType.welcomeScreenTitle: return {
                key: ASSETS.gui.welcomeScreenTitle.name,
                frame: 0
            };
            case ElementType.controls: return {
                key: ASSETS.gui.controls.name,
                frame: 0
            };
            case ElementType.badge1: return {
                key: ASSETS.gui.badge.assetName,
                frame: ASSETS.gui.badge.freeHeatingService
            };
            case ElementType.badge2: return {
                key: ASSETS.gui.badge.assetName,
                frame: ASSETS.gui.badge.friendsAndFamily
            };
            case ElementType.badge3: return {
                key: ASSETS.gui.badge.assetName,
                frame: ASSETS.gui.badge.geofencing
            };
            case ElementType.badge4: return {
                key: ASSETS.gui.badge.assetName,
                frame: ASSETS.gui.badge.heatingCurveAssistant
            };
            case ElementType.badge5: return {
                key: ASSETS.gui.badge.assetName,
                frame: ASSETS.gui.badge.smartThermostat
            };
            case ElementType.badge6: return {
                key: ASSETS.gui.badge.assetName,
                frame: ASSETS.gui.badge.surpriseGift
            };
            case ElementType.congratulations: return {
                key: ASSETS.gui.congratulations.name,
                frame: 0
            };
            case ElementType.checkmark: return {
                key: ASSETS.gui.checkmark.name,
                frame: 0
            };
            case ElementType.difficultyEasy: return {
                key: ASSETS.gui.difficultyLevelEasy.name,
                frame: 0
            };
            case ElementType.difficultyNormal: return {
                key: ASSETS.gui.difficultyLevelNormal.name,
                frame: 0
            };
            case ElementType.difficultyHard: return {
                key: ASSETS.gui.difficultyLevelHard.name,
                frame: 0
            };
            case ElementType.filePdf: return {
                key: ASSETS.gui.filePdf.name,
                frame: 0
            };
            default: return {
                key: 'unknown',
                frame: 0
            };
        }
    }
}

export enum ElementType {
    thermostatImage = 1,
    life,
    badgeBag,
    playerDeath,
    windowContentGroup,
    windowContentGroupSmall,
    windowContentGroupRow,
    windowLarge,
    windowWide,
    windowUltraWide,
    playerExitHint,
    playerContinueHint,
    itemSlot,
    itemSlotLocked,
    welcomeScreenTitle,
    controls,
    badge1,
    badge2,
    badge3,
    badge4,
    badge5,
    badge6,
    congratulations,
    difficultyEasy,
    difficultyNormal,
    difficultyHard,
    checkmark,

    filePdf
}

export interface ElementConfig {
    scene: Phaser.Scene;
    x: number;
    y: number;
    elementType: ElementType;
    scale?: number;
    tag?: string;
    onClick?: (tag: string) => void;
}
