import {ASSETS, AssetAnimSpriteInfo, CheckpointAsset} from '../assets';
import {EVENT_CHECKPOINT_ACTIVATE, GlobalEvents} from '../helpers/global-events';

enum CheckpointState {
    Inactive = 1,
    Active,
    Activating,
    Deactivating
}

export class CheckpointSprite extends Phaser.Physics.Arcade.Sprite {
    private _id: string;
    private asset: CheckpointAsset = Object.assign({}, ASSETS.checkpoint);
    private globalEvents: GlobalEvents = GlobalEvents.resolve();
    public body: Phaser.Physics.Arcade.StaticBody;

    public get id(): string {
        return this._id;
    }

    constructor(scene: Phaser.Scene, x: number, y: number, checkpointId: string) {
        super(scene, x, y, 'checkpoint');
        this._id = checkpointId;
        this.scene.add.existing(this);
        this.scene.physics.add.existing(this, true);
        this.setImmovable();

        if (!this.scene.anims.exists('checkpoint/activate')) {
            this.scene.anims.create({
                key: 'checkpoint/inactive',
                repeat: 0,
                frames: this.anims.generateFrameNames(this.asset.activate.name, {
                    frames: [0]
                }),
                frameRate: 0,
                showOnStart: true,
                hideOnComplete: false
            });
            this.scene.anims.create({
                key: 'checkpoint/activate',
                repeat: 0,
                frames: this.anims.generateFrameNames(this.asset.activate.name),
                frameRate: this.asset.activate.fps,
                showOnStart: true,
                hideOnComplete: false
            });

            this.scene.anims.create({
                key: 'checkpoint/deactivate',
                repeat: 0,
                frames: this.anims.generateFrameNames(this.asset.activate.name).reverse(),
                frameRate: this.asset.activate.fps,
                showOnStart: true,
                hideOnComplete: false
            });

            this.scene.anims.create({
                key: 'checkpoint/active',
                repeat: -1,
                frames: this.anims.generateFrameNames(this.asset.active.name),
                frameRate: this.asset.active.fps,
                showOnStart: true,
                hideOnComplete: false
            });
        }

        this.onAnimationCompleteCallback('checkpoint/activate', () => {
            this.setState(CheckpointState.Active);
            this.updateFromAsset();
        });

        this.onAnimationCompleteCallback('checkpoint/deactivate', () => {
            this.setState(CheckpointState.Inactive);
            this.updateFromAsset();
        });

        this.globalEvents.on(EVENT_CHECKPOINT_ACTIVATE, this.onCheckpointActivate, this);
        this.setState(CheckpointState.Inactive);
    }

    public destroy(fromScene?: boolean): void {
        this.globalEvents.off(EVENT_CHECKPOINT_ACTIVATE, this.onCheckpointActivate, this);
        super.destroy(fromScene);
    }

    public setState(value: CheckpointState): this {
        switch (value) {
            default:
            case CheckpointState.Inactive:
                this.play('checkpoint/inactive', true);
                break;

            case CheckpointState.Activating:
                this.play('checkpoint/activate', true);
                break;

            case CheckpointState.Deactivating:
                this.play('checkpoint/deactivate', true);
                break;

            case CheckpointState.Active:
                this.play('checkpoint/active', true);
                break;
        }
        this.updateFromAsset();
        return super.setState(value);
    }

    private onCheckpointActivate(checkpointId: string): void {
        if (checkpointId === this.id) {
            this.activate();
        }
        else {
            this.deactivate();
        }
    }

    private activate(): void {
        if (this.state === CheckpointState.Inactive) {
            this.setState(CheckpointState.Activating);
        }
    }

    private deactivate(): void {
        if (this.state === CheckpointState.Active) {
            this.setState(CheckpointState.Deactivating);
        }
    }

    private updateFromAsset(): void {
        const isActive = this.state === CheckpointState.Active;
        const assetInfo: AssetAnimSpriteInfo = isActive ? this.asset.active : this.asset.activate;
        // GameSpriteHelper.setBodyParams(this, assetInfo);


        if (this.flipX) {
            this.setOrigin(1 - assetInfo.originX, assetInfo.originY);
            this.body.setOffset(
                assetInfo.frameW - (assetInfo.bodyW + assetInfo.offsetX), assetInfo.offsetY
            );
        }
        else {
            this.setOrigin(assetInfo.originX, assetInfo.originY);
            this.body.setOffset(assetInfo.offsetX, assetInfo.offsetY);
        }

        this.body.updateFromGameObject();
        this.body.setSize(assetInfo.bodyW, assetInfo.bodyH);
    }

    private onAnimationCompleteCallback(animationKey: string, callback: () => void): void {
        this.on(Phaser.Animations.Events.ANIMATION_COMPLETE + '-' + animationKey, (animation: Phaser.Animations.Animation) => {
            if (animation.key === animationKey) {
                callback();
            }
        }, this);
    }

}
