import Rectangle = Phaser.GameObjects.Rectangle;
import {globalConfig} from '../config';

export class StaticPlatformCollider extends Rectangle {
    public body: Phaser.Physics.Arcade.Body;
    constructor(scene: Phaser.Scene, x: number, y: number, w: number, h: number) {
        super(scene, x, y, w, h);
        this.scene.physics.world.enable(this);
        this.body.debugBodyColor = 0x00FF00;
        this.body
            .setImmovable(true)
            .setAllowGravity(false);
        this.setOrigin(0, 0)

        if (globalConfig.debug) {
            this.scene.add.text(x, y - 20, `${this.body.x}, ${this.body.y}`, {color: '#00FF00'})
        }
    }
}
