import VirtualJoystickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin';
import {BadgeFoundScreen} from './gui/screens/badge-found-screen';
import {BadgeInfoScreen} from './gui/screens/badge-info-screen';
import {BadgesScreen} from './gui/screens/badges-screen';
import {ErrorScreen} from './gui/screens/error-screen';
import {ExitQuestionScreen} from './gui/screens/exit-question-screen';
import {GameCompletedScreen} from './gui/screens/game-completed-screen';
import {MessageScreen} from './gui/screens/message-screen';
import {OverlayScreen} from './gui/screens/overlay-screen';
import {PlayerDeathScreen} from './gui/screens/player-death-screen';
import {PlayerFinalDeathScreen} from './gui/screens/player-final-death-screen';
import {WelcomeScreen} from './gui/screens/welcome-screen';
import {AnyLevelScene} from './scenes/any-level-scene';
import {WelcomeScene} from './scenes/welcome-scene';
import Center = Phaser.Scale.Center;

export const globalConfig = {
    debug: true
};
export const gameConfig: Phaser.Types.Core.GameConfig = {
    type: Phaser.AUTO,
    width: 1024 * 2,
    height: 768 * 2,
    backgroundColor: '#000000',
    parent: 'parent',
    dom: {
        behindCanvas: false,
        createContainer: true
    },
    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Center.CENTER_BOTH
    },
    input: {
        keyboard: true,
        gamepad: true,
        mouse: true,
        touch: true,
        activePointers: 5// allow mobile to use more than one
    },
    render: {
        pixelArt: false,
        antialias: true,
        antialiasGL: true
    },
    physics: {
        default: 'arcade',
        arcade: {
            debug: globalConfig.debug,
            gravity: {
                y: 1500
            }
        }
    },
    plugins: {
        global: [{
            key: 'rexVirtualJoystick',
            plugin: VirtualJoystickPlugin,
            start: true
        }]
    },
    audio: {

    },
    scene: [
        // the order of scenes must be as following:
        // 1. Welcome scene first
        // 2. Normal game scenes
        // 3. Gui screens
        WelcomeScene,
        AnyLevelScene,
        OverlayScreen,
        WelcomeScreen,
        PlayerFinalDeathScreen,
        PlayerDeathScreen,
        BadgeFoundScreen,
        BadgesScreen,
        BadgeInfoScreen,
        GameCompletedScreen,
        ExitQuestionScreen,
        ErrorScreen,
        MessageScreen
    ]
};
