import STATIC_BODY = Phaser.Physics.Arcade.STATIC_BODY;
import Rectangle = Phaser.GameObjects.Rectangle;
import StaticBody = Phaser.Physics.Arcade.StaticBody;

export class DeathCollider extends Rectangle {

    constructor(scene: Phaser.Scene, x: number, y: number, w: number, h: number) {
        super(scene, x, y, w, h);
        this.scene.physics.world.enable(this, STATIC_BODY);
        const body = this.body as StaticBody;
        body.debugBodyColor = 0xFF0000;
    }
}
