export const tileSize = 128;

export class Assets {
    public readonly player1: PlayerAssets = {
        idle: {
            frameW: 111,
            frameH: 165,
            bodyW: 94,
            bodyH: 132,
            offsetX: 8.5,
            offsetY: 10,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player1/idle',
            path: './assets/images/players/anim_player1_idle.png',
            fps: 16
        },
        idleBlink: {
            frameW: 111,
            frameH: 165,
            bodyW: 94,
            bodyH: 132,
            offsetX: 8.5,
            offsetY: 0,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player1/idle-blink',
            path: './assets/images/players/anim_player1_idle_blink.png',
            fps: 16
        },
        walk: {
            frameW: 134,
            frameH: 183,
            bodyW: 94,
            bodyH: 142,
            offsetX: 20,
            offsetY: 16,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player1/walk',
            path: './assets/images/players/anim_player1_walk.png',
            fps: 16
        },
        run: {
            frameW: 134,
            frameH: 183,
            bodyW: 94,
            bodyH: 142,
            offsetX: 20,
            offsetY: 16,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player1/run',
            path: './assets/images/players/anim_player1_walk.png',
            fps: 32
        },
        jump: {
            frameW: 136,
            frameH: 188,
            bodyW: 94,
            bodyH: 147,
            offsetX: 21,
            offsetY: 15,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player1/jump',
            path: './assets/images/players/anim_player1_jump.png',
            fps: 16
        },
        fall: {
            frameW: 150,
            frameH: 188,
            bodyW: 94,
            bodyH: 147,
            offsetX: 28,
            offsetY: 15,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player1/fall',
            path: './assets/images/players/anim_player1_fall.png',
            fps: 16
        },
        hurt: {
            frameW: 121,
            frameH: 173,
            bodyW: 94,
            bodyH: 138,
            offsetX: 13.5,
            offsetY: 11,
            originX: 0.5,
            originY: 0.87,
            framesCount: 12,
            name: 'player1/hurt',
            path: './assets/images/players/anim_player1_hurt.png',
            fps: 16
        },
        attack: {
            frameW: 137,
            frameH: 196,
            bodyW: 94,
            bodyH: 142,
            offsetX: 21.5,
            offsetY: 36,
            originX: 0.5,
            originY: 179 / 196,
            framesCount: 16,
            name: 'player1/attack',
            path: './assets/images/players/anim_player1_attack.png',
            fps: 16
        },
        death: {
            frameW: 231,
            frameH: 195,
            bodyW: 94,
            bodyH: 142,
            offsetX: 112,
            offsetY: 20,
            originX: 210 / 231,
            originY: 178 / 195,
            framesCount: 16,
            name: 'player1/death',
            path: './assets/images/players/anim_player1_death.png',
            fps: 16
        }
    };
    public readonly player2: PlayerAssets = {
        idle: {
            frameW: 110,
            frameH: 211,
            bodyW: 90,
            bodyH: 167,
            offsetX: 10,
            offsetY: 15,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player2/idle',
            path: './assets/images/players/anim_player2_idle.png',
            fps: 16
        },
        idleBlink: {
            frameW: 110,
            frameH: 211,
            bodyW: 90,
            bodyH: 182,
            offsetX: 10,
            offsetY: 0,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player2/idle-blink',
            path: './assets/images/players/anim_player2_idle_blink.png',
            fps: 16
        },
        walk: {
            frameW: 132,
            frameH: 230,
            bodyW: 90,
            bodyH: 179,
            offsetX: 21,
            offsetY: 20,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player2/walk',
            path: './assets/images/players/anim_player2_walk.png',
            fps: 16
        },
        run: {
            frameW: 132,
            frameH: 230,
            bodyW: 90,
            bodyH: 179,
            offsetX: 21,
            offsetY: 20,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player2/run',
            path: './assets/images/players/anim_player2_walk.png',
            fps: 32
        },
        jump: {
            frameW: 142,
            frameH: 243,
            bodyW: 90,
            bodyH: 195,
            offsetX: 26,
            offsetY: 15,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player2/jump',
            path: './assets/images/players/anim_player2_jump.png',
            fps: 16
        },
        fall: {
            frameW: 162,
            frameH: 242,
            bodyW: 90,
            bodyH: 194,
            offsetX: 36,
            offsetY: 15,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player2/fall',
            path: './assets/images/players/anim_player2_fall.png',
            fps: 16
        },
        hurt: {
            frameW: 135,
            frameH: 228,
            bodyW: 90,
            bodyH: 177,
            offsetX: 16,
            offsetY: 19,
            originX: 0.5,
            originY: 0.87,
            framesCount: 12,
            name: 'player2/hurt',
            path: './assets/images/players/anim_player2_hurt.png',
            fps: 16
        },
        attack: {
            frameW: 147,
            frameH: 234,
            bodyW: 90,
            bodyH: 182,
            offsetX: 21,
            offsetY: 20,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player2/attack',
            path: './assets/images/players/anim_player2_attack.png',
            fps: 16
        },
        death: {
            frameW: 285,
            frameH: 240,
            bodyW: 90,
            bodyH: 182,
            offsetX: 166,
            offsetY: 18,
            originX: 211 / 285,
            originY: 223 / 240,
            framesCount: 16,
            name: 'player2/death',
            path: './assets/images/players/anim_player2_death.png',
            fps: 16
        }
    };
    public readonly player3: PlayerAssets = {
        idle: {
            frameW: 110,
            frameH: 170,
            bodyW: 94,
            bodyH: 134,
            offsetX: 8.5,
            offsetY: 12,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player3/idle',
            path: './assets/images/players/anim_player3_idle.png',
            fps: 16
        },
        idleBlink: {
            frameW: 110,
            frameH: 170,
            bodyW: 94,
            bodyH: 134,
            offsetX: 8.5,
            offsetY: 12,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player3/idle-blink',
            path: './assets/images/players/anim_player3_idle_blink.png',
            fps: 16
        },
        walk: {
            frameW: 132,
            frameH: 188,
            bodyW: 94,
            bodyH: 147,
            offsetX: 19,
            offsetY: 15,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player3/walk',
            path: './assets/images/players/anim_player3_walk.png',
            fps: 16
        },
        run: {
            frameW: 132,
            frameH: 188,
            bodyW: 94,
            bodyH: 147,
            offsetX: 19,
            offsetY: 15,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player3/run',
            path: './assets/images/players/anim_player3_walk.png',
            fps: 32
        },
        jump: {
            frameW: 142,
            frameH: 200,
            bodyW: 94,
            bodyH: 157,
            offsetX: 24,
            offsetY: 16,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player3/jump',
            path: './assets/images/players/anim_player3_jump.png',
            fps: 16
        },
        fall: {
            frameW: 157,
            frameH: 200,
            bodyW: 94,
            bodyH: 154.5,
            offsetX: 31.5,
            offsetY: 18,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player3/fall',
            path: './assets/images/players/anim_player3_fall.png',
            fps: 16
        },
        hurt: {
            frameW: 128,
            frameH: 185,
            bodyW: 94,
            bodyH: 145,
            offsetX: 13.5,
            offsetY: 15,
            originX: 0.5,
            originY: 0.87,
            framesCount: 12,
            name: 'player3/hurt',
            path: './assets/images/players/anim_player3_hurt.png',
            fps: 16
        },
        attack: {
            frameW: 143,
            frameH: 191,
            bodyW: 94,
            bodyH: 153,
            offsetX: 21.5,
            offsetY: 20,
            originX: 0.5,
            originY: 179 / 196,
            framesCount: 16,
            name: 'player3/attack',
            path: './assets/images/players/anim_player3_attack.png',
            fps: 16
        },
        death: {
            frameW: 236,
            frameH: 199,
            bodyW: 94,
            bodyH: 140,
            offsetX: 116,
            offsetY: 22,
            originX: 159 / 231,
            originY: 178 / 195,
            framesCount: 16,
            name: 'player3/death',
            path: './assets/images/players/anim_player3_death.png',
            fps: 16
        }
    };
    public readonly player4: PlayerAssets = {
        idle: {
            frameW: 109,
            frameH: 158,
            bodyW: 70,
            bodyH: 128,
            offsetX: 18,
            offsetY: 8,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player4/idle',
            path: './assets/images/players/anim_player4_idle.png',
            fps: 16
        },
        idleBlink: {
            frameW: 109,
            frameH: 158,
            bodyW: 70,
            bodyH: 128,
            offsetX: 18,
            offsetY: 8,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player4/idle-blink',
            path: './assets/images/players/anim_player4_idle_blink.png',
            fps: 16
        },
        walk: {
            frameW: 132,
            frameH: 178,
            bodyW: 70,
            bodyH: 143.5,
            offsetX: 29,
            offsetY: 10,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player4/walk',
            path: './assets/images/players/anim_player4_walk.png',
            fps: 16
        },
        run: {
            frameW: 132,
            frameH: 178,
            bodyW: 70,
            bodyH: 143.5,
            offsetX: 29,
            offsetY: 10,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player4/run',
            path: './assets/images/players/anim_player4_walk.png',
            fps: 32
        },
        jump: {
            frameW: 123,
            frameH: 189,
            bodyW: 70,
            bodyH: 153,
            offsetX: 24.5,
            offsetY: 10,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player4/jump',
            path: './assets/images/players/anim_player4_jump.png',
            fps: 16
        },
        fall: {
            frameW: 145,
            frameH: 188,
            bodyW: 70,
            bodyH: 153,
            offsetX: 35.5,
            offsetY: 10,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player4/fall',
            path: './assets/images/players/anim_player4_fall.png',
            fps: 16
        },
        hurt: {
            frameW: 107,
            frameH: 174,
            bodyW: 70,
            bodyH: 140,
            offsetX: 15,
            offsetY: 10,
            originX: 0.5,
            originY: 0.87,
            framesCount: 12,
            name: 'player4/hurt',
            path: './assets/images/players/anim_player4_hurt.png',
            fps: 16
        },
        attack: {
            frameW: 132,
            frameH: 180,
            bodyW: 70,
            bodyH: 148,
            offsetX: 25,
            offsetY: 10,
            originX: 0.5,
            originY: 159 / 180,
            framesCount: 16,
            name: 'player4/attack',
            path: './assets/images/players/anim_player4_attack.png',
            fps: 16
        },
        death: {
            frameW: 225,
            frameH: 181,
            bodyW: 70,
            bodyH: 136,
            offsetX: 118,
            offsetY: 20,
            originX: 159 / 231,
            originY: 178 / 195,
            framesCount: 16,
            name: 'player4/death',
            path: './assets/images/players/anim_player4_death.png',
            fps: 16
        }
    };
    public readonly player5: PlayerAssets = {
        idle: {
            frameW: 109,
            frameH: 179,
            bodyW: 92,
            bodyH: 139,
            offsetX: 9,
            offsetY: 16,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player5/idle',
            path: './assets/images/players/anim_player5_idle.png',
            fps: 16
        },
        idleBlink: {
            frameW: 109,
            frameH: 179,
            bodyW: 92,
            bodyH: 139,
            offsetX: 9,
            offsetY: 16,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player5/idle-blink',
            path: './assets/images/players/anim_player5_idle_blink.png',
            fps: 16
        },
        walk: {
            frameW: 132,
            frameH: 191,
            bodyW: 92,
            bodyH: 145,
            offsetX: 20.5,
            offsetY: 21,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player5/walk',
            path: './assets/images/players/anim_player5_walk.png',
            fps: 16
        },
        run: {
            frameW: 132,
            frameH: 191,
            bodyW: 92,
            bodyH: 145,
            offsetX: 20.5,
            offsetY: 21,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player5/run',
            path: './assets/images/players/anim_player5_walk.png',
            fps: 32
        },
        jump: {
            frameW: 142,
            frameH: 204,
            bodyW: 92,
            bodyH: 152.5,
            offsetX: 25,
            offsetY: 24,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player5/jump',
            path: './assets/images/players/anim_player5_jump.png',
            fps: 16
        },
        fall: {
            frameW: 157,
            frameH: 203,
            bodyW: 92,
            bodyH: 151.5,
            offsetX: 33,
            offsetY: 24,
            originX: 0.5,
            originY: 0.87,
            framesCount: 16,
            name: 'player5/fall',
            path: './assets/images/players/anim_player5_fall.png',
            fps: 16
        },
        hurt: {
            frameW: 128,
            frameH: 188,
            bodyW: 92,
            bodyH: 139,
            offsetX: 13.5,
            offsetY: 20,
            originX: 0.5,
            originY: 0.87,
            framesCount: 12,
            name: 'player5/hurt',
            path: './assets/images/players/anim_player5_hurt.png',
            fps: 16
        },
        attack: {
            frameW: 144,
            frameH: 194,
            bodyW: 92,
            bodyH: 149.5,
            offsetX: 21.5,
            offsetY: 24,
            originX: 0.5,
            originY: 0.9,
            framesCount: 16,
            name: 'player5/attack',
            path: './assets/images/players/anim_player5_attack.png',
            fps: 16
        },
        death: {
            frameW: 240,
            frameH: 200,
            bodyW: 92,
            bodyH: 138.5,
            offsetX: 121.5,
            offsetY: 27,
            originX: 0.72,
            originY: 0.90,
            framesCount: 16,
            name: 'player5/death',
            path: './assets/images/players/anim_player5_death.png',
            fps: 16
        }
    };
    public readonly drillEnemy: DrillEnemyAsset = {
        single: {
            frameW: 69,
            frameH: 87,
            bodyW: 52,
            bodyH: 83,
            offsetX: 8,
            offsetY: 0,
            originX: 0.5,
            originY: 1.0,
            framesCount: 48,
            name: 'enemy-drill/single',
            path: './assets/images/anim_drill_single.png',
            fps: 16
        },
        dual: {
            frameW: 134,
            frameH: 88,
            bodyW: 117,
            bodyH: 83,
            offsetX: 8,
            offsetY: 0,
            originX: 0.5,
            originY: 1.0,
            framesCount: 48,
            name: 'enemy-drill/dual',
            path: './assets/images/anim_drill_dual.png',
            fps: 16
        }
    };
    public readonly chimneyEnemy: ChimneyEnemyAsset = {
        idle: {
            frameW: 265,
            frameH: 411,
            bodyW: 150,
            bodyH: 135,
            offsetX: 40,
            offsetY: 275,
            originX: 0.5,
            originY: 1.0,
            framesCount: 25,
            name: 'enemy-chimney/idle',
            path: './assets/images/anim_chimney_idle.png',
            fps: 10
        },
        attack: {
            frameW: 265,
            frameH: 411,
            bodyW: 145,
            bodyH: 145,
            offsetX: 40,
            offsetY: 265,
            originX: 0.5,
            originY: 1.0,
            framesCount: 24,
            name: 'enemy-chimney/attack',
            path: './assets/images/anim_chimney_attack.png',
            fps: 10
        }
    };
    public readonly ammo: AmmunitionAsset = {
        soot: {
            frameW: 35,
            frameH: 33,
            bodyW: 35,
            bodyH: 33,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 0.5,
            framesCount: 10,
            name: 'ammo/soot',
            path: './assets/images/anim_soot_amo.png',
            fps: 10
        },
        blueFlame: {
            frameW: 60,
            frameH: 36,
            bodyW: 60,
            bodyH: 36,
            offsetX: 0,
            offsetY: 0,
            originX: 1.0,
            originY: 0.5,
            framesCount: 10,
            name: 'ammo/blue-flame',
            path: './assets/images/anim_blue_flame_amo.png',
            fps: 10
        },
        fire: {
            frameW: 53,
            frameH: 33,
            bodyW: 53,
            bodyH: 33,
            offsetX: 0,
            offsetY: 0,
            originX: 1.0,
            originY: 0.5,
            framesCount: 10,
            name: 'ammo/fire',
            path: './assets/images/anim_fire_amo.png',
            fps: 10
        },
        toxic: {
            frameW: 53,
            frameH: 49,
            bodyW: 53,
            bodyH: 49,
            offsetX: 0,
            offsetY: 0,
            originX: 1.0,
            originY: 0.5,
            framesCount: 10,
            name: 'ammo/toxic',
            path: './assets/images/anim_toxic_amo.png',
            fps: 10
        }
    };
    public readonly obstacles: ObstaclesAsset = {
        spikesBig: {
            frameW: 172,
            frameH: 248,
            bodyW: 132,
            bodyH: 248,
            offsetX: 20,
            offsetY: 0,
            originX: 0.5,
            originY: 1.0,
            framesCount: 10,
            name: 'obstacles/spikes-big',
            path: './assets/images/anim_spike_obstacle.png',
            fps: 20
        },
        spikesNormal: {
            frameW: 172,
            frameH: 248,
            bodyW: 132,
            bodyH: 176,
            offsetX: 20,
            offsetY: 72,
            originX: 0.5,
            originY: 1.0,
            framesCount: 7,
            name: 'obstacles/spikes-normal',
            path: './assets/images/anim_spike_obstacle.png',
            fps: 15
        },
        spikesSmall: {
            frameW: 172,
            frameH: 248,
            bodyW: 132,
            bodyH: 114,
            offsetX: 20,
            offsetY: 134,
            originX: 0.5,
            originY: 1.0,
            framesCount: 4,
            name: 'obstacles/spikes-small',
            path: './assets/images/anim_spike_obstacle.png',
            fps: 12
        }
    };
    public readonly items: ItemsAsset = {
        badge: {
            frameW: 60,
            frameH: 106,
            bodyW: 50,
            bodyH: 106,
            offsetX: 5,
            offsetY: 0,
            originX: 0.5,
            originY: 1.0,
            framesCount: 10,
            name: 'items/badge',
            path: './assets/images/anim_badge_item.png',
            fps: 10
        },
        life: {
            frameW: 89,
            frameH: 74,
            bodyW: 89,
            bodyH: 74,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 1.0,
            framesCount: 1,
            name: 'items/life',
            path: './assets/images/life_item.png',
            fps: 1
        },
        power: {
            frameW: 58,
            frameH: 82,
            bodyW: 58,
            bodyH: 82,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 1.0,
            framesCount: 1,
            name: 'items/power',
            path: './assets/images/power_item.png',
            fps: 1
        }
    };
    public readonly portal: PortalAssets = {
        default: {
            frameW: 137,
            frameH: 167,
            bodyW: 137,
            bodyH: 167,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 0,
            framesCount: 16,
            name: 'portal/default',
            path: './assets/images/anim_portal.png',
            fps: 16
        }
    };
    public readonly gui: GuiAssets = {
        okBtn: {
            normal: 2, // Frame index
            hover: 16, // Frame index
            assetName: 'gui/elements'
        },
        cancelBtn: {
            normal: 5,
            hover: 19,
            assetName: 'gui/elements'
        },
        retryBtn: {
            normal: 3,
            hover: 17,
            assetName: 'gui/elements'
        },
        backBtn: {
            normal: 4,
            hover: 18,
            assetName: 'gui/elements'
        },
        settingsBtn: {
            normal: 1,
            hover: 15,
            assetName: 'gui/elements'
        },
        badgeBtn: {
            normal: 0,
            hover: 14,
            assetName: 'gui/elements'
        },
        soundOnBtn: {
            normal: 11,
            hover: 25,
            assetName: 'gui/elements'
        },
        soundOffBtn: {
            normal:12,
            hover: 26,
            assetName: 'gui/elements'
        },
        controlsBtn: {
            normal:13,
            hover: 27,
            assetName: 'gui/elements'
        },

        fullScreenOnBtn: {
            name: 'gui/buttons/full-screen-on',
            path: './assets/images/gui/btn-fullscreen-on.png',
            framesCount: 2,
            frameW: 168,
            frameH: 168
        },
        fullScreenOffBtn: {
            name: 'gui/buttons/full-screen-off',
            path: './assets/images/gui/btn-fullscreen-off.png',
            framesCount: 2,
            frameW: 168,
            frameH: 168
        },

        textStartBtn: {
            name: 'gui/text-buttons/start',
            path: './assets/images/gui/btn-text-start.png',
            framesCount: 2,
            frameW: 360,
            frameH: 190
        },
        textContinueBtn: {
            name: 'gui/text-buttons/continue',
            path: './assets/images/gui/btn-text-continue.png',
            framesCount: 2,
            frameW: 360,
            frameH: 190
        },
        textAboutBtn: {
            name: 'gui/text-buttons/about',
            path: './assets/images/gui/btn-text-about.png',
            framesCount: 2,
            frameW: 360,
            frameH: 190
        },
        textControlsBtn: {
            name: 'gui/text-buttons/controls',
            path: './assets/images/gui/btn-text-controls.png',
            framesCount: 2,
            frameW: 360,
            frameH: 190
        },
        textPlayersBtn: {
            name: 'gui/text-buttons/players',
            path: './assets/images/gui/btn-text-players.png',
            framesCount: 2,
            frameW: 360,
            frameH: 190
        },

        textVitodensSmallBtn: {
            name: 'gui/text-small-buttons/vitodens',
            path: './assets/images/gui/btn-text-small-vitodens.png',
            framesCount: 2,
            frameW: 280,
            frameH: 114
        },
        textVitodensFSmallBtn: {
            name: 'gui/text-small-buttons/vitodens-f',
            path: './assets/images/gui/btn-text-small-vitodens-f.png',
            framesCount: 2,
            frameW: 280,
            frameH: 114
        },
        textVitocalSmallBtn: {
            name: 'gui/text-small-buttons/vitocal',
            path: './assets/images/gui/btn-text-small-vitocal.png',
            framesCount: 2,
            frameW: 280,
            frameH: 114
        },
        textVitovoltSmallBtn: {
            name: 'gui/text-small-buttons/vitovolt',
            path: './assets/images/gui/btn-text-small-vitovolt.png',
            framesCount: 2,
            frameW: 280,
            frameH: 114
        },
        textVitocellSmallBtn: {
            name: 'gui/text-small-buttons/vitocell',
            path: './assets/images/gui/btn-text-small-vitocell.png',
            framesCount: 2,
            frameW: 280,
            frameH: 114
        },

        checkmark: {
            name: 'gui/checkmark',
            path: './assets/images/gui/checkmark.png',
            framesCount: 1,
            frameW: 128,
            frameH: 128
        },

        difficultyLevelEasy: {
            name: 'gui/difficulty-easy',
            path: './assets/images/gui/difficulty-easy.png',
            framesCount: 1,
            frameW: 200,
            frameH: 256
        },
        difficultyLevelNormal: {
            name: 'gui/difficulty-normal',
            path: './assets/images/gui/difficulty-normal.png',
            framesCount: 1,
            frameW: 200,
            frameH: 256
        },
        difficultyLevelHard: {
            name: 'gui/difficulty-hard',
            path: './assets/images/gui/difficulty-hard.png',
            framesCount: 1,
            frameW: 200,
            frameH: 256
        },

        filePdf: {
            name: 'gui/files/pdf',
            path: './assets/images/gui/file-pdf.png',
            framesCount: 1,
            frameW: 136,
            frameH: 168
        },

        itemSlot: {
            normal: 6,
            locked: 20,
            assetName: 'gui/elements'
        },
        playerWithHint: {
            exit: 0,
            continue: 1,
            assetName: 'gui/player-exit-continue'
        },
        badge: {
            friendsAndFamily: 7,
            heatingCurveAssistant: 8,
            surpriseGift: 9,
            freeHeatingService: 21,
            smartThermostat: 22,
            geofencing: 23,
            assetName: 'gui/elements'
        },
        thermostatImage: {
            frame: 24,
            assetName: 'gui/elements'
        },
        life: {
            frame: 10,
            assetName: 'gui/elements'
        },
        elements: {
            frameW: 168,
            frameH: 168,
            framesCount: 29,
            name: 'gui/elements',
            path: './assets/images/gui/gui-elements.png'
        },
        badgeBag: {
            frameW: 234,
            frameH: 324,
            framesCount: 1,
            name: 'gui/badge-bag',
            path: './assets/images/gui/gui-badge.png'
        },
        playerDeath: {
            frameW: 280,
            frameH: 184,
            framesCount: 1,
            name: 'gui/player-death',
            path: './assets/images/gui/gui-player-death.png'
        },
        congratulations: {
            frameW: 165,
            frameH: 166,
            framesCount: 1,
            name: 'gui/congratulations',
            path: './assets/images/gui/gui-congratulations.png'
        },
        playerExitContinue: {
            frameW: 256,
            frameH: 256,
            framesCount: 2,
            name: 'gui/player-exit-continue',
            path: './assets/images/gui/gui-player-exit-continue.png'
        },
        windowBig: {
            frameW: 752,
            frameH: 720,
            framesCount: 1,
            name: 'gui/window-big',
            path: './assets/images/gui/gui-window-big.png'
        },
        windowWide: {
            frameW: 901,
            frameH: 721,
            framesCount: 1,
            name: 'gui/window-wide',
            path: './assets/images/gui/gui-window-wide.png'
        },
        windowLarge: {
            frameW: 1140,
            frameH: 960,
            framesCount: 1,
            name: 'gui/window-large',
            path: './assets/images/gui/gui-window-large.png'
        },
        windowUltraWide: {
            frameW: 1140,
            frameH: 720,
            framesCount: 1,
            name: 'gui/window-ultra-wide',
            path: './assets/images/gui/gui-window-ultra-wide.png'
        },
        windowContentGroup: {
            frameW: 468,
            frameH: 376,
            framesCount: 1,
            name: 'gui/window-content',
            path: './assets/images/gui/gui-window-content-group.png'
        },
        windowContentGroupSmall: {
            frameW: 251,
            frameH: 299,
            framesCount: 1,
            name: 'gui/window-content-small',
            path: './assets/images/gui/gui-window-content-group-small.png'
        },
        windowContentGroupRow: {
            frameW: 461,
            frameH: 89,
            framesCount: 1,
            name: 'gui/window-content-row',
            path: './assets/images/gui/gui-window-content-group-row.png'
        },
        windowRect: {
            frameW: 820,
            frameH: 568,
            framesCount: 1,
            name: 'gui/window-rect',
            path: './assets/images/gui/gui-window-rect-empty.png'
        },
        windowItemCollected: {
            frameW: 820,
            frameH: 568,
            framesCount: 1,
            name: 'gui/window-item-collected',
            path: './assets/images/gui/gui-window-rect-item-collected.png'
        },
        windowSmall: {
            frameW: 752,
            frameH: 588,
            framesCount: 1,
            name: 'gui/window-small',
            path: './assets/images/gui/gui-window-small.png'
        },
        windowMenu: {
            frameW: 720,
            frameH: 960,
            framesCount: 1,
            name: 'gui/window-menu',
            path: './assets/images/gui/gui-window-menu.png'
        },
        controls: {
            frameW: 432,
            frameH: 293,
            framesCount: 1,
            name: 'gui/controls',
            path: './assets/images/gui/gui-controls-window-content.png'
        },
        hudAmo: {
            frameW: 400,
            frameH: 120,
            framesCount: 1,
            name: 'gui/hud-amo',
            path: './assets/images/gui/hud-amo.png'
        },
        hudAmoItem: {
            frameW: 24,
            frameH: 40,
            framesCount: 2,
            name: 'gui/hud-amo-item',
            path: './assets/images/gui/hud-amo-items.png'
        },
        hudLife: {
            frameW: 298,
            frameH: 120,
            framesCount: 1,
            name: 'gui/hud-life',
            path: './assets/images/gui/hud-lives.png'
        },
        hudLifeItem: {
            frameW: 40,
            frameH: 40,
            framesCount: 2,
            name: 'gui/hud-life-item',
            path: './assets/images/gui/hud-lives-items.png'
        },
        hudPower: {
            frameW: 298,
            frameH: 120,
            framesCount: 1,
            name: 'gui/hud-power',
            path: './assets/images/gui/hud-power.png'
        },
        hudBossPower: {
            frameW: 298,
            frameH: 120,
            framesCount: 1,
            name: 'gui/hud-boss-power',
            path: './assets/images/gui/hud-boss.png'
        },
        hudPowerItem: {
            frameW: 168,
            frameH: 36,
            framesCount: 11,
            name: 'gui/hud-power-item',
            path: './assets/images/gui/hud-power-items.png'
        },
        welcomeScreenTitle: {
            frameW: 1648,
            frameH: 360,
            framesCount: 1,
            name: 'gui/welcome-screen-title',
            path: './assets/images/gui/welcome-screen-title.png'
        },

        padController: {
            frameW: 251,
            frameH: 251,
            framesCount: 1,
            name: 'gui/pad-controller',
            path: './assets/images/gui/controller.png'
        },
        padControllerThumb: {
            frameW: 101,
            frameH: 101,
            framesCount: 1,
            name: 'gui/pad-controller-thumb',
            path: './assets/images/gui/controller-thumb.png'
        },
        padFireButton: {
            frameW: 132,
            frameH: 132,
            framesCount: 2,
            name: 'gui/pad-fire-button',
            path: './assets/images/gui/fire-button.png'
        },
        padJumpButton: {
            frameW: 132,
            frameH: 132,
            framesCount: 2,
            name: 'gui/pad-jump-button',
            path: './assets/images/gui/jump-button.png'
        },
    };
    public readonly effects: EffectsAssets = {
        effect1: {
            frameW: 277,
            frameH: 263,
            bodyW: 277,
            bodyH: 263,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 0.5,
            framesCount: 9,
            name: 'effects/1',
            path: './assets/images/effects/effect_1.png',
            fps: 12
        },
        effect2: {
            frameW: 207,
            frameH: 223,
            bodyW: 207,
            bodyH: 223,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 0.5,
            framesCount: 9,
            name: 'effects/2',
            path: './assets/images/effects/effect_2.png',
            fps: 12
        },
        effect3: {
            frameW: 279,
            frameH: 275,
            bodyW: 279,
            bodyH: 275,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 0.5,
            framesCount: 9,
            name: 'effects/3',
            path: './assets/images/effects/effect_3.png',
            fps: 12
        },
        effect4: {
            frameW: 306,
            frameH: 273,
            bodyW: 306,
            bodyH: 273,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 0.5,
            framesCount: 9,
            name: 'effects/4',
            path: './assets/images/effects/effect_4.png',
            fps: 12
        },
        effect5: {
            frameW: 272,
            frameH: 273,
            bodyW: 272,
            bodyH: 273,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 0.5,
            framesCount: 9,
            name: 'effects/5',
            path: './assets/images/effects/effect_5.png',
            fps: 12
        },
        effect6: {
            frameW: 270,
            frameH: 303,
            bodyW: 270,
            bodyH: 303,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 1.0,
            framesCount: 9,
            name: 'effects/6',
            path: './assets/images/effects/effect_6.png',
            fps: 12
        },
        effect7: {
            frameW: 285,
            frameH: 214,
            bodyW: 285,
            bodyH: 214,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 1.0,
            framesCount: 9,
            name: 'effects/7',
            path: './assets/images/effects/effect_7.png',
            fps: 12
        }
    };
    public readonly boss: BossAsset = {
        idle: {
            frameW: 190,
            frameH: 298,
            bodyW: 130,
            bodyH: 278,
            offsetX: 45,
            offsetY: 20,
            originX: 0.5,
            originY: 1,
            framesCount: 16,
            name: 'boss/idle',
            path: './assets/images/bosses/anim_boss_angry.png',
            fps: 16
        },
        idleAngry: {
            frameW: 190,
            frameH: 298,
            bodyW: 130,
            bodyH: 278,
            offsetX: 45,
            offsetY: 20,
            originX: 0.5,
            originY: 1,
            framesCount: 38,
            name: 'boss/idle-angry',
            path: './assets/images/bosses/anim_boss_angry_2.png',
            fps: 16
        },
        smoke: {
            frameW: 282,
            frameH: 465,
            bodyW: 282,
            bodyH: 465,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 0.5,
            framesCount: 32,
            name: 'boss/smoke',
            path: './assets/images/bosses/anim_toxic_smoke.png',
            fps: 16
        },
        fireplace: {
            frameW: 133,
            frameH: 128,
            bodyW: 133,
            bodyH: 128,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 0.5,
            framesCount: 8,
            name: 'boss/fireplace',
            path: './assets/images/bosses/anim_boss_fireplace.png',
            fps: 16
        }
    };
    public readonly checkpoint: CheckpointAsset = {
        activate: {
            frameW: 82,
            frameH: 182,
            bodyW: 70,
            bodyH: 180,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 1.0,
            framesCount: 12,
            name: 'checkpoints/activate',
            path: './assets/images/checkpoint_activate.png',
            fps: 12
        },
        active: {
            frameW: 82,
            frameH: 182,
            bodyW: 70,
            bodyH: 180,
            offsetX: 0,
            offsetY: 0,
            originX: 0.5,
            originY: 1.0,
            framesCount: 12,
            name: 'checkpoints/active',
            path: './assets/images/checkpoint_active.png',
            fps: 12
        }
    }
}

export interface PlayerAssets extends MultiAsset {
    idle: AssetAnimSpriteInfo;
    idleBlink: AssetAnimSpriteInfo;
    walk: AssetAnimSpriteInfo;
    run: AssetAnimSpriteInfo;
    jump: AssetAnimSpriteInfo;
    fall: AssetAnimSpriteInfo;
    hurt: AssetAnimSpriteInfo;
    attack: AssetAnimSpriteInfo;
    death: AssetAnimSpriteInfo;
}

export interface DrillEnemyAsset extends MultiAsset {
    single: AssetAnimSpriteInfo;
    dual: AssetAnimSpriteInfo;
}

export interface ChimneyEnemyAsset extends MultiAsset {
    idle: AssetAnimSpriteInfo;
    attack: AssetAnimSpriteInfo;
}

export interface AmmunitionAsset extends MultiAsset {
    soot: AssetAnimSpriteInfo;
    blueFlame: AssetAnimSpriteInfo;
    fire: AssetAnimSpriteInfo;
    toxic: AssetAnimSpriteInfo;
}

export interface ObstaclesAsset extends MultiAsset {
    spikesBig: AssetAnimSpriteInfo;
    spikesNormal: AssetAnimSpriteInfo;
    spikesSmall: AssetAnimSpriteInfo;
}

export interface ItemsAsset extends MultiAsset {
    badge: AssetAnimSpriteInfo;
    life: AssetAnimSpriteInfo;
    power: AssetAnimSpriteInfo;
}

export interface CheckpointAsset extends MultiAsset {
    activate: AssetAnimSpriteInfo;
    active: AssetAnimSpriteInfo;
}

export interface BossAsset extends MultiAsset {
    idle: AssetAnimSpriteInfo;
    idleAngry: AssetAnimSpriteInfo;
    smoke: AssetAnimSpriteInfo;
    fireplace: AssetAnimSpriteInfo;
}

export interface GuiAssets {
    okBtn: GuiButtonInfo;
    cancelBtn: GuiButtonInfo;
    retryBtn: GuiButtonInfo;
    backBtn: GuiButtonInfo;
    settingsBtn: GuiButtonInfo;
    badgeBtn: GuiButtonInfo;
    soundOnBtn: GuiButtonInfo;
    soundOffBtn: GuiButtonInfo;
    controlsBtn: GuiButtonInfo;

    fullScreenOnBtn: AssetSpriteInfo;
    fullScreenOffBtn: AssetSpriteInfo;

    textStartBtn: AssetSpriteInfo;
    textContinueBtn: AssetSpriteInfo;
    textAboutBtn: AssetSpriteInfo;
    textControlsBtn: AssetSpriteInfo;
    textPlayersBtn: AssetSpriteInfo;

    textVitodensSmallBtn: AssetSpriteInfo;
    textVitodensFSmallBtn: AssetSpriteInfo;
    textVitocalSmallBtn: AssetSpriteInfo;
    textVitovoltSmallBtn: AssetSpriteInfo;
    textVitocellSmallBtn: AssetSpriteInfo;

    difficultyLevelEasy: AssetSpriteInfo;
    difficultyLevelNormal: AssetSpriteInfo;
    difficultyLevelHard: AssetSpriteInfo;

    checkmark: AssetSpriteInfo;
    filePdf: AssetSpriteInfo;

    itemSlot: {
        normal: number;
        locked: number;
        assetName: string;
    }
    badge: {
        friendsAndFamily: number;
        heatingCurveAssistant: number;
        surpriseGift: number;
        freeHeatingService: number;
        smartThermostat: number;
        geofencing: number;
        assetName: string;
    },
    thermostatImage: {
        frame: number;
        assetName: string;
    },
    life: {
        frame: number;
        assetName: string;
    },
    playerWithHint: {
        exit: number;
        continue: number;
        assetName: string;
    },
    elements: AssetSpriteInfo;
    badgeBag: AssetSpriteInfo;
    playerDeath: AssetSpriteInfo;
    congratulations: AssetSpriteInfo;
    playerExitContinue: AssetSpriteInfo;
    windowLarge: AssetSpriteInfo;
    windowWide: AssetSpriteInfo;
    windowUltraWide: AssetSpriteInfo;
    windowBig: AssetSpriteInfo;
    windowContentGroup: AssetSpriteInfo;
    windowContentGroupSmall: AssetSpriteInfo;
    windowContentGroupRow: AssetSpriteInfo;
    windowRect: AssetSpriteInfo;
    windowItemCollected: AssetSpriteInfo;
    windowSmall: AssetSpriteInfo;
    windowMenu: AssetSpriteInfo;
    controls: AssetSpriteInfo;
    hudAmo: AssetSpriteInfo;
    hudAmoItem: AssetSpriteInfo;
    hudLife: AssetSpriteInfo;
    hudLifeItem: AssetSpriteInfo;
    hudPower: AssetSpriteInfo;
    hudBossPower: AssetSpriteInfo;
    hudPowerItem: AssetSpriteInfo;
    welcomeScreenTitle: AssetSpriteInfo;
    padController: AssetSpriteInfo;
    padControllerThumb: AssetSpriteInfo;
    padJumpButton: AssetSpriteInfo;
    padFireButton: AssetSpriteInfo;
}

export interface EffectsAssets extends MultiAsset {
    effect1: AssetAnimSpriteInfo;
    effect2: AssetAnimSpriteInfo;
    effect3: AssetAnimSpriteInfo;
    effect4: AssetAnimSpriteInfo;
    effect5: AssetAnimSpriteInfo;
    effect6: AssetAnimSpriteInfo;
    effect7: AssetAnimSpriteInfo;
}

export interface PortalAssets extends MultiAsset {
    default: AssetAnimSpriteInfo;
}

export interface AssetAnimSpriteInfo extends AssetSpriteInfo {
    bodyW: number;
    bodyH: number;
    offsetX: number;
    offsetY: number;
    originX: number;
    originY: number;
    fps: number;
}

export interface AssetSpriteInfo {
    frameW: number;
    frameH: number;
    framesCount: number;
    name: string;
    path: string;
}

export interface GuiButtonInfo {
    normal: number;
    hover: number;
    assetName: string
}

export interface Asset {
    key: string;
    filePath: string;
}

export interface MultiAsset {
    [key: string]: AssetAnimSpriteInfo;
}

export const ASSETS = new Assets();

