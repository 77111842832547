import {boss1Scene} from './definitions/boss-1.scene';
import {demoScene} from './definitions/demo.scene';
import {level3Scene} from './definitions/level-3.scene';
import {level4Scene} from './definitions/level-4.scene';
import {SceneData} from './definitions/scenes-models';
import {level1Scene} from './definitions/level-1.scene';
import {level2Scene} from './definitions/level-2.scene';

export const SCENES: { [sceneName: string]: SceneData } = {
    [level1Scene.sceneName]: level1Scene,
    [level2Scene.sceneName]: level2Scene,
    [level3Scene.sceneName]: level3Scene,
    [level4Scene.sceneName]: level4Scene,
    [boss1Scene.sceneName]: boss1Scene,
    [demoScene.sceneName]: demoScene
};

// The scene which is started when a user clicks START button on welcome screen.
export const START_SCENE = level1Scene.sceneName;
