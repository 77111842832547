import {GlobalObjects} from './global-objects';

export class TimeHelper {
    public static getGameDurationTimeString(): string {
        const currentTime = (new Date()).getTime();
        const startTime = GlobalObjects.gameStartedTime.getTime();
        const diffMillis = currentTime - startTime;
        const timeDate = new Date(diffMillis);
        return `${this.formatNum(timeDate.getUTCHours())}:${this.formatNum(timeDate.getUTCMinutes())}:${this.formatNum(timeDate.getUTCSeconds())}:${this.formatMillis(timeDate.getUTCMilliseconds())}`;
    }

    public static getGameDurationTime(): number {
        const currentTime = (new Date()).getTime();
        const startTime = GlobalObjects.gameStartedTime.getTime();
        return currentTime - startTime;
    }

    private static formatNum(value: number): string {
        return value <10 ? `0${value}` : `${value}`;
    }

    private static formatMillis(value: number): string {
        return value < 10 ? `00${value}` : (value < 100 ? `0${value}` : `${value}`);
    }
}
