import {ASSETS, BossAsset} from '../assets';
import { POWER_VALUE } from '../constants';
import {
    EVENT_BOSS_KILLED,
    EVENT_BOSS_POWER_UPDATE, EVENT_GAME_COMPLETED, EVENT_SHOW_GAME_COMPLETED,
    GlobalEvents
} from '../helpers/global-events';
import {GlobalObjects} from '../helpers/global-objects';
import {globalConfig} from '../config';
import { GameDifficultyLevel } from '../map/map-helper';
import {AmmoSprite, AmmoType} from './ammo-sprite';
import {GameSpriteHelper} from './game-sprite-helper';
import {InteractiveObjectInterface} from './interactive-object-interface';
import {Obstacle} from './obstacle-base';
import BaseSound = Phaser.Sound.BaseSound;
import Image = Phaser.GameObjects.Image;
import Animation = Phaser.Animations.Animation;
import TimerEvent = Phaser.Time.TimerEvent;

enum States {
    IDLE = 0,
    ANGRY = 1,
    DIE
}

export class BossSprite extends Obstacle implements InteractiveObjectInterface {
    private readonly toxicShots: number; // how many toxic shoots before fire shoot
    private readonly shootingInterval: number; // how often boss shuts the player
    private toxicShootsCounter: number = 0;
    private readonly totalPower: number; // a total power of the boss
    private readonly closeDistance: number; // the distance when boss is waked up
    private debugText: Phaser.GameObjects.Text;
    private asset: BossAsset = ASSETS.boss;
    private angryTimer: TimerEvent;
    private shootingTimer: TimerEvent;
    public body: Phaser.Physics.Arcade.Body;
    private ammo: Phaser.GameObjects.Group;
    private bounds: Phaser.Geom.Rectangle;
    private bossArtifacts: Phaser.GameObjects.Group;
    private grate: Image;
    private globalEvents: GlobalEvents = GlobalEvents.resolve();
    private isDead: boolean = false;
    private isActivated: boolean = false;
    public readonly hurtScore: number;
    public power: number;
    private readonly player: Phaser.Physics.Arcade.Sprite;
    private isInCamRange: boolean;
    private dangerSound: BaseSound;
    private destroySound: BaseSound;

    get isCloseToPlayer(): boolean {
        return this.player ? this.isInCamRange && (Math.abs(this.player.x - this.x) < this.closeDistance) : false;
    }

    constructor(scene: Phaser.Scene, x: number, y: number) {
        super(scene, x, y - 100, 'boss');
        
        this.player = GlobalObjects.player;
        // configure BOSS for difficulty levels
        switch (GlobalObjects.difficultyLevel) {
            case GameDifficultyLevel.easy:
                this.shootingInterval = 10000;
                this.totalPower = 50;
                this.hurtScore = POWER_VALUE * 0.7; // when we touch the boss, we lose half of the power
                this.toxicShots = 3;
                this.closeDistance = 600;
                break;
            
            case GameDifficultyLevel.normal:
                this.shootingInterval = 6000;
                this.totalPower = 200;
                this.hurtScore = POWER_VALUE; // when we touch the boss we die
                this.toxicShots = 3;
                this.closeDistance = 800;
                break;
            
            case GameDifficultyLevel.hard:
                this.shootingInterval = 4000;
                this.totalPower = 300;
                this.hurtScore = POWER_VALUE; // when we touch the boss we die
                this.toxicShots = 2;
                this.closeDistance = 800;
                break;
        }
        this.power = this.totalPower;

        this.scene.add.image(this.x + 20, this.y + 80, 'map/images/boss-base');
        this.scene.anims.create({
            key: 'boss/fireplace',
            repeat: -1,
            frames: this.asset.fireplace.name,
            frameRate: this.asset.fireplace.fps
        });
        this.scene.anims.create({
            key: 'boss/smoke',
            repeat: -1,
            frames: ASSETS.boss.smoke.name,
            frameRate: ASSETS.boss.smoke.fps
        });

        this.bossArtifacts = this.scene.add.group(
            [
                this.scene.add.sprite(this.x - 49, this.y + 65, 'boss/fireplace').play('boss/fireplace'),
                this.scene.add.sprite(this.x + 40, this.y - 490, 'boss/smoke').play('boss/smoke').setDepth(2)
            ]
        );
        this.grate = this.scene.add.image(this.x - 49, this.y + 60, 'map/images/fireplace-grate');
        this.bossArtifacts.add(this.grate);


        if (globalConfig.debug) {
            this.debugText = this.scene.add.text(x - 30, y - 420, '');
            this.debugText.setDepth(999);
        }

        this.scene.add.existing(this);
        this.scene.physics.world.enable(this);

        this.body
            .setAllowGravity(false)
            .setCollideWorldBounds(true)
            .setImmovable(true);

        this.dangerSound = this.scene.sound.add('music/danger', {loop: true, volume: 0.2});
        this.destroySound = this.scene.sound.add('music/destroy', {loop: false, volume: 0.4});

        this.ammo = this.scene.physics.add.group({
            allowGravity: false,
            mass: 0.00000001
        });
        this.scene.physics.add.collider(this.ammo, this.player, (player: any, flame: any) => {
            (<AmmoSprite>flame).killAmo();
            (<InteractiveObjectInterface>player).hurt((<InteractiveObjectInterface>flame).hurtScore);
        });

        
        this.scene.anims.create({
            key: 'boss/idle',
            repeat: -1,
            frames: this.asset.idle.name,
            frameRate: this.asset.idle.fps
        });
        this.scene.anims.create({
            key: 'boss/idle-angry',
            repeat: 0,
            frames: this.asset.idleAngry.name,
            frameRate: this.asset.idleAngry.fps
        });
        this.scene.anims.create({
            key: 'boss/kill',
            repeat: 0,
            frames: ASSETS.effects.effect6.name,
            frameRate: ASSETS.effects.effect6.fps
        });

        // animations complete events
        this.on(Phaser.Animations.Events.ANIMATION_COMPLETE, (animation: Animation) => {
            if (animation.key === 'boss/idle-angry') {
                this.setState(States.IDLE);
                this.angryTimer.paused = false;
            }
        }, this);

        this.on(Phaser.Animations.Events.ANIMATION_COMPLETE, (animation: Animation) => {
            if (animation.key === 'boss/kill') {
                this.setAlpha(0);
                this.setScale(0)
                this.globalEvents.emit(EVENT_BOSS_KILLED);
            }
        }, this);


        this.setState(States.IDLE);
        this.bounds = this.getBounds();
        this.createTimers();
    }

    public preUpdate(time: number, delta: number): void {
        this.isInCamRange = Phaser.Geom.Rectangle.Overlaps(this.scene.cameras.main.worldView, this.bounds);
        if (!this.isActivated && this.isCloseToPlayer) {
            this.isActivated = true;
            if (globalConfig.debug) {
                this.debugText.setText('ACTIVATED');
            }
            this.dangerSound.play();
        }
        super.preUpdate(time, delta);
    }

    public setState(value: States): this {
        switch (value) {
            case States.IDLE:
                GameSpriteHelper.setBodyParams(this, this.asset.idle);
                this.play('boss/idle', true);
                break;

            case States.ANGRY:
                GameSpriteHelper.setBodyParams(this, this.asset.idle);
                this.play('boss/idle-angry', true);
                break;

            case States.DIE:
                this.destroySound.play();
                this.dangerSound.stop();
                this.isDead = true;
                this.angryTimer.destroy();
                this.shootingTimer.destroy();
                this.bossArtifacts.destroy(true);
                this.play('boss/kill', true);
                if (globalConfig.debug) {
                    this.debugText.setText('BOSS is DEAD')
                        .setPosition(this.debugText.x - 30, this.debugText.y + 220);
                }

                this.scene.time.delayedCall(1500, () => {
                    this.globalEvents.emit(EVENT_GAME_COMPLETED);
                    this.globalEvents.emit(EVENT_SHOW_GAME_COMPLETED);
                });

                break;
        }
        return super.setState(value);
    }

    public hurt(amount: number): void {
        this.power -= amount;
        this.globalEvents.emit(EVENT_BOSS_POWER_UPDATE);
        if (this.power <= 0) {
            this.setState(States.DIE);
        }
    }

    public destroy(fromScene?: boolean): void {
        this.ammo.destroy();
        super.destroy();
    }

    /**
     * Resets energy, and death flags. Number of lives stays the same.
     */
    public resetBoss(): void {
        this.isDead = false;
        this.power = this.totalPower;
        this.setState(States.IDLE);
        this.angryTimer.destroy();
        this.shootingTimer.destroy();
        this.createTimers(); // recreate timers
    }

    private createTimers(): void {
        this.angryTimer = this.scene.time.addEvent({
            startAt: 100,
            delay: 8000,
            loop: true,
            callback: this.makeMeAngry.bind(this)
        });

        this.shootingTimer = this.scene.time.addEvent({
            startAt: 100,
            delay: this.shootingInterval,
            loop: true,
            callback: this.throwSoot.bind(this)
        });
    }

    private makeMeAngry(): void {
        if (this.isActivated && !this.isDead) {
            this.angryTimer.paused = true;
            this.setState(States.ANGRY);
        }
    }

    private throwSoot(): void {
        if (this.isActivated && !this.isDead) {
            if (this.toxicShootsCounter < this.toxicShots) {
                this.toxicShootsCounter++;
                this.attackToxic();
            }
            else {
                this.toxicShootsCounter = 0;
                this.attackFire();
            }
        }
    }

    private async attackFire(): Promise<void> {
        await this.openGrate();

        this.scene.time.delayedCall(500, () => {
            const ammoSprite = new AmmoSprite(this.scene, this.x - 60, this.y + 60, true, AmmoType.Fire, 2800, 50);
            this.ammo.add(ammoSprite);
            this.scene.physics.moveTo(ammoSprite, this.player.x, this.player.y - 50, 500);
        });

        return new Promise((resolve) => {
            this.scene.time.delayedCall(1000, () => {
                this.closeGrate()
                    .then(() => resolve());
            });
        });
    }

    private attackToxic(): Promise<void> {
        // start at 0
        const ammoSprite = new AmmoSprite(this.scene, this.x - 30, this.y + 150, false, AmmoType.Toxic, 1800, 20);
        this.ammo.add(ammoSprite);
        this.scene.physics.moveTo(ammoSprite, this.player.x, this.player.y - 10, 500);

        // start at 400ms
        this.scene.time.delayedCall(400, () => {
            const ammoSprite = new AmmoSprite(this.scene, this.x - 60, this.y + 130, false, AmmoType.Toxic, 1800, 20);
            this.ammo.add(ammoSprite);
            this.scene.physics.moveTo(ammoSprite, this.player.x, this.player.y - 50, 500);
        });

        // start at 800ms
        this.scene.time.delayedCall(800, () => {
            const ammoSprite = new AmmoSprite(this.scene, this.x - 90, this.y + 110, false, AmmoType.Toxic, 1800, 20);
            this.ammo.add(ammoSprite);
            this.scene.physics.moveTo(ammoSprite, this.player.x, this.player.y - 90, 500);
        });

        // end at 1300s
        return new Promise((resolve) => {
            this.scene.time.delayedCall(1300, () => {
                resolve();
            });
        });
    }

    private openGrate(): Promise<void> {
        return new Promise((resolve) => {
            this.scene.tweens.add({
                targets: this.grate,
                duration: 1000,
                props: {
                    y: this.grate.y - 40
                },
                onComplete: () => resolve()
            });
        });
    }

    private closeGrate(): Promise<void> {
        return new Promise((resolve) => {
            this.scene.tweens.add({
                targets: this.grate,
                duration: 1000,
                props: {
                    y: this.grate.y + 40
                },
                onComplete: () => resolve()
            });
        });
    }
}
