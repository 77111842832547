import {GlobalEvents} from '../../helpers/global-events';

/**
 * A base class for all GUI screens, windows, popups, and modals.
 */
export class Screen extends Phaser.Scene {
    private params: ScreenParams;
    protected globalEvents: GlobalEvents = GlobalEvents.resolve();

    constructor(public name: string) {
        super({
            key: name || 'gui/basic-screen',
            active: false,
            visible: false
        });
    }
    
    
    /**
     * Called when a scene is created.
     * @param data
     */
    public create(data: ScreenParams) {
        this.params = data;
        this.onShow(data);
        if (this.params.onShow) {
            this.params.onShow(this);
        }
    }

    public update(time: number, delta: number): void {
        super.update(time, delta);
    }

    public closeScreen(result: ScreenResult): Promise<void> {
        return this.onHide()
                   .then(() => {
                       // start closing
                       this.scene.stop();
                       if (this.params.onClose) {
                           this.params.onClose(result);
                       }
                   });
    }

    protected onShow(data: ScreenParams): void {
    }

    protected onHide(): Promise<void> {
        return Promise.resolve();
    }
}

export interface ScreenParams<T = any> {
    data?: T;
    onClose?: (result: ScreenResult) => void;
    onShow?: (screen: Screen) => void;
}

export interface ScreenResult {
    button?: string;
    data?: any;
}
