import Text = Phaser.GameObjects.Text;
import TextStyle = Phaser.Types.GameObjects.Text.TextStyle;

export class GuiText extends Text {
    constructor(private config: TextConfig) {
        super(config.scene, config.x, config.y, config.text, GuiText.getStyle(config));

        if (config.onClick) {
            this.setInteractive({cursor: 'pointer'});
            this.on('pointerdown', () => {
                this.config.onClick(this.config.tag);
            });
        }
    }

    public changeColor(color: TextColor): void {
        this.config.color = color;
        this.setStyle(GuiText.getStyle(this.config));
    }

    private static getStyle(config: TextConfig): TextStyle {
        const style = <TextStyle>{
            fontSize: `${config.fontSize ? config.fontSize.toFixed(1) : 14}px`,
            color: config.color || TextColor.brown,
            fontFamily: config.textType || TextType.monospace,
            align: config.textAlign || 'center'
        };
        if (config.width) {
            style.fixedWidth = config.width;
        }
        if (config.height) {
            style.fixedHeight = config.height;
        }
        if (config.wordWrap) {
            style.wordWrap = {width: config.width, useAdvancedWrap: true};
        }
        return style;
    }
}

export enum TextType {
    monospace = 'monospace',
    ravie = 'Ravie',
    interBold = 'Inter Bold',
    interRegular = 'Inter',
    interSemiBold = 'Inter Semi Bold'
}

export enum TextColor {
    yellow = '#E5CD65',
    brown = '#913D25',
    black = '#000000',
    white = '#FFFFFF',
    green = '#59A100',
    red = '#FF0000'
}

export interface TextConfig {
    scene: Phaser.Scene;
    x: number;
    y: number;
    width?: number;
    height?: number;
    fontSize?: number;
    text: string;
    color?: TextColor;
    textType?: TextType;
    textAlign?: 'left' | 'right' | 'center',
    wordWrap?: boolean;
    tag?: string;
    onClick?: (tag: string) => void;
}
