import {AmmunitionAsset, AssetAnimSpriteInfo, ASSETS} from '../assets';
import {EVENT_BOSS_KILLED, EVENT_BOSS_POWER_UPDATE} from '../helpers/global-events';
import Animation = Phaser.Animations.Animation;
import TimerEvent = Phaser.Time.TimerEvent;
import Vector2 = Phaser.Math.Vector2;
import BaseSound = Phaser.Sound.BaseSound;

export enum AmmoType {
    BlueFlame = 'flame',
    Soot = 'soot',
    Toxic = 'toxic',
    Fire = 'fire'
}

export class AmmoSprite extends Phaser.Physics.Arcade.Sprite {

    private asset: AmmunitionAsset = ASSETS.ammo;
    private explosions: { [ammoType: string]: AssetAnimSpriteInfo } = {};
    private destroyEffectAnim: string;
    private killEvent: TimerEvent;
    private startPos: Vector2;
    public body: Phaser.Physics.Arcade.Body;
    private hitSound: BaseSound;
    public readonly hurtScore: number;
    public isAboutToKill = false;

    constructor(scene: Phaser.Scene, x: number, y: number, flipX: boolean, private ammoType = AmmoType.BlueFlame, destroyAfter: number = 1250, hurtPoints: number = 10) {
        super(scene, x, y, 'ammo');
        this.hurtScore = hurtPoints;
        this.startPos = new Phaser.Math.Vector2(x, y);
        this.scene.add.existing(this);
        this.scene.physics.add.existing(this);
        this.explosions[AmmoType.BlueFlame] = ASSETS.effects.effect2;
        this.explosions[AmmoType.Toxic] = ASSETS.effects.effect2;
        this.explosions[AmmoType.Fire] = ASSETS.effects.effect2;
        this.explosions[AmmoType.Soot] = ASSETS.effects.effect1;
        this.destroyEffectAnim = `anim/amo-destroy-effect/${this.ammoType}`;
        this.body
            .setAllowGravity(false)
            .setVelocityX(flipX ? -300 : 300)
            .setVelocityY(0);

        this.setDepth(9999);
        this.setRotation(flipX ? Math.PI : 0);

        this.hitSound = this.scene.sound.add('music/ammo-hit', { loop: false, volume: 0.3});

        if (!this.scene.anims.exists('ammo/blue-flame')) {
            this.scene.anims.create({
                key: 'ammo/blue-flame',
                repeat: -1,
                frames: this.asset.blueFlame.name,
                frameRate: this.asset.blueFlame.fps
            });
        }
        if (!this.scene.anims.exists('ammo/soot')) {
            this.scene.anims.create({
                key: 'ammo/soot',
                repeat: -1,
                frames: this.asset.soot.name,
                frameRate: this.asset.soot.fps
            });
        }
        if (!this.scene.anims.exists('ammo/toxic')) {
            this.scene.anims.create({
                key: 'ammo/toxic',
                repeat: -1,
                frames: this.asset.toxic.name,
                frameRate: this.asset.toxic.fps
            });
        }
        if (!this.scene.anims.exists('ammo/fire')) {
            this.scene.anims.create({
                key: 'ammo/fire',
                repeat: -1,
                frames: this.asset.fire.name,
                frameRate: this.asset.fire.fps
            });
        }
        if (!this.scene.anims.exists(this.destroyEffectAnim)) {
            this.scene.anims.create({
                key: this.destroyEffectAnim,
                repeat: 0,
                frames: this.explosions[this.ammoType].name,
                frameRate: this.explosions[this.ammoType].fps
            });
        }

        switch (ammoType) {
            case AmmoType.BlueFlame:
                this.play('ammo/blue-flame', true);
                break;

            case AmmoType.Soot:
                this.play('ammo/soot', true);
                break;

            case AmmoType.Toxic:
                this.play('ammo/toxic', true);
                break;

            case AmmoType.Fire:
                this.play('ammo/fire', true);
                break;
            default:
                return;
        }

        this.killEvent = this.scene.time.delayedCall(destroyAfter, () => {
            this.killAmo();
        });
    }

    public destroy(fromScene?: boolean): void {
        this.removeAllListeners();
        super.destroy(fromScene);
    }

    public killAmo(): void {
        this.hitSound.play();
        this.stop();
        this.setVelocity(0);
        this.killEvent.destroy();
        this.body.setEnable(false);
        this.on(Phaser.Animations.Events.ANIMATION_COMPLETE, this.onAnimComplete, this);

        this.setScale(0.5); // smaller explosion
        this.play(this.destroyEffectAnim, true);
    }

    private onAnimComplete(animation: Animation): void {
        if (animation.key === this.destroyEffectAnim) {
            this.destroy();
        }
    }

}
