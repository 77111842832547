import { LIVES_VALUE, POWER_VALUE } from '../constants';
import { BadgeService } from '../gui/bagde-service';
import { BadgeType, GameDifficultyLevel } from '../map/map-helper';
import { SCENES, START_SCENE } from '../scenes/scenes';
import { PlayerSprite } from '../sprites/player-sprite';
import { PlayerType } from '../sprites/player-type.enum';
import {
    EVENT_BADGE_FOUND, EVENT_CHECKPOINT_ACTIVATE, EVENT_DIFFICULTY_LEVEL_CHANGED, EVENT_ENEMY_KILLED,
    EVENT_GAME_COMPLETED, EVENT_GAME_OVER,
    EVENT_ITEM_COLLECTED, EVENT_PLAYER_CHANGED, EVENT_PLAYER_DEATH, EVENT_PLAYER_LIFE_UPDATE,
    EVENT_PLAYER_LOCATION_TARGET_UPDATE, EVENT_PLAYER_POWER_UPDATE, GlobalEvents
} from './global-events';
import { GlobalObjects, PlayerData } from './global-objects';
import { GoogleTagManager } from './google-tag-manager';

type StoreData = {
    levelsData: { [mapKey: string]: LevelState };
    selectedPlayerType: PlayerType;
    lastScene: string;
    playerData: PlayerData;
    difficultyLevel: GameDifficultyLevel;
};

// const level4Pos = <StoreData>{
//     lastScene: level4Scene.sceneName,
//     selectedPlayerType: PlayerType.player3,
//     playerData: {
//         power: 100,
//         lives: 3,
//         badges: [],
//         locationTarget: 'exit-portal'
//     },
//     playerPositionCheckPointId: undefined,
//     levelsData: {
//         [level1Scene.sceneName]: <LevelState>{killedEnemies: [], collectedObjects: []},
//         [level2Scene.sceneName]: <LevelState>{killedEnemies: [], collectedObjects: []},
//         [level3Scene.sceneName]: <LevelState>{killedEnemies: [], collectedObjects: []},
//         [level4Scene.sceneName]: <LevelState>{killedEnemies: [], collectedObjects: []}
//     }
// };

export class GameStore {
    private static globalEvents = GlobalEvents.resolve();
    private static gtm = GoogleTagManager.resolve();
    private static badgeService: BadgeService = BadgeService.resolve();
    private static data: StoreData = {
        levelsData: {},
        selectedPlayerType: PlayerType.player1,
        lastScene: START_SCENE,
        playerData: {
            power: POWER_VALUE,
            lives: LIVES_VALUE,
            badges: [],
            locationTarget: undefined
        },
        difficultyLevel: GameDifficultyLevel.normal
    };

    /**
     * Gets a current player type.
     */
    public static get player(): PlayerType {
        return GameStore.data.selectedPlayerType;
    }
    
    /**
     * Gets current difficulty level.
     */
    public static get difficultyLevel(): GameDifficultyLevel {
        return GameStore.data.difficultyLevel;
    }

    /**
     * Gets name of the last true level scene. For welcome screen the scene is
     * never a Welcome screen scene name, it will be the name of the last visited level.
     */
    public static get lastLevelName(): string {
        return GameStore.data.lastScene;
    }

    public static get isBossLastLevel(): boolean {
        return SCENES[GameStore.lastLevelName].isBossLevel;
    }

    public static get activeCheckpoint(): string | undefined {
        return GameStore.data.levelsData[GameStore.lastLevelName].checkPointId;
    }

    public static get playerData(): PlayerData {
        return {
            ...GameStore.data.playerData,
            badges: [
                ...GameStore.data.playerData.badges
            ]
        };
    }

    public static initLevelState(sceneKey: string): void {
        GameStore.data.lastScene = sceneKey;
        if (!GameStore.data.levelsData[GameStore.lastLevelName]) {
            GameStore.data.levelsData[GameStore.lastLevelName] = {
                collectedObjects: [],
                killedEnemies: [],
                checkPointId: undefined
            };
        }
    }

    public static hasObject(objectId: string): boolean {
        return GameStore.data.levelsData[GameStore.lastLevelName].collectedObjects.includes(objectId) ||
            GameStore.data.levelsData[GameStore.lastLevelName].killedEnemies.includes(objectId);
    }

    /**
     * Checks whether there is any old state that can be continued.
     */
    public static canContinueGame(): boolean {
        let hasData = false;
        for (let key in GameStore.data.levelsData) {
            if (GameStore.data.levelsData.hasOwnProperty(key)) {
                hasData = true;
                break;
            }
        }
        return hasData;
    }

    /**
     * Clears all achievements and levels data.
     */
    public static resetStore(): void {
        GameStore.data.levelsData = {};
        GameStore.data.lastScene = START_SCENE;
        GameStore.data.playerData = {
            power: POWER_VALUE,
            lives: LIVES_VALUE,
            badges: [],
            locationTarget: undefined,
        };
        GameStore.data.difficultyLevel = GlobalObjects.difficultyLevel;
        GameStore.badgeService.removeAll();
        GameStore.saveState();
    }

    public static resetSingleLevel(sceneKey: string): void {
        if (GameStore.data.levelsData[sceneKey]) {
            delete GameStore.data.levelsData[sceneKey];
        }
    }


    public static bootstrap(): void {
        // GameStore.data = level4Pos;

        GameStore.globalEvents.on(EVENT_ENEMY_KILLED, (objectId: string) => {
            GameStore.data.levelsData[GameStore.lastLevelName].killedEnemies.push(objectId);
            GameStore.saveState();
        });
        GameStore.globalEvents.on(EVENT_ITEM_COLLECTED, (objectId: string) => {
            GameStore.data.levelsData[GameStore.lastLevelName].collectedObjects.push(objectId);
            GameStore.saveState();
        });
        GameStore.globalEvents.on(EVENT_PLAYER_CHANGED, (playerType: PlayerType) => {
            GameStore.data.selectedPlayerType = playerType;
            GameStore.saveState();
        });
        GameStore.globalEvents.on(EVENT_DIFFICULTY_LEVEL_CHANGED, (level: GameDifficultyLevel) => {
            GameStore.data.difficultyLevel = level;
            GameStore.saveState();
        });
        GameStore.globalEvents.on(EVENT_PLAYER_POWER_UPDATE, (player: PlayerSprite) => {
            GameStore.data.playerData.power = player.power;
            GameStore.saveState();
        });
        GameStore.globalEvents.on(EVENT_PLAYER_LIFE_UPDATE, (player: PlayerSprite) => {
            GameStore.data.playerData.lives = player.lives;
            GameStore.saveState();

            GameStore.gtm.livesChanged(player.lives);
        });
        GameStore.globalEvents.on(EVENT_PLAYER_DEATH, () => {
            if (GameStore.isBossLastLevel) {
                GameStore.resetSingleLevel(GameStore.lastLevelName);
                GameStore.saveState();
            }
        });
        GameStore.globalEvents.on(EVENT_CHECKPOINT_ACTIVATE, (checkpointId: string) => {
            GameStore.data.levelsData[GameStore.lastLevelName].checkPointId = checkpointId;
            GameStore.saveState();
        });
        GameStore.globalEvents.on(EVENT_BADGE_FOUND, (badge: BadgeType) => {
            GameStore.data.playerData.badges.push(badge);
            GameStore.saveState();
        });
        GameStore.globalEvents.on(EVENT_PLAYER_LOCATION_TARGET_UPDATE, (targetScene: string, locationTarget: string) => {
            GameStore.data.playerData.locationTarget = locationTarget;
            GameStore.data.lastScene = targetScene;
            GameStore.saveState();

            GameStore.gtm.sceneEnter(`${targetScene}=>${locationTarget}`);
        });
        GameStore.globalEvents.on(EVENT_GAME_OVER, () => {
            GameStore.resetStore();
            GameStore.saveState();
            GameStore.gtm.gameOver();
        });
        GameStore.globalEvents.on(EVENT_GAME_COMPLETED, () => {
            GameStore.gtm.gameCompleted();
        });
        if (window.localStorage) {
            // load data from the local storage if possible
            GameStore.readState();
            
            // Temporary override difficulty level for development purposes.
            // Remember to start a new game every time we override something here.
            // const overrideDifficultyLevelWith = GameDifficultyLevel.easy;
            // if (GameStore.data.difficultyLevel != overrideDifficultyLevelWith) {
            //     GameStore.data.difficultyLevel = overrideDifficultyLevelWith;
            //     GameStore.data.levelsData = {};
            //     GameStore.data.playerData = {
            //         power: POWER_VALUE,
            //         lives: LIVES_VALUE,
            //         badges: [],
            //         locationTarget: undefined,
            //     };
            //     GameStore.badgeService.removeAll();
            //     GameStore.saveState();
            // }
        }
    }

    private static encodeState(): LocalStorageData {
        const data: LocalStorageData = {
            p: GameStore.getPlayerNumber(GameStore.player),
            d: {},
            x: GameStore.lastLevelName,
            pd: {
                a: GameStore.data.playerData.power,
                b: GameStore.data.playerData.lives,
                c: GameStore.data.playerData.badges.map(b => GameStore.getBadgeNumber(b)),
                d: GameStore.data.playerData.locationTarget
            },
            l: 0
        };
        
        // encode difficulty
        switch (GlobalObjects.difficultyLevel) {
            case GameDifficultyLevel.easy: data.l = 25; break;
            case GameDifficultyLevel.normal: data.l = 50; break;
            case GameDifficultyLevel.hard: data.l = 100; break;
        }
        
        for (let key in GameStore.data.levelsData) {
            if (GameStore.data.levelsData.hasOwnProperty(key)) {
                data.d[key] = {
                    c: GameStore.data.levelsData[key].collectedObjects,
                    k: GameStore.data.levelsData[key].killedEnemies,
                    ci: GameStore.data.levelsData[key].checkPointId
                };
            }
        }
        return data;
    }

    private static decodeState(data: LocalStorageData): StoreData {
        const state: StoreData = {
            levelsData: {},
            selectedPlayerType: GameStore.getPlayerType(data.p),
            lastScene: data.x,
            playerData: {
                power: data.pd.a,
                lives: data.pd.b,
                badges: data.pd.c.map(b => GameStore.getBadgeType(b)),
                locationTarget: data.pd.d,
            },
            difficultyLevel: GameDifficultyLevel.normal // default
        };
        switch (data.l) {
            case 25: state.difficultyLevel = GameDifficultyLevel.easy; break;
            case 50: state.difficultyLevel = GameDifficultyLevel.normal; break;
            case 100: state.difficultyLevel = GameDifficultyLevel.hard; break;
        }

        // add badges to badge service
        state.playerData.badges.forEach(badgeType => {
            GameStore.badgeService.addBadgeToUnlocked(badgeType);
        });

        for (let key in data.d) {
            if (data.d.hasOwnProperty(key)) {
                state.levelsData[key] = {
                    collectedObjects: data.d[key].c,
                    killedEnemies: data.d[key].k,
                    checkPointId: data.d[key].ci
                };
            }
        }
        return state;
    }

    private static saveState(): void {
        if (window.localStorage) {
            // saving state in localStorage

            const encoded = GameStore.encodeState();
            if (encoded) {
                try {
                    const json = JSON.stringify(encoded, null, '');
                    window.localStorage.setItem('vigame', json);
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }

    private static readState(): void {
        if (window.localStorage) {
            const json = window.localStorage.getItem('vigame');
            if (json) {
                try {
                    const data: LocalStorageData = JSON.parse(json);
                    GameStore.data = GameStore.decodeState(data);
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }

    private static getPlayerNumber(value: PlayerType): number {
        const values = Object.values(PlayerType);
        return values.indexOf(value) + 1;
    }

    private static getPlayerType(playerNumber: number): PlayerType {
        const values = Object.values(PlayerType);
        return values[playerNumber - 1] as PlayerType;
    }

    private static getBadgeNumber(value: BadgeType): number {
        const keys = Object.values(BadgeType);
        return keys.indexOf(value) + 1;
    }

    private static getBadgeType(badgeNumber: number): BadgeType {
        const values = Object.values(BadgeType);
        return values[badgeNumber - 1] as BadgeType;
    }
}

interface LevelState {
    /**
     * Collected objects IDs.
     */
    collectedObjects: string[];
    /**
     * Killed enemies IDs.
     */
    killedEnemies: string[];
    /**
     * Particular level last checkpoint id.
     */
    checkPointId: string | undefined;
}

interface LocalStorageData {
    /**
     * Name of last visited scene.
     */
    x: string;
    /**
     * Selected player number 1...5
     */
    p: number;
    /**
     * Difficulty level
     */
    l: number;
    /**
     * Levels data
     */
    d: {
        [mapKey: string]: {
            /**
             * Collected objects
             */
            c: string[];
            /**
             * killed enemies
             */
            k: string[];
            /**
             * Check-point ID.
             */
            ci: string | undefined;
        }
    };
    /**
     * Player data.
     */
    pd: {
        /**
         * Power.
         */
        a: number;
        /**
         * Lives.
         */
        b: number;
        /**
         * Badges
         */
        c: number[];
        /**
         * Location target.
         */
        d: string;
    };
}
