import VirtualJoyStick from 'phaser3-rex-plugins/plugins/input/virtualjoystick/VirtualJoyStick';
import VirtualJoystickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin';
import {ASSETS} from '../assets';
import {gameConfig} from '../config';
import {GlobalObjects} from './global-objects';
import Ellipse = Phaser.GameObjects.Ellipse;
import Image = Phaser.GameObjects.Image;

export class VirtualGamePad {
    private scene: Phaser.Scene;
    private windowWidth: number;
    private windowHeight: number;
    private btnFire: Image;
    private btnJump: Image;

    private btnLeft: Ellipse;
    private btnRight: Ellipse;
    private btnDown: Ellipse;

    private isJump: boolean = false;
    private isFire: boolean = false;
    private isLeft: boolean = false;
    private isRight: boolean = false;
    private isDown: boolean = false;

    private btnSize: number;
    private controlsEdgeDistance: number;
    private distanceBetweenButtons: number;
    private controllerSize: number;
    private controllerThumbSize: number;
    private gameWidth: number;
    private gameHeight: number;

    private joyStick: VirtualJoyStick;
    private cursorKeys: {
        up: Phaser.Input.Keyboard.Key,
        down: Phaser.Input.Keyboard.Key,
        left: Phaser.Input.Keyboard.Key,
        right: Phaser.Input.Keyboard.Key,
    };

    public get up(): boolean {
        return false;
    }

    public get down(): boolean {
        const controllerBtn = GlobalObjects.usePadController ? this.cursorKeys.down.isDown : false;
        return controllerBtn || this.isDown;
    }

    public get left(): boolean {
        const controllerBtn = GlobalObjects.usePadController ? this.cursorKeys.left.isDown : false;
        return controllerBtn || this.isLeft;
    }

    public get right(): boolean {
        const controllerBtn = GlobalObjects.usePadController ? this.cursorKeys.right.isDown : false;
        return controllerBtn || this.isRight;
    }

    public get turboOn(): boolean {
        return true;
    }

    public get jump(): boolean {
        return this.isJump;
    }

    public get fire(): boolean {
        return this.isFire;
    }

    constructor(scene: Phaser.Scene) {
        this.scene = scene;
        this.initVirtualPad();
    }

    private prepareControlParams(): void {
        const canvas = this.scene.sys.game.canvas;
        this.windowWidth = parseFloat(canvas.style.width);
        this.windowHeight = parseFloat(canvas.style.height);
        this.gameWidth = gameConfig.width as number;
        this.gameHeight = gameConfig.height as number;
        this.controllerSize = this.gameWidth * 0.20; // 20%
        this.controllerThumbSize = this.controllerSize / 2.5;
        this.controlsEdgeDistance = this.gameWidth * 0.02; // 2%
        this.btnSize = this.gameWidth * 0.09;
        this.distanceBetweenButtons = this.controlsEdgeDistance;

        console.log(`
            controller size: ${this.controllerSize}
            btn size: ${this.btnSize}
        `);
    }

    private updateControlsPosition(): void {
        const upperBtnBottomMargin = this.controlsEdgeDistance * 3;

        if (GlobalObjects.usePadController) {
            this.joyStick.setPosition(
                this.controllerSize / 2 + this.controlsEdgeDistance,
                this.gameHeight - this.controllerSize / 2 - this.controlsEdgeDistance
            );
        } else {
            const btnLeftX = this.btnSize / 2 + this.controlsEdgeDistance;
            const btnRightX = btnLeftX + this.btnSize / 2 + this.distanceBetweenButtons * 6 + this.btnSize / 2;
            this.btnLeft.setPosition(
                btnLeftX,
                this.gameHeight - this.btnSize - this.controlsEdgeDistance
            );

            this.btnRight.setPosition(
                btnRightX,
                this.gameHeight - this.btnSize - this.controlsEdgeDistance
            );

            this.btnDown.setPosition(
                btnLeftX + (btnRightX - btnLeftX) / 2,
                this.gameHeight - this.btnSize / 2 - this.controlsEdgeDistance
            );
        }

        this.btnFire.setPosition(
            this.gameWidth - this.btnSize / 2 - this.controlsEdgeDistance - this.btnSize / 2 - this.btnSize / 2 - this.distanceBetweenButtons,
            this.gameHeight - this.btnSize / 2 - this.controlsEdgeDistance
        );
        this.btnJump.setPosition(
            this.gameWidth - this.btnSize / 2 - this.controlsEdgeDistance,
            this.gameHeight - this.btnSize / 2 - this.controlsEdgeDistance - upperBtnBottomMargin
        );
    }

    private initVirtualPad(): void {
        this.prepareControlParams();

        if (GlobalObjects.usePadController) {
            const plugin = <VirtualJoystickPlugin>this.scene.plugins.get('rexVirtualJoystick');
            const controllerArea = this.scene.add.image(0,0, ASSETS.gui.padController.name, 0);
            controllerArea.setScale(this.controllerSize / controllerArea.width);
            const controllerThumb = this.scene.add.image(0,0, ASSETS.gui.padControllerThumb.name, 0);
            controllerThumb.setScale(this.controllerThumbSize / controllerThumb.width);

            this.joyStick = plugin.add(this.scene, {
                x: 0,
                y: 0,
                radius: this.controllerSize / 2,
                base: controllerArea,
                thumb: controllerThumb,
                dir: '4dir',
                fixed: true
            });
            this.cursorKeys = this.joyStick.createCursorKeys();
        } else {
            this.btnLeft = this.scene.add.ellipse(
                0,
                0,
                this.btnSize, this.btnSize,
                0xFFFFFF, 1
            );
            this.btnLeft
                .setScrollFactor(0, 0)
                .setInteractive({cursor: 'pointer'})
                .setAlpha(0.5);

            this.btnRight = this.scene.add.ellipse(
                0,
                0,
                this.btnSize, this.btnSize,
                0xFFFFFF, 1
            );
            this.btnRight
                .setScrollFactor(0, 0)
                .setInteractive({cursor: 'pointer'})
                .setAlpha(0.5);

            this.btnDown = this.scene.add.ellipse(
                0,
                0,
                this.btnSize, this.btnSize,
                0xFFFFFF, 1
            );
            this.btnDown
                .setScrollFactor(0, 0)
                .setInteractive({cursor: 'pointer'})
                .setAlpha(0.5);

            this.btnLeft.on('pointerdown', () => {
                this.btnLeft.setAlpha(0.8);
                this.isLeft = true;
            });
            this.btnLeft.on('pointerup', () => {
                this.btnLeft.setAlpha(0.5);
                this.isLeft = false;
            });
            this.btnLeft.on('pointerout', () => {
                this.btnLeft.setAlpha(0.5);
                this.isLeft = false;
            });

            this.btnRight.on('pointerdown', () => {
                this.btnRight.setAlpha(0.8);
                this.isRight = true;
            });
            this.btnRight.on('pointerup', () => {
                this.btnRight.setAlpha(0.5);
                this.isRight = false;
            });
            this.btnRight.on('pointerout', () => {
                this.btnRight.setAlpha(0.5);
                this.isRight = false;
            });

            this.btnDown.on('pointerdown', () => {
                this.btnDown.setAlpha(0.8);
                this.isDown = true;
            });
            this.btnDown.on('pointerup', () => {
                this.btnDown.setAlpha(0.5);
                this.isDown = false;
            });
            this.btnDown.on('pointerout', () => {
                this.btnDown.setAlpha(0.5);
                this.isDown = false;
            });
        }

        this.btnFire = this.scene.add.image(0,0, ASSETS.gui.padFireButton.name, 0);
        this.btnFire
            .setScale(this.btnSize / this.btnFire.width)
            .setScrollFactor(0, 0)
            .setInteractive({cursor: 'pointer'});

        this.btnJump = this.scene.add.image(0,0, ASSETS.gui.padJumpButton.name, 0);
        this.btnJump
            .setScale(this.btnSize / this.btnJump.width)
            .setScrollFactor(0, 0)
            .setInteractive({cursor: 'pointer'});

        this.btnFire.on('pointerdown', () => {
            this.btnFire.setTexture(ASSETS.gui.padFireButton.name, 1);
            this.isFire = true;
        });
        this.btnFire.on('pointerup', () => {
            this.btnFire.setTexture(ASSETS.gui.padFireButton.name, 0);
            this.isFire = false;
        });
        this.btnFire.on('pointerout', () => {
            this.btnFire.setTexture(ASSETS.gui.padFireButton.name, 0);
            this.isFire = false;
        });

        this.btnJump.on('pointerdown', () => {
            this.btnJump.setTexture(ASSETS.gui.padJumpButton.name, 1);
            this.isJump = true;
        });
        this.btnJump.on('pointerup', () => {
            this.btnJump.setTexture(ASSETS.gui.padJumpButton.name, 0);
            this.isJump = false;
        });
        this.btnJump.on('pointerout', () => {
            this.btnJump.setTexture(ASSETS.gui.padJumpButton.name, 0);
            this.isJump = false;
        });

        this.updateControlsPosition();
    }
}
