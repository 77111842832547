import Container = Phaser.GameObjects.Container;
import {Screen, ScreenParams} from './screen';
import {GuiWindow, WindowType} from '../gui-window';
import {GuiText, TextColor, TextType} from '../gui-text';
import {ElementType, GuiElement} from '../gui-element';
import {BadgeType} from '../../map/map-helper';
import {tweenShow} from '../gui-tweens';

const titles = {
    [BadgeType.freeHeatingService]: 'Free Heating Service',
    [BadgeType.friendsAndFamily]: 'Friends & Family',
    [BadgeType.geofencing]: 'Geofencing',
    [BadgeType.smartThermostat]: 'Viessmann Smart Thermostat',
    [BadgeType.surpriseGift]: 'Viessmann Surprise Gift',
    [BadgeType.heatingCurveAssistant]: 'Heating Curve Assistant'
};

export class BadgeFoundScreen extends Screen {
    private container: Container;
    private windowHeight: number;
    public static readonly key = 'gui/screen/badge-found';
    private badge: BadgeType;

    constructor() {
        super(BadgeFoundScreen.key);
    }

    protected onShow(data: ScreenParams): Promise<void> {
        this.badge = data.data.badge;
        this.initHandlers();

        this.time.delayedCall(5000, () => {
            this.input.off('pointerdown');
            this.closeScreen({
                button: 'ok'
            });
        });

        this.createElements();
        tweenShow(
            this,
            this.container,
            {
                y: this.windowHeight / 2
            }
        );

        return Promise.resolve();
    }

    protected onHide(): Promise<void> {
        return Promise.resolve();
    }

    private initHandlers(): void {
        this.input.on('pointerdown', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
        this.input.keyboard.on('keydown-ENTER', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
        this.input.keyboard.on('keydown-ESC', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
    }

    private createElements(): void {
        const wnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.itemCollected
        });
        this.windowHeight = wnd.height;

        const title = new GuiText({
            scene: this,
            x: -152,
            y: -78,
            text: 'New Badge Unlocked',
            textType: TextType.ravie,
            fontSize: 24,
            color: TextColor.yellow
        });

        const msg = new GuiText({
            scene: this,
            x: -40,
            y: 22,
            width: 270,
            height: 130,
            text: titles[this.badge],
            textType: TextType.ravie,
            fontSize: 24,
            color: TextColor.brown,
            wordWrap: true,
            textAlign: 'left'
        });

        this.container = this.add.container(this.game.canvas.width / 2, -this.windowHeight / 2);
        this.container.add(wnd);
        this.container.add(title);
        this.container.add(msg);
        this.container.add(this.createBadge());
    }

    private createBadge(): GuiElement {
        return new GuiElement({
            elementType: this.resolveElementType(),
            scene: this,
            x: -159,
            y: 87
        });
    }

    private resolveElementType(): ElementType {
        switch (this.badge) {
            case BadgeType.freeHeatingService:
                return ElementType.badge1;

            case BadgeType.friendsAndFamily:
                return ElementType.badge2;

            case BadgeType.geofencing:
                return ElementType.badge3;

            case BadgeType.heatingCurveAssistant:
                return ElementType.badge4;

            case BadgeType.smartThermostat:
                return ElementType.badge5;

            case BadgeType.surpriseGift:
                return ElementType.badge6;
        }
    }
}
