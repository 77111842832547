import {ElementType, GuiElement} from '../gui-element';
import {GuiText, TextColor, TextType} from '../gui-text';
import {tweenHide, tweenShow} from '../gui-tweens';
import {GuiWindow, WindowType} from '../gui-window';
import {Screen, ScreenParams} from './screen';
import Container = Phaser.GameObjects.Container;
import BaseSound = Phaser.Sound.BaseSound;

export class PlayerDeathScreen extends Screen {
    private container: Container;
    private windowHeight: number;
    private failSound: BaseSound;
    public static readonly key = 'gui/screen/player-death';

    constructor() {
        super(PlayerDeathScreen.key);
    }

    protected onShow(data: ScreenParams): Promise<void> {
        const livesLeft = data.data as number;
        this.failSound = this.sound.add('music/fail', {loop: false})
        this.initHandlers();

        this.time.delayedCall(5000, () => {
            this.input.off('pointerdown');
            this.closeScreen({
                button: 'ok'
            });
        });

        this.createElements(livesLeft);
        return tweenShow(this, this.container, {
            y: this.windowHeight / 2
        }).then(() => {
            this.failSound.play();
        });
    }

    protected onHide(): Promise<void> {
        return tweenHide(this, this.container, {
            y: -this.windowHeight / 2
        }).then(() => {
            this.failSound.stop();
        });
    }

    private initHandlers(): void {
        this.input.on('pointerdown', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
        this.input.keyboard.on('keydown-ENTER', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
        this.input.keyboard.on('keydown-ESC', () => {
            this.closeScreen({
                button: 'ok'
            });
        });
    }

    private createElements(livesLeft: number): void {
        const wnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.small
        });
        this.windowHeight = wnd.height;

        const lives = this.createLives(livesLeft);
        lives.setX(-lives.width / 2);

        const title = new GuiText({
            scene: this,
            x: -50,
            y: -140,
            text: 'Ouch...',
            textType: TextType.ravie,
            fontSize: 24,
            color: TextColor.yellow
        });
        const msgTitle = new GuiText({
            scene: this,
            x: -300,
            y: -50,
            width: 600,
            text: 'It\'s not the end, huh!',
            textType: TextType.ravie,
            fontSize: 28,
            color: TextColor.brown
        });

        const numberOfLives = [
            'One life', 'Two lives', 'Three lives', 'Four lives', 'Five lives'
        ];
        const msg = new GuiText({
            scene: this,
            x: -300,
            y: 40,
            width: 600,
            text: `${numberOfLives[livesLeft - 1]} left, have a good luck!`,
            textType: TextType.interSemiBold,
            fontSize: 18,
            color: TextColor.brown
        });


        this.container = this.add.container(this.game.canvas.width / 2, -this.windowHeight / 2);
        this.container.add(wnd);
        this.container.add(lives);
        this.container.add(title);
        this.container.add(msgTitle);
        this.container.add(msg);
    }

    private createLives(count: number): Container {
        const children: GuiElement[] = [];
        for (let i = 0; i < count; i++) {
            children.push(new GuiElement({
                elementType: ElementType.life,
                scene: this,
                x: i * 110,
                y: 0
            }));
        }
        const group = this.add.container(0, 140, children);
        group.setSize(110 * (count - 1), 0);
        return group;
    }

}
