export type TweenProperties = {
    [key: string]: (number | string | Phaser.Types.Tweens.GetEndCallback | Phaser.Types.Tweens.TweenPropConfig)
};
export function tweenHide(scene: Phaser.Scene, targets: any, props: TweenProperties, duration: number = 500): Promise<void> {
    return new Promise((resolve => {
        scene.tweens.add({
            targets,
            duration,
            ease: Phaser.Math.Easing.Cubic.In,
            props,
            onComplete: () => {
                resolve();
            }
        });
    }));
}

export function tweenShow(scene: Phaser.Scene, targets: any, props: TweenProperties, duration: number = 1000): Promise<void> {
    return new Promise((resolve => {
        scene.tweens.add({
            targets,
            duration,
            ease: Phaser.Math.Easing.Quartic.Out,
            props,
            onComplete: () => {
                resolve();
            }
        });
    }));
}
