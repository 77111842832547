import { ASSETS, PlayerAssets } from '../../assets';
import { GameStore } from '../../helpers/game-store';
import {
    EVENT_DIFFICULTY_LEVEL_CHANGED,
    EVENT_DISABLE_SOUND, EVENT_ENABLE_SOUND, EVENT_PLAYER_CHANGED, GlobalEvents
} from '../../helpers/global-events';
import { GlobalObjects } from '../../helpers/global-objects';
import { GoogleTagManager } from '../../helpers/google-tag-manager';
import { GameDifficultyLevel } from '../../map/map-helper';
import { PlayerType } from '../../sprites/player-type.enum';
import { ButtonSize, ButtonType, GuiButton } from '../gui-button';
import { ElementType, GuiElement } from '../gui-element';
import { GuiText, TextColor, TextType } from '../gui-text';
import { tweenHide, tweenShow } from '../gui-tweens';
import { GuiWindow, WindowType } from '../gui-window';
import { Screen, ScreenParams } from './screen';
import Container = Phaser.GameObjects.Container;
import Sprite = Phaser.GameObjects.Sprite;

const db: { [email: string]: PlayerType[] } = {};

export class WelcomeScreen extends Screen {
    private static gameStartTriggerTag = 'start';
    private gtm = GoogleTagManager.resolve();
    private containerMenu: Container;
    private containerControls: Container;
    private containerPlayers: Container;
    private containerLogin: Container;
    private containerNewGameQuestion: Container;
    private containerDifficultyLevels: Container;
    private loginWindowHeight: number;
    private menuWindowHeight: number;
    private playersWindowHeight: number;
    private controlsWindowHeight: number;
    private difficultyLevelsWindowHeight: number;
    private newGameQuestionWindowHeight: number;
    private loginInput: Phaser.GameObjects.DOMElement;
    private loginErrorInfo: GuiText;
    private playerButtons: GuiText[];
    private standingPlayers: Sprite[];
    private allPlayers: PlayerSelectionInfo[] = [
        {
            name: 'Vitodens',
            anim: 'anims/vitodens',
            selected: GlobalObjects.selectedPlayer === PlayerType.player1,
            textBtn: undefined,
            playerSprite: undefined,
            type: PlayerType.player1
        },
        {
            name: 'Vitocal',
            anim: 'anims/vitocal',
            selected: GlobalObjects.selectedPlayer === PlayerType.player2,
            textBtn: undefined,
            playerSprite: undefined,
            type: PlayerType.player2
        },
        {
            name: 'Vitodens 222-F',
            anim: 'anims/vitodens-222',
            selected: GlobalObjects.selectedPlayer === PlayerType.player3,
            textBtn: undefined,
            playerSprite: undefined,
            type: PlayerType.player3
        },
        {
            name: 'Vitovolt',
            anim: 'anims/vitovolt',
            selected: GlobalObjects.selectedPlayer === PlayerType.player4,
            textBtn: undefined,
            playerSprite: undefined,
            type: PlayerType.player4
        },
        {
            name: 'Vitocell',
            anim: 'anims/vitocell',
            selected: GlobalObjects.selectedPlayer === PlayerType.player5,
            textBtn: undefined,
            playerSprite: undefined,
            type: PlayerType.player5
        }
    ];
    private players: PlayerSelectionInfo[];
    private difficultyLevels: DifficultyLevelSelectionInfo[];

    public static readonly key = 'gui/screen/welcome';

    constructor() {
        super(WelcomeScreen.key);
        this.players = this.getVisiblePlayers([
            PlayerType.player1, PlayerType.player5
        ]);

        db['prmp@viessmann.com'] = [PlayerType.player1, PlayerType.player2, PlayerType.player3, PlayerType.player4, PlayerType.player5];
        db['test1@viessmann.com'] = [PlayerType.player2, PlayerType.player4, PlayerType.player5];
        db['test2@viessmann.com'] = [PlayerType.player1, PlayerType.player5];
        db['test3@viessmann.com'] = [PlayerType.player3, PlayerType.player4, PlayerType.player5];
        db['full@viessmann.com'] = [PlayerType.player1, PlayerType.player2, PlayerType.player3, PlayerType.player4, PlayerType.player5];
    }

    protected onShow(data: ScreenParams): Promise<void> {
        this.createElements();
        return this.showMenu();
    }

    protected onHide(): Promise<void> {

        return Promise.resolve();
    }

    private createElements(): void {

        const wnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.menu
        });
        wnd.setScale(0.8);
        this.menuWindowHeight = wnd.height;

        const buttonsSpaceY = 115;

        const btnStart = new GuiButton({
            scene: this,
            x: 0,
            y: -115,
            tag: WelcomeScreen.gameStartTriggerTag,
            buttonSize: ButtonSize.normal,
            buttonType: ButtonType.textStart,
            onClick: this.onButtonClick.bind(this)
        });

        const btnContinue = new GuiButton({
            scene: this,
            x: 0,
            y: btnStart.y + buttonsSpaceY,
            tag: 'continue',
            isDisabled: !GameStore.canContinueGame(),
            buttonSize: ButtonSize.normal,
            buttonType: ButtonType.textContinue,
            onClick: this.onButtonClick.bind(this)
        });

        const btnControls = new GuiButton({
            scene: this,
            x: 0,
            y: btnContinue.y + buttonsSpaceY,
            tag: 'controls',
            buttonSize: ButtonSize.normal,
            buttonType: ButtonType.textControls,
            onClick: this.onButtonClick.bind(this)
        });

        const btnPlayers = new GuiButton({
            scene: this,
            x: 0,
            y: btnControls.y + buttonsSpaceY,
            tag: 'players',
            buttonSize: ButtonSize.normal,
            buttonType: ButtonType.textPlayers,
            onClick: this.onButtonClick.bind(this)
        });

        // const btnAbout = new GuiButton({
        //     scene: this,
        //     x: 0,
        //     y: 180,
        //     tag: 'about',
        //     buttonSize: ButtonSize.normal,
        //     buttonType: ButtonType.textAbout,
        //     isDisabled: true
        // });

        // sound button
        const soundBtn = new GuiButton({
            scene: this,
            x: 0,
            y: 340,
            buttonType: this.sound.mute ? ButtonType.soundOff : ButtonType.soundOn,
            buttonSize: ButtonSize.normal,
            onClick: () => {
                if (soundBtn.getBtnType() === ButtonType.soundOn) {
                    soundBtn.changeType(ButtonType.soundOff);
                    this.globalEvents.emit(EVENT_DISABLE_SOUND);
                    this.gtm.buttonClicked(`WelcomeScene:SOUND-OFF`);
                }
                else {
                    soundBtn.changeType(ButtonType.soundOn);
                    this.globalEvents.emit(EVENT_ENABLE_SOUND);
                    this.gtm.buttonClicked(`WelcomeScene:SOUND-ON`);
                }
            }
        });

        this.containerMenu = this.add.container(this.game.canvas.width / 2, -this.menuWindowHeight / 2);
        this.containerMenu.add(wnd);
        this.containerMenu.add(btnStart);
        this.containerMenu.add(btnContinue);
        this.containerMenu.add(btnControls);
        this.containerMenu.add(btnPlayers);
        this.containerMenu.add(soundBtn);
        
        // sub menu screens
        this.createControlsConfigurationScreen();
        this.createPlayerSelectionScreen();
        this.createLoginScreen();
        this.createNewGameConfirmationScreen(btnStart.getTag());
        this.createDifficultyLevelsSelectionScreen();
        
        // ---------------------------------------------------
        const gameTitle = new GuiElement({
            scene: this,
            x: 0, y: 0,
            elementType: ElementType.welcomeScreenTitle
        }).setScale(0.6).setOrigin(0.5, 0).setPosition(this.game.canvas.width / 2, 0);
        this.add.existing(gameTitle);
    }
    
    private createControlsConfigurationScreen(): void {
        const window = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.big
        });
        window.setScale(0.8);
        this.controlsWindowHeight = window.height;
        
        const title = new GuiText({
            scene: this,
            x: -150,
            y: -170,
            width: 300,
            text: 'Controls',
            textType: TextType.ravie,
            fontSize: 26,
            color: TextColor.yellow
        });
        const content = new GuiElement({
            scene: this,
            x: 0,
            y: 40,
            elementType: ElementType.controls
        }).setScale(0.8);
        
        const backBtn = new GuiButton({
            scene: this,
            x: -130,
            y: 240,
            tag: 'back',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.back,
            onClick: () => {
                this.gtm.buttonClicked(`WelcomeScene:CONTROLS-BACK`);
                this.hideControls()
                    .then(() => {
                        return this.showMenu();
                    });
            }
        });
        
        this.containerControls = this.add.container(this.game.canvas.width / 2, -this.controlsWindowHeight / 2);
        this.containerControls.add(window);
        this.containerControls.add(title);
        this.containerControls.add(content);
        this.containerControls.add(backBtn);
    }
    
    private createPlayerSelectionScreen(): void {
        const playersWnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.big
        });
        playersWnd.setScale(0.9);
        this.playersWindowHeight = playersWnd.height;
        
        const playersTitle = new GuiText({
            scene: this,
            x: -150,
            y: -185,
            width: 300,
            text: 'Choose player',
            textType: TextType.ravie,
            fontSize: 22,
            color: TextColor.yellow
        });
        
        const playersContent = new GuiElement({
            scene: this,
            x: 90,
            y: 50,
            elementType: ElementType.windowContentGroupSmall
        }).setScale(0.9);
        
        
        const cancelBtn = new GuiButton({
            scene: this,
            x: -150,
            y: 320,
            tag: 'cancel-player',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.cancel,
            onClick: this.onButtonClick.bind(this)
        });
        
        const saveBtn = new GuiButton({
            scene: this,
            x: 150,
            y: 320,
            tag: 'select-player',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.ok,
            onClick: this.onButtonClick.bind(this)
        });
        
        this.containerPlayers = this.add.container(this.game.canvas.width / 2, -this.playersWindowHeight / 2);
        this.createPlayersSelectionMenu();
        
        this.containerPlayers.add(playersWnd);
        this.containerPlayers.add(playersTitle);
        this.containerPlayers.add(playersContent);
        this.containerPlayers.add(this.playerButtons);
        this.containerPlayers.add(this.standingPlayers);
        this.containerPlayers.add(cancelBtn);
        this.containerPlayers.add(saveBtn);
    }
    
    private createLoginScreen(): void {
        // LOGIN
        const loginWnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.big
        });
        loginWnd.setScale(0.8);
        this.loginWindowHeight = loginWnd.height;
        
        const loginTitle = new GuiText({
            scene: this,
            x: -150,
            y: -190,
            width: 300,
            text: 'Login',
            textType: TextType.ravie,
            fontSize: 26,
            color: TextColor.yellow
        });
        
        const loginInputGroup = new GuiElement({
            scene: this,
            x: 0,
            y: -20,
            elementType: ElementType.windowContentGroupRow
        }).setScale(0.8);
        
        const loginInputLabel = new GuiText({
            scene: this,
            x: -200,
            y: -80,
            width: 400,
            text: 'Your Viessmann customer email address',
            textType: TextType.interSemiBold,
            fontSize: 16,
            textAlign: 'center',
            color: TextColor.brown
        });
        
        this.loginErrorInfo = new GuiText({
            scene: this,
            x: -200,
            y: 15,
            width: 400,
            text: 'ERROR: Account not found.',
            textType: TextType.interSemiBold,
            fontSize: 16,
            textAlign: 'center',
            color: TextColor.red
        });
        this.loginErrorInfo.setAlpha(0);
        
        const orLabel = new GuiText({
            scene: this,
            x: -200,
            y: 50,
            width: 400,
            text: 'OR',
            textType: TextType.ravie,
            fontSize: 20,
            textAlign: 'center',
            color: TextColor.brown
        });
        
        const defaultPlayerLabel = new GuiText({
            scene: this,
            x: -200,
            y: 110,
            width: 400,
            text: 'Click CANCEL and use default player.',
            textType: TextType.interSemiBold,
            fontSize: 16,
            textAlign: 'center',
            color: TextColor.brown
        });
        
        const cancelLoginBtn = new GuiButton({
            scene: this,
            x: -120,
            y: 260,
            tag: 'cancel-login',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.cancel,
            onClick: this.onButtonClick.bind(this)
        });
        
        const loginBtn = new GuiButton({
            scene: this,
            x: 120,
            y: 260,
            tag: 'login',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.ok,
            onClick: this.onButtonClick.bind(this)
        });
        
        this.containerLogin = this.add.container(this.game.canvas.width / 2, -this.loginWindowHeight / 2);
        this.containerLogin.add(loginWnd);
        this.containerLogin.add(loginTitle);
        this.containerLogin.add(loginInputGroup);
        this.containerLogin.add(loginInputLabel);
        this.containerLogin.add(orLabel);
        this.containerLogin.add(defaultPlayerLabel);
        this.containerLogin.add(cancelLoginBtn);
        this.containerLogin.add(loginBtn);
        this.containerLogin.add(this.loginErrorInfo);
        
        this.loginInput = this.add.dom(this.game.canvas.width / 2 - 170, 420).createFromCache('form').setDepth(999999);
    }
    
    private createNewGameConfirmationScreen(btnStartTag: string): void {
        // Start new game confirmation
        const startNewQuestionWnd = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.rect
        });
        startNewQuestionWnd.setScale(0.8);
        this.newGameQuestionWindowHeight = startNewQuestionWnd.height;
        
        const newGameTitle = new GuiText({
            scene: this,
            x: 0,
            y: 60,
            width: 360,
            text: 'Are you sure to start NEW game?\nPrevious game state will be lost.',
            textType: TextType.interSemiBold,
            fontSize: 20,
            color: TextColor.brown
        });
        newGameTitle.setOrigin(0.5);
        
        const yesBtn = new GuiButton({
            scene: this,
            x: 130,
            y: 190,
            tag: 'yes',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.ok,
            onClick: () => {
                this.gtm.buttonClicked(`WelcomeScene:NEW-GAME-YES`);
                GameStore.resetStore();
                this.hideNewGameQuestion()
                    .then(() => {
                        return this.showDifficultyLevels();
                    });
            }
        });
        
        const noBtn = new GuiButton({
            scene: this,
            x: -130,
            y: 190,
            tag: 'no',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.cancel,
            onClick: () => {
                this.gtm.buttonClicked(`WelcomeScene:NEW-GAME-NO`);
                this.hideNewGameQuestion()
                    .then(() => {
                        return this.showMenu();
                    });
            }
        });
        
        this.containerNewGameQuestion = this.add.container(this.game.canvas.width / 2, -this.newGameQuestionWindowHeight / 2);
        this.containerNewGameQuestion.add(startNewQuestionWnd);
        this.containerNewGameQuestion.add(newGameTitle);
        this.containerNewGameQuestion.add(yesBtn);
        this.containerNewGameQuestion.add(noBtn);
    }
    
    private createDifficultyLevelsSelectionScreen(): void {
        const window = new GuiWindow({
            scene: this,
            x: 0, y: 0,
            windowType: WindowType.ultraWide
        });
        window.setScale(0.9);
        this.difficultyLevelsWindowHeight = window.height;
        
        const title = new GuiText({
            scene: this,
            x: -165,
            y: -185,
            width: 330,
            text: 'Choose difficulty level',
            textType: TextType.ravie,
            fontSize: 22,
            color: TextColor.yellow
        });
        
        const okBtn = new GuiButton({
            scene: this,
            x: 0,
            y: 320,
            tag: 'difficulty-levels-ok',
            buttonSize: ButtonSize.big,
            buttonType: ButtonType.ok,
            onClick: () => {
                this.gtm.buttonClicked(`WelcomeScene:DIFFICULTY-LEVELS-OK`);
                const selectedLevel = this.difficultyLevels.find(level => level.isSelected).level;
                GlobalObjects.setDifficultyLevel(selectedLevel);
                this.globalEvents.emit(EVENT_DIFFICULTY_LEVEL_CHANGED, selectedLevel);
                
                // save the choice
                this.closeScreen({
                    button: 'start' // start triggers game start here
                });
            }
        });
        const selectLevel = (level: GameDifficultyLevel) => {
            this.difficultyLevels.forEach(levelItem => {
                if (levelItem.level === level) {
                    levelItem.isSelected = true;
                    levelItem.selectionGroup.setAlpha(1);
                    levelItem.textLabel.changeColor(TextColor.black);
                } else {
                    levelItem.isSelected = false;
                    levelItem.selectionGroup.setAlpha(0.3);
                    levelItem.textLabel.changeColor(TextColor.brown);
                }
            });
        };
        const levelEasyGroup = new GuiElement({
            scene: this,
            x: -260,
            y: 40,
            elementType: ElementType.windowContentGroupSmall,
            onClick: () => {
                selectLevel(GameDifficultyLevel.easy);
            }
        }).setScale(0.9).setAlpha(0.3);
        
        const levelNormalGroup = new GuiElement({
            scene: this,
            x: 0,
            y: 40,
            elementType: ElementType.windowContentGroupSmall,
            onClick: () => {
                selectLevel(GameDifficultyLevel.normal);
            }
        }).setScale(0.9).setAlpha(0.3);
        
        const levelHardGroup = new GuiElement({
            scene: this,
            x: 260,
            y: 40,
            elementType: ElementType.windowContentGroupSmall,
            onClick: () => {
                selectLevel(GameDifficultyLevel.hard);
            }
        }).setScale(0.9).setAlpha(0.3);
        
        const playerEasyGroup = new GuiElement({
            scene: this,
            x: -260,
            y: 40,
            elementType: ElementType.difficultyEasy
        }).setScale(0.9);
        
        const playerNormalGroup = new GuiElement({
            scene: this,
            x: 0,
            y: 40,
            elementType: ElementType.difficultyNormal
        }).setScale(0.9);
        
        const playerHardGroup = new GuiElement({
            scene: this,
            x: 260,
            y: 40,
            elementType: ElementType.difficultyHard
        }).setScale(0.9);
        
        const labelsY = 180;
        const easyLabel = new GuiText({
            scene: this,
            x: -260-50,
            y: labelsY,
            width: 100,
            text: 'Easy',
            textType: TextType.ravie,
            fontSize: 20,
            color: TextColor.brown
        });
        
        const normalLabel = new GuiText({
            scene: this,
            x: -50,
            y: labelsY,
            width: 100,
            text: 'Normal',
            textType: TextType.ravie,
            fontSize: 20,
            color: TextColor.brown
        });
        
        const hardLabel = new GuiText({
            scene: this,
            x: 260-50,
            y: labelsY,
            width: 100,
            text: 'Hard',
            textType: TextType.ravie,
            fontSize: 20,
            color: TextColor.brown
        });
        
        this.difficultyLevels = [
            <DifficultyLevelSelectionInfo>{
                isSelected: false,
                level: GameDifficultyLevel.easy,
                selectionGroup: levelEasyGroup,
                textLabel: easyLabel
            },
            <DifficultyLevelSelectionInfo>{
                isSelected: false,
                level: GameDifficultyLevel.normal,
                selectionGroup: levelNormalGroup,
                textLabel: normalLabel
            },
            <DifficultyLevelSelectionInfo>{
                isSelected: false,
                level: GameDifficultyLevel.hard,
                selectionGroup: levelHardGroup,
                textLabel: hardLabel
            }
        ];
        selectLevel(GlobalObjects.difficultyLevel);
        
        this.containerDifficultyLevels = this.add.container(this.game.canvas.width / 2, -this.difficultyLevelsWindowHeight / 2);
        this.containerDifficultyLevels.add(window);
        this.containerDifficultyLevels.add(title);
        this.containerDifficultyLevels.add(okBtn);
        this.containerDifficultyLevels.add(levelEasyGroup);
        this.containerDifficultyLevels.add(levelNormalGroup);
        this.containerDifficultyLevels.add(levelHardGroup);
        this.containerDifficultyLevels.add(playerEasyGroup);
        this.containerDifficultyLevels.add(playerNormalGroup);
        this.containerDifficultyLevels.add(playerHardGroup);
        this.containerDifficultyLevels.add(easyLabel);
        this.containerDifficultyLevels.add(normalLabel);
        this.containerDifficultyLevels.add(hardLabel);
    }

    private createPlayersSelectionMenu(): void {

        if (this.standingPlayers) {
            this.containerPlayers.remove(this.standingPlayers);
            this.standingPlayers.forEach(p => p.destroy());
        }
        if (this.playerButtons) {
            this.containerPlayers.remove(this.playerButtons);
            this.playerButtons.forEach(p => p.destroy());
        }

        this.standingPlayers = [];
        this.playerButtons = this.players.map((player, index) => {
            const playerBtn = new GuiText({
                scene: this,
                x: -190,
                y: -40 + index * 40,
                text: (player.selected ? '  ' : '') + player.name,
                tag: index.toString(),
                textType: TextType.interSemiBold,
                fontSize: 20,
                color: player.selected ? TextColor.black : TextColor.brown,
                onClick: this.onPlayerSelect.bind(this)
            });
            player.textBtn = playerBtn;
            player.playerSprite = this.createStandingPlayer(player.name, 90, 40).setFlip(true, false);
            player.playerSprite.setAlpha(player.selected ? 1 : 0);
            this.standingPlayers.push(player.playerSprite);

            if (player.selected) {
                this.globalEvents.emit(EVENT_PLAYER_CHANGED, player.type);
            }

            return playerBtn;
        });
        this.containerPlayers.add(this.standingPlayers);
        this.containerPlayers.add(this.playerButtons);
    }

    private onButtonClick(tag: string): void {
        if (tag === WelcomeScreen.gameStartTriggerTag) {
            this.gtm.buttonClicked(`WelcomeScene:NEW`);
            if (GameStore.canContinueGame()) {
                // ask if to reset the whole game
                this.hideMenu()
                    .then(() => {
                        this.showNewGameQuestion();
                    });
            }
            else {
                this.hideMenu()
                    .then(() => {
                        this.showDifficultyLevels();
                    });
            }
        }
        if (tag === 'continue') {
            this.gtm.buttonClicked(`WelcomeScene:CONTINUE`);
            this.hideMenu()
                .then(() => {
                    return this.closeScreen({
                        button: tag
                    });
                });
        }
        else if (tag === 'controls') {
            this.gtm.buttonClicked(`WelcomeScene:CONTROLS`);
            this.hideMenu()
                .then(() => {
                    this.showControls();
                });
        }
        else if (tag === 'players') {
            this.gtm.buttonClicked(`WelcomeScene:PLAYERS`);
            this.hideMenu()
                .then(() => {
                    // this.showLogin();

                    // show all players
                    this.players = this.getVisiblePlayers(db['full@viessmann.com']);
                    this.createPlayersSelectionMenu();
                    this.showPlayers();
                });
        }
        else if (tag === 'select-player') {

            // find selected player and select it for global usage
            this.players.forEach((p, idx) => {
                if (p.selected) {
                    GlobalObjects.setSelectedPlayer(p.type);
                    this.globalEvents.emit(EVENT_PLAYER_CHANGED, p.type);
                    this.gtm.buttonClicked(`WelcomeScene:SELECT-PLAYER (${p.type})`);
                }
            });
            this.hidePlayers()
                .then(() => {
                    return this.showMenu();
                });
        }
        else if (tag === 'cancel-player') {
            this.gtm.buttonClicked(`WelcomeScene:CANCEL-SELECT-PLAYER`);
            this.hidePlayers()
                .then(() => {
                    return this.showMenu();
                });
        }
        else if (tag === 'cancel-login') {
            this.gtm.buttonClicked(`WelcomeScene:CANCEL-LOGIN`);
            this.hideLogin()
                .then(() => {
                    return this.showMenu();
                });
        }
        else if (tag === 'login') {
            this.gtm.buttonClicked(`WelcomeScene:LOGIN`);
            const dom = this.loginInput.getChildByID('login') as HTMLInputElement;
            if (!db[dom.value]) {
                this.loginErrorInfo.setAlpha(1);
            }
            else {
                this.loginErrorInfo.setAlpha(0);
                this.hideLogin()
                    .then(() => {
                        this.players = this.getVisiblePlayers(db[dom.value]);
                        this.createPlayersSelectionMenu();
                        this.showPlayers();
                    });
            }
        }
    }

    private onPlayerSelect(tag: string): void {
        this.players.forEach((p, idx) => {
            if (p.selected) {
                p.selected = false;
                p.textBtn.setText(p.name);
                p.textBtn.changeColor(TextColor.brown);
                p.playerSprite.setAlpha(0);
            }
            if (idx.toString() === tag) {
                p.selected = true;
                p.textBtn.setText('  ' + p.name);
                p.textBtn.changeColor(TextColor.black);
                p.playerSprite.setAlpha(1);
            }
        });
    }

    private showMenu(): Promise<void> {
        return tweenShow(this, this.containerMenu, {
                y: this.menuWindowHeight / 2
            }
        );
    }

    private hideMenu(): Promise<void> {
        return tweenHide(this, this.containerMenu, {
            y: -this.menuWindowHeight / 2
        });
    }
    
    private showDifficultyLevels(): Promise<void> {
        return tweenShow(this, this.containerDifficultyLevels, {
                y: this.difficultyLevelsWindowHeight / 2 + 100
            }
        );
    }

    private showControls(): void {
        tweenShow(this, this.containerControls, {
                y: this.controlsWindowHeight / 2 + 100
            }
        ).then(() => {
        });
    }

    private hideControls(): Promise<void> {
        return tweenHide(this, this.containerControls, {
            y: -this.controlsWindowHeight / 2
        });
    }

    private showPlayers(): void {
        // select player chosen globally
        // GlobalObjects.setSelectedPlayer(p.type);
        this.players.forEach((p, idx) => {
            if (p.type === GlobalObjects.selectedPlayer) {
                this.onPlayerSelect(idx.toString());
            }
        });

        tweenShow(this, this.containerPlayers, {
                y: this.playersWindowHeight / 2 + 100
            }
        ).then(() => {
        });
    }

    private hidePlayers(): Promise<void> {
        return tweenHide(this, this.containerPlayers, {
            y: -this.playersWindowHeight / 2
        });
    }

    private showLogin(): void {
        tweenShow(this, this.containerLogin,
            {
                y: this.loginWindowHeight / 2 + 100
            }
        ).then(() => {
            const dom = this.loginInput.getChildByID('login') as HTMLInputElement;
            dom.style.display = 'block';
        });
    }

    private hideLogin(): Promise<void> {
        const dom = this.loginInput.getChildByID('login') as HTMLInputElement;
        dom.style.display = 'none';

        return tweenHide(this, this.containerLogin, {
            y: -this.loginWindowHeight / 2
        });
    }

    private showNewGameQuestion(): void {
        tweenShow(this, this.containerNewGameQuestion, {
                y: this.newGameQuestionWindowHeight / 2 + 100
            }
        ).then(() => {
        });
    }

    private hideNewGameQuestion(): Promise<void> {
        return tweenHide(this, this.containerNewGameQuestion, {
            y: -this.newGameQuestionWindowHeight / 2
        });
    }

    private createStandingPlayer(name: string, x: number, y: number): Sprite {
        let asset: PlayerAssets;

        switch (name) {
            case 'Vitodens':
                asset = ASSETS.player1;
                break;
            case 'Vitocal':
                asset = ASSETS.player2;
                break;
            case 'Vitodens 222-F':
                asset = ASSETS.player3;
                break;
            case 'Vitovolt':
                asset = ASSETS.player4;
                break;
            case 'Vitocell':
                asset = ASSETS.player5;
                break;
        }

        this.anims.create({
            key: 'anim/' + name,
            repeat: -1,
            frames: [
                ...this.anims.generateFrameNames(asset.idle.name),
                ...this.anims.generateFrameNames(asset.idle.name),
                ...this.anims.generateFrameNames(asset.idleBlink.name)
            ],
            frameRate: ASSETS.player1.idle.fps
        });
        return this.add.sprite(x, y, 'anim/' + name).play('anim/' + name);
    }

    private getVisiblePlayers(available: PlayerType[]): PlayerSelectionInfo[] {
        let anySelected = false;
        const result = this.allPlayers.filter((p) => {
            anySelected = p.selected ? true : anySelected;
            return available.includes(p.type);
        }).map(vp => Object.assign({}, vp)); // clone players
        return result;
    }


}

interface PlayerSelectionInfo {
    name: string,
    anim: string,
    selected: boolean,
    textBtn: GuiText,
    playerSprite: Sprite,
    type: PlayerType
}

interface DifficultyLevelSelectionInfo {
    isSelected: boolean;
    level: GameDifficultyLevel;
    textLabel: GuiText,
    selectionGroup: GuiElement
}
